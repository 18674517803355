<template>
  <div class="tt-promotion">
    <PromotionInfo />

    <BonusCardSteps />

    <PromotionFaq />
  </div>
</template>

<script>
import PromotionInfo from "@/components/Promotion/PromotionInfo.vue";
import BonusCardSteps from "@/components/Promotion/BonusCardSteps.vue";
import PromotionFaq from "@/components/Promotion/PromotionFaq.vue";

export default {
  components: {
    PromotionFaq,
    BonusCardSteps,
    PromotionInfo
  },
  metaInfo() {
    return {
      title:  `${this.$t('navbar_bonus')} | Доставка кондитерских изделий в Астане (Нур-султан)`,
      meta: [
        {
            vmid: 'description',
            property: 'description',
            name: 'description',
            content: this.$t('Share_views_meta_description'),
        },
        {
            vmid: 'og:title',
            property: 'og:title',
            name: 'og:title',
            content: `${this.$t('navbar_blog')}| Доставка кондитерских изделий в Астане (Нур-султан)`,
        },
        {
            vmid: 'og:description',
            property: 'og:description',
            name: 'og:description',
            content: this.$t('Share_views_meta_og_description'),
        }
      ]
    }
  }
}
</script>

<style lang="sass" scoped>
@import "src/assets/styles-new/typography"
@import "src/assets/styles-new/vars"

.tt-promotion
  padding: 40px 0 100px

@media screen and (max-width: $tablet-width)
  .tt-promotion
    padding: 40px 0

@media screen and (max-width: $mobile-width)
  .tt-promotion
    padding-top: 16px
</style>
