import axios from "axios";
import Echo from "laravel-echo"
import Pusher from "pusher-js";

window.Echo = new Echo({
    broadcaster: 'pusher',
    key: process.env.VUE_APP_PUSHER_KEY,
    cluster: process.env.VUE_APP_PUSHER_CLUSTER,
    encrypted: true,
    authEndpoint: process.env.VUE_APP_API_URL + 'broadcasting/auth',
    auth:{
        headers:{
            Authorization: 'Bearer ' + localStorage.getItem('token')
        }
    }
});

const getDefaultState = () => {
    return {
        paymentTypes: [],
        selectedPaymentType: {},
        order: {},
        selectedCakeType: {},
        isForFriend: false,
        friend: {
            name: null,
            phone: null,
        },
        validationErrors: {},
        courierComment: '',
        comment: '',
        history: {},
        hasError: false,
        loading: false,
    }
};

const state = getDefaultState()

// const getters = {
//     getModalName: state => () => state.showModal,
//     getSelectedCakeType: state => () => state.selectedCakeType,
// };

const mutations = {
    SET_ORDER(state, order) {
        if (order && order.id && order.status === 'New' && order.payment_url && !order.payed_at) {
            // только один раз делаем редирект
            let cacheKey = window.location.hostname + '_order_payment_redirect_' + order.id;
            if (localStorage.getItem(cacheKey) === null) {
                localStorage.setItem(cacheKey, 'true')
                window.location.href = order.payment_url;
            }
        }
        state.order = order;
    },
    SET_SELECTED_CAKE_TYPE(state, selectedCake) {
        state.selectedCakeType = selectedCake;
    },
    SET_ORDER_STATUS(state, response) {
        state.order.status = response.status;
        state.order.status_id = response.status_id;
        state.order.is_closed = response.is_closed;
    },
    SET_FRIEND_PHONE(state, phone) {
        state.friend.phone = phone;
    },
    SET_FRIEND_NAME(state, name) {
        state.friend.name = name;
    },
    SET_FOR_FRIEND(state, forFriend) {
        state.isForFriend = forFriend;
    },
    RESET_STATE(state) {
        Object.assign(state, getDefaultState())
    },
    SET_VALIDATION_ERRORS(state, errors) {
        state.validationErrors = errors;
    },
    SET_COURIER_COMMENT(state, courierComment) {
        state.courierComment = courierComment;
    },
    SET_COMMENT(state, comment) {
        state.comment = comment;
    },
    SET_ORDER_HISTORY(state, history) {
        state.history = history;
    },
    SET_HAS_ERROR(state, hasError) {
        state.hasError = hasError;
    },
    SET_LOADING(state, payload) {
        state.loading = payload;
    }
};

const actions = {
    setSelectedCake({ commit }, selectedCake) {
        commit('SET_SELECTED_CAKE_TYPE', selectedCake)
    },
    createOrder(context) {
        const cartState = context.rootState.cart;
        const isDelivery = cartState.isDelivery;
        const isExpress = cartState.isExpressDelivery;
        const city = JSON.parse(localStorage.getItem('city'));
        let params = {
            payment_id: context.rootState.paymentType.selectedPaymentType.id,
            is_delivery: isDelivery,
            is_express: isExpress,
            shop_id: context.rootState.filial.activeFilial.id,
            cart_id: cartState.cartId,
            city_id: city.id,
            selectedDate: context.rootGetters["cart/getSelectedDate"],
            selectedTime: context.rootGetters["cart/getSelectedTime"],
            isForFriend: context.state.isForFriend,
            courierComment: context.state.courierComment,
            comment: context.state.comment,
            // greetingCards: context.rootGetters["cart/greetingCards"]
        };

        if (isDelivery) {
            params.address = cartState.deliveryAddress;
            params.coords = cartState.deliveryCoords;
        }else{
            params.cashAmount = cartState.cashAmount;
        }

        if (context.state.isForFriend) {
            params.friend = context.state.friend;
        }

        return axios.post('order', params);
    },
    getOrder(context, orderId) {
        return axios.get(`order/${orderId}`)
            .then(response => {
                context.commit('SET_ORDER', response.data);
                context.dispatch('listenOrder', response.data);
                context.dispatch('listenOrderStatus', response.data);
                context.commit('SET_HAS_ERROR', false);

                return response;
            })
            .catch(() => {
                context.commit('SET_HAS_ERROR', true);
            });

    },
    getHistoryOrders(context) {
        return axios.get('order/history')
            .then(response => {
                context.commit('SET_ORDER', response.data.active);
                context.dispatch('listenOrder', response.data.active);
                context.dispatch('listenOrderStatus', response.data.active);
                context.commit('SET_ORDER_HISTORY', response.data.history);

                return response;
            })
    },
    listenOrder(context, order) {
        if (order && order.id) {
            window.Echo.channel('order.' + order.id)
                .listen('OrderCreated', (e) => {
                    context.commit('SET_ORDER', e);
                    context.dispatch('listenOrderStatus',e)
                });
        }
    },
    listenOrderStatus(context, order) {
        if (order && order.id && !order.is_closed) {

            window.Echo.channel('order-status.' + order.id)
                .listen('OrderStatusChanged', (e) => {
                    context.commit('SET_ORDER_STATUS', e);
                });
        }
    },
};

// Экспортируем наружу
export default {
    namespaced: true,
    state,
    // getters,
    mutations,
    actions
}
