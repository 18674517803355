<template>
  <div class="tt-pickup">
    <div class="tt-pickup__form">
      <div class="tt-pickup__time">
        <div class="tt-v-select">
          <label class="tt-v-select__label">
            {{ $t('TakeDelivery_select_date') }}
          </label>
          <v-select v-model="selectedDate"
                    :clearable="false"
                    :searchable="false"
                    :options="dateOptions"
                    :disabled="!dateOptions.length"
                    :loading="isStopListLoading"
                    label="value" />
        </div>

        <div class="tt-v-select">
          <label class="tt-v-select__label">
            {{ $t('OrderPage_cmpt_TakeDelivery_choose_time') }}
          </label>
          <v-select v-model="selectedTime"
                    :clearable="false"
                    :searchable="false"
                    :options="timeOptions"
                    :disabled="!timeOptions.length"
                    :loading="isStopListLoading"
                    label="value" />
        </div>
      </div>

      <div class="tt-pickup__shop-list-title">
        {{ $t('OrderPage_cmpt_TakePickup_in_the_list') }}
      </div>
      <div class="tt-pickup__shop-list">
        <div class="tt-pickup__shop"
             :class="'tt-pickup__shop--' + filialMarker(filial.id)"
             v-for="filial in filials"
             :key="filial.id"
             @click="setActiveFilial(filial)">
          <input type="radio"
                 class="tt-radio-button"
                 v-model="activeFilial"
                 :value="filial"
                 :disabled="filialMarker(filial.id) === 'red'">
          <div class="tt-pickup__shop-info">
            <div class="tt-pickup__shop-name">
              {{ filial.name }}
              <div class="tt-pickup__stock-status"
                   :class="'tt-pickup__stock-status--' + filialMarker(filial.id)"></div>
            </div>
            <div class="tt-pickup__shop-address">
              {{ filial.address }}
            </div>
          </div>
        </div>
      </div>

      <div class="tt-pickup__error"
           v-if="filialMarker(activeFilial.id) === 'red'">
        {{ $t('OrderPage_cmpt_TakePickup_attention_red_zone') }}
      </div>
      <div class="tt-pickup__error"
           v-if="filialMarker(activeFilial.id) === 'yellow'">
        {{ $t('OrderPage_cmpt_TakePickup_attention_yellow_zone') }}
        <scrollactive :offset="200" :duration="1000">
          <a href="#cart_block"
             class="scrollactive-item">
            {{ $t('OrderPage_cmpt_TakePickup_link2up') }}
          </a>
        </scrollactive>
      </div>
    </div>

    <div class="tt-pickup__info">
      <div class="tt-pickup__regulations">
        <div class="tt-icon">
          <img src="@/assets/icons-new/info.svg">
        </div>
        <p>{{ $t('TakePickup_attention_about_pickup') }}</p>
      </div>

      <div class="tt-pickup__statuses">
        <div class="tt-pickup__status">
          <div class="tt-pickup__stock-status tt-pickup__stock-status--green"></div>
          {{ $t('OrderPage_cmpt_TakePickup_green_zone') }}
        </div>
        <div class="tt-pickup__status">
          <div class="tt-pickup__stock-status tt-pickup__stock-status--yellow"></div>
          {{ $t('OrderPage_cmpt_TakePickup_yellow_zone') }}
        </div>
        <div class="tt-pickup__status">
          <div class="tt-pickup__stock-status tt-pickup__stock-status--red"></div>
          {{ $t('OrderPage_cmpt_TakePickup_red_zone') }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import {mapGetters, mapState} from "vuex";

export default {
  computed: {
    ...mapState({
      isStopListLoading: state => state.stopList.loading
    }),
    ...mapGetters({
      dateOptions: 'cart/getPickupDates',
      timeOptions: 'cart/getPickupTimes'
    }),
    filials() {
      const filials = this.$store.state.filial.filials;
      const { filialMarker } = this;
      return filials
          .filter(filial => filial.visible)
          .sort((a,b) => {
            const priority = {
              green: 1,
              yellow: 2,
              red: 3,
            };

            const p1 = priority[filialMarker(a.id)];
            const p2 = priority[filialMarker(b.id)];

            if (p1 > p2) return 1;
            if (p1 < p2) return -1;
            return 0;
          });
    },
    selectedDate: {
      get() {
        return this.$store.state.cart.selectedPickupDate;
      },
      set(selectedDate) {
        this.$store.commit('cart/SET_SELECTED_PICKUP_DATE', selectedDate);
      }
    },
    selectedTime: {
      get() {
        return this.$store.state.cart.selectedPickupTime;
      },
      set(selectedTime) {
        this.$store.commit('cart/SET_SELECTED_PICKUP_TIME', selectedTime);
      }
    },
    activeFilial: {
      get() {
        return this.$store.state.filial.activeFilial;
      },
      set(filial) {
        this.$store.commit('filial/SET_ACTIVE_FILIAL', filial);
      }
    }
  },
  methods: {
    filialMarker(filialId) {
      return this.$store.getters['stopList/filialMarker'](filialId);
    },
    setActiveFilial(filial) {
      if (this.filialMarker(filial.id) === 'red') {
        return;
      }
      this.$store.commit('filial/SET_ACTIVE_FILIAL', filial);
    }
  },
  beforeDestroy() {
    this.$store.commit('filial/SET_ACTIVE_FILIAL', {});
  }
};
</script>

<style lang="sass" scoped>
@import "src/assets/styles-new/typography"
@import "src/assets/styles-new/colors"
@import "src/assets/styles-new/vars"

.tt-pickup
  display: flex
  justify-content: space-between
  gap: 40px
  &__time
    display: grid
    grid-template-columns: repeat(2, 200px)
    column-gap: 40px
    margin-bottom: 32px
  &__shop-list-title
    margin-bottom: 20px
    font-size: $fs-small
  &__shop-list
    display: grid
    grid-template-columns: repeat(3, 212px)
    gap: 32px
  &__shop
    min-height: 40px
    display: flex
    gap: 8px
    align-items: flex-start
    cursor: pointer
    &--red
      cursor: auto
    .tt-radio-button
      margin-top: 4px
  &__shop-name
    font-weight: $fw-medium
    display: flex
    align-items: center
    .tt-pickup__stock-status
      margin-left: 4px
      min-width: 6px
      max-width: 6px
      min-height: 6px
      max-height: 6px
  &__shop-address
    font-size: $fs-small
    color: $dark-gray
  &__info
    display: flex
    flex-direction: column
    gap: 40px
  &__regulations
    max-width: 256px
    font-size: $fs-small
    .tt-icon
      justify-content: flex-start
  &__statuses
    display: flex
    flex-direction: column
    gap: 24px
    font-size: $fs-small
  &__status
    display: flex
    gap: 8px
    .tt-pickup__stock-status
      margin-top: 6px
  &__error
    margin-top: 32px
    color: $error
    font-size: $fs-small

.tt-pickup__stock-status
  min-width: 10px
  max-width: 10px
  min-height: 10px
  max-height: 10px
  border-radius: 50%
  &--red
    background-color: $error
  &--green
    background-color: $green
  &--yellow
    background-color: $secondary

@media screen and (max-width: $tablet-width)
  .tt-pickup
    flex-direction: column
    &__time
      margin-bottom: 24px
    &__shop-list-title
      margin-bottom: 16px
    &__shop-list
      row-gap: 24px
    &__info
      flex-direction: row
      justify-content: space-between
    &__regulations
      max-width: 343px
    &__statuses
      gap: 16px

@media screen and (max-width: $mobile-width)
  .tt-pickup
    &__time
      display: flex
      flex-direction: column
      gap: 16px
      margin-bottom: 16px
    &__shop-list
      display: flex
      flex-direction: column
      gap: 16px
    &__info
      display: none
</style>