<template>
  <div v-if="products.length" class="tt-catalog">
    <div id="test" class="tt-catalog__element-scroll-to"></div>

    <CategoryTabs
      :products="products"
      :active-category="activeCategory"
      @activeCategoryChanged="setActiveCategory($event)"
    />

    <div class="tt-catalog__title ff-mont">
      {{ categoryName }}
    </div>

    <div class="tt-catalog__grid">
      <div
        v-for="product in selectedCategory"
        :key="product.id"
        class="tt-catalog__product"
      >
        <ProductCard
          :multiple="product.length > 1"
          :product="product"
          :img-src="product.image"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";
import CategoryTabs from "@/components/Home/CategoryTabs.vue";
import ProductCard from "@/components/shared/ProductCard.vue";

export default {
  computed: {
    ...mapState({
      products: state => state.products.products,
      activeCategory: state => state.products.activeCategoryTab
    }),
    selectedCategory() {
      return this.sortProducts(this.products[this.activeCategory].products);
    },
    categoryName() {
      return this.products[this.activeCategory].name;
    }
  },
  methods: {
    setActiveCategory(id) {
      this.$store.dispatch('products/setActiveCategory', id);
      const el = this.$el.querySelector('#test');
      el.scrollIntoView({
        behavior: 'smooth',
        block: 'start'
      })
    },

    sortProducts(products) {
      if (!products || !products.length) {
        return products
      }

      const sortedProducts = []
      const groupedAdded = []

      products.forEach((product) => {
        if (!product.products_size_related) {
          sortedProducts.push(product)
        } else {
          const group = []
          product.products_size_related.forEach(related => {
            const id = related.related_product_id
            const relatedProduct = products.find(item => item.id === id)
            if (relatedProduct) {
              group.push(relatedProduct)
            }
          })
          if (!groupedAdded.includes(product.id)) {
            sortedProducts.push(
              [...group, product].sort((a,b) => a.price - b.price)
            )
            group.forEach(groupItem => {
              groupedAdded.push(groupItem?.id)
            })
          }
          groupedAdded.push(product?.id)
        }
      })

      return sortedProducts
    }
  },
  components: {
    ProductCard,
    CategoryTabs,
  },
}
</script>

<style lang="sass" scoped>
@import "src/assets/styles-new/typography"
@import "src/assets/styles-new/vars"

.tt-catalog
  position: relative
  &__title
    font-size: $fs-large
    font-weight: $fw-semibold
    margin-top: 24px
  &__grid
    margin-top: 16px
    display: grid
    grid-template-columns: repeat(auto-fill, 256px)
    justify-content: space-between
    gap: 16px
  &__product
    height: 310px
    position: relative
  &__element-scroll-to
    width: 0
    height: 0
    position: absolute
    top: -60px

@media screen and (max-width: $tablet-width)
  .tt-catalog
    &__grid
      grid-template-columns: repeat(auto-fill, 216px)
    &__product
      height: 340px

@media screen and (max-width: $mobile-width)
  .tt-catalog
    &__grid
      grid-template-columns: repeat(auto-fill, 164px)
    &__product
      height: 280px
</style>
