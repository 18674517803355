import Vue from 'vue';
import axios from "@/axios";
import {i18n} from "@/i18n/i18n";
import App from './App.vue';
import router from './router';
import store from './store';
import GlobalMixin from "./mixins/global.js";

import VueImg from 'v-img'
import VCalendar from 'v-calendar';
import Vuelidate from 'vuelidate';
import VueStarRating  from "vue-star-rating";
import vSelect from 'vue-select';
import VueTheMask from 'vue-the-mask';
import Multiselect from 'vue-multiselect';
import VueLazyload from 'vue-lazyload';
import VueScrollactive from 'vue-scrollactive';
import VueSlickCarousel from 'vue-slick-carousel';
import 'vue-slick-carousel/dist/vue-slick-carousel.css';
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css';
import VueTabs from 'vue-nav-tabs';
import 'vue-nav-tabs/themes/vue-tabs.css';
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
import Meta from 'vue-meta';
import VueYandexMetrika from 'vue-yandex-metrika';

import "normalize.css"

//config
Vue.config.productionTip = false;

// use
Vue.use(Vuelidate);
Vue.use(VueTheMask);
Vue.use(VueScrollactive);
Vue.use(Meta);
Vue.use(VCalendar);
Vue.use(VueTabs);
Vue.use(VueLazyload);
Vue.use(VueYandexMetrika, {
    id: 66585259,
    env: process.env.NODE_ENV,
});
Vue.use(VueToast);
Vue.use(VueImg)

//components
Vue.component('star-rating', VueStarRating);
Vue.component('multiselect', Multiselect);
Vue.component('VueSlickCarousel', VueSlickCarousel);
Vue.component('v-select', vSelect);
Vue.mixin(GlobalMixin);

//vue-prototype
Vue.prototype.$axios = axios;
Vue.prototype.$eventBus = new Vue();

new Vue({
    router,
    axios,
    store,
    i18n,
    render: h => h(App)
}).$mount('#app');
