<template>
  <div class="tt-bonus-steps">
    <div class="tt-bonus-steps__images">
      <div class="tt-bonus-steps__image">
        <img src="@/assets/images-new/promotion_2.png">
      </div>
      <div class="tt-bonus-steps__image">
        <img src="@/assets/images-new/promotion_4.png">
      </div>
      <div class="tt-bonus-steps__image grid-item-3">
        <img src="@/assets/images-new/promotion_3.png">
      </div>
      <div class="tt-bonus-steps__image">
        <img src="@/assets/images-new/qr.png">
      </div>
      <div class="tt-bonus-steps__image">
        <img src="@/assets/images-new/promotion_5.png">
      </div>
    </div>

    <div>
      <h2 class="tt-bonus-steps__title ff-mont">
        {{ $t('promotion-registration__header') }}
      </h2>


      <div class="tt-bonus-steps__step">
        1. {{ $t('promotion-registration__steps-1') }}
      </div>
      <div class="tt-bonus-steps__step">
        2. {{ $t('promotion-registration__steps-2') }}
      </div>
      <div class="tt-bonus-steps__step">
        3. {{ $t('promotion-registration__steps-3') }}
      </div>
      <div class="tt-bonus-steps__step">
        4. {{ $t('promotion-registration__steps-4') }}
      </div>
      <div class="tt-bonus-steps__step">
        5. {{ $t('promotion-registration__steps-5') }}
      </div>

      <a href="https://registration.lo.cards/?hash=QkUuIT51OOwrCHOu"
         target="_blank">
        <button class="tt-button tt-button--primary">
          {{$t('promotion-header__button')}}
        </button>
      </a>
    </div>
  </div>
</template>

<script>
export default {
}
</script>

<style lang="sass" scoped>
@import "src/assets/styles-new/typography"
@import "src/assets/styles-new/vars"

.tt-bonus-steps
  display: grid
  grid-template-columns: 1fr 544px
  gap: 100px
  margin-bottom: 100px
  &__title
    font-size: $fs-larger
    font-weight: $fw-semibold
    margin-bottom: 16px
  &__images
    display: grid
    grid-template-columns: repeat(3, 1fr)
    column-gap: 16px
    row-gap: 40px
    .grid-item-3
      grid-row-start: 1
      grid-row-end: 3
      grid-column-start: 2
      align-self: center
  &__image
    height: 160px
    padding: 8px
    background-color: #F4F4F5
    border-radius: 16px
    overflow: hidden
    img
      width: 100%
      height: 100%
      object-fit: contain
  &__step
    margin-bottom: 24px
  .tt-button
    margin-top: 16px
    width: fit-content

@media screen and (max-width: $tablet-width)
  .tt-bonus-steps
    grid-template-columns: 314px 1fr
    gap: 40px
    margin-bottom: 80px
    &__title
      font-size: $fs-large
    &__step
      margin-bottom: 16px

@media screen and (max-width: $mobile-width)
  .tt-bonus-steps
    grid-template-columns: 1fr
    margin-bottom: 48px
    &__title
      font-size: $fs-semilarge
    &__images
      grid-row-start: 2
</style>