<template>
  <div class="tt-user"
       v-if="user">
    <UserDetail i18nKey="OrderBasket_views_contacts_form_name"
                :value="user.name"/>

    <UserDetail i18nKey="PersonalArea_views_phone"
                :value="formattedPhoneNumber"/>

    <UserEmail :email="user.email" />

    <UserBirthday :date="user.birthdate" />

    <UserDetail i18nKey="PersonalArea_views_bonuses"
                :value="`${user.wallet_balance || '0' } ₸`"/>

    <UserDetail i18nKey="bonuses_from_hbday"
                :value="`${user.birthday_wallet_balance || '0' } ₸`"/>

  </div>
</template>

<script>
import UserDetail from "@/components/Cabinet/UserDetail.vue";
import {mapState} from "vuex";
import UserEmail from "@/components/Cabinet/UserEmail.vue";
import UserBirthday from "@/components/Cabinet/UserBirthday.vue";

export default {
  computed: {
    ...mapState({
      user: state => state.auth.user
    }),
    formattedPhoneNumber() {
      if (!this.user.phone) {
        return '';
      }
      let formatted = '+';
      formatted += this.user.phone[1] + " ";
      formatted += this.user.phone.slice(1,4) + " ";
      formatted += this.user.phone.slice(4,7) + " ";
      formatted += this.user.phone.slice(7,9) + " ";
      formatted += this.user.phone.slice(9,11);
      return formatted
    }
  },
  components: {
    UserBirthday,
    UserEmail,
    UserDetail
  }
}
</script>

<style lang="sass" scoped>
@import "src/assets/styles-new/vars"

.tt-user
  display: flex
  column-gap: 80px
  row-gap: 24px
  flex-wrap: wrap
  margin-bottom: 50px

@media screen and (max-width: $tablet-width)
  .tt-user
    column-gap: 40px
    margin-bottom: 32px

@media screen and (max-width: $mobile-width)
  .tt-user
    row-gap: 10px
    flex-direction: column
    margin-bottom: 24px
</style>