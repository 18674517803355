import axios from "axios";

const state = {
    token: localStorage.getItem('token'),
    loggedIn: false,
    user: {},
}

const actions = {
    fetchUser(context) {
        if (!state.token) {
            return;
        }
        return axios
            .get('auth/user')
            .then(response => {
                context.commit('SET_USER', response.data)
            });
    },
    refresh(context) {
        return axios
            .post('auth/refresh')
            .then(response => {
                context.commit('SET_TOKEN', response.data.access_token)
                if (!context.state.user.id) {
                    context.commit('SET_USER', response.data.user)
                }
            })
            .catch(error => {
                context.commit('CLEAR_USER')
            });
    },
    logout(context) {
        axios.post('auth/logout')
            .finally(() => {
                context.commit('CLEAR_USER');
            });
    },
    getActivationCode(context, user) {
        return axios.post('auth/send_confirmation', user);
    },
    loginByCode(context, user) {
        const unauthorizedToken = context.rootGetters["cart/getUnauthorizedToken"];
        if (unauthorizedToken) {
            user.token = unauthorizedToken;
        }
        return axios
            .post('auth/code/login', user)
            .then(response => {
                context.commit('SET_TOKEN', response.data.access_token)
                context.commit('SET_USER', response.data.user)
            });
    },
    updateUser(context, data) {
        return axios.post('auth/update', data)
    },
};
const mutations = {
    SET_USER(state, user) {
        state.loggedIn = true;
        state.user = user;
    },
    SET_TOKEN(state, token) {
        localStorage.setItem('token', token);
        state.token = token;
    },
    CLEAR_USER(state) {
        state.loggedIn = false;
        state.user = null;
        state.token = null;
        localStorage.removeItem('token')
    },
};
const getters = {
    loggedIn() {
        return state.user && state.user.id;
    },
    user() {
        return state.user;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
