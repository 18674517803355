import {mapState} from "vuex";

export const cartMixin = {
    computed: {
        ...mapState({
            cartProducts: state => state.cart.products,
            noTakeawayCategoryId: state => state.products.noTakeawayCategoryId
        })
    },
    methods: {
        canAddProductToCart(product) {
            if (!this.noTakeawayCategoryId) {
                return true;
            }
            if (this.assertProductIsAdditionalProduct(product) && !this.checkCartForNonAdditionalProduct()) {
                this.$toast.open({
                    position: 'top-right',
                    type: 'error',
                    message: this.$t('no_add_without_cake'),
                    duration: 3000,
                });
                return false;
            }
            return true;
        },
        assertProductIsAdditionalProduct(product) {
            return product.category_id.toString() === this.noTakeawayCategoryId.toString();
        },
        checkCartForNonAdditionalProduct() {
            return this.cartProducts.some(p => p.category_id.toString() !== this.noTakeawayCategoryId.toString());
        }
    }
}