<template>
  <div class="tt-basket">
    <div class="tt-basket__header">
      <h3 class="tt-basket__title ff-mont">
        {{ $t('home_cart_Basket_cart_title') }}
      </h3>
      <div class="tt-icon-button"
           @click="closeBasket">
        <img src="@/assets/icons-new/close_xl.svg" alt="close">
      </div>
    </div>

    <div class="tt-basket__main"
         v-if="isProductsVisible">
      <div class="tt-basket__products">
        <div class="tt-basket__product"
             v-for="(product, index) in products"
             :key="index">
          <BasketProduct :product="product"
                         :index="index" />
        </div>
      </div>

      <div>
        <div class="tt-basket__recommendations"
             v-if="isRecommendationsVisible">
          <Carousel :slides-length="recommendations.length">
            <template v-slot:title>
              {{ $t('home_cart_recommendations_title') }}
            </template>
            <template v-slot:slides>
              <div v-for="product in recommendations"
                   :key="product.id">
                <RecommendationProduct :product="product"/>
              </div>
            </template>
          </Carousel>
        </div>

        <div class="tt-basket__footer">
          <div class="tt-basket__total-price">
            <div class="tt-basket__total-price__text">
              {{ $t('home_cart_Basket_total_sum_orders') }}:
            </div>
            <div class="tt-basket__total-pricecontainer__amount">
              {{ numberWithSpaces(amount) }} ₸
            </div>
          </div>
          <button class="tt-button tt-button--primary"
                  @click="createOrder">
            {{ $t('home_cart_Basket_btn2create_order') }}
          </button>
        </div>
      </div>
    </div>

    <div v-else class="tt-basket__empty">
      <div class="tt-icon">
        <img src="@/assets/icons-new/cart_xl.svg" alt/>
      </div>
      <div class="tt-basket__empty__text">{{ $t('home_cart_Basket_cart_empty_title') }}</div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import BasketProduct from "@/components/basket/BasketProduct.vue";
import Carousel from "@/components/shared/Carousel.vue";
import RecommendationProduct from "@/components/basket/RecommendationProduct.vue";

export default {
  computed: {
    ...mapState({
      products: state => state.cart.products,
      productsQuantity: state => state.cart.productsQuantity,
      amount: state => state.cart.amount,
      recommendations: state => state.cart.recommendations
    }),
    isProductsVisible() {
      return this.productsQuantity > 0;
    },
    isRecommendationsVisible() {
      return this.recommendations.length > 0;
    }
  },
  methods: {
    closeBasket() {
      this.$emit('closeButtonClicked');
    },
    createOrder() {
      if (this.$route.path !== '/create-order') {
        this.$router.push('/create-order');
      }
      this.closeBasket();
    }
  },
  components: {
    RecommendationProduct,
    Carousel,
    BasketProduct
  }
};
</script>

<style lang="sass" scoped>
@import "src/assets/styles-new/colors"
@import "src/assets/styles-new/typography"

.tt-basket
  display: flex
  flex-direction: column
  height: 100%
  width: 100%
  background-color: $white
  padding: 0 4px
  &__header
    padding: 28px
    display: flex
    justify-content: space-between
    align-items: center
    border-bottom: 1px solid $light-gray
  &__title
    font-size: $fs-large
  &__main
    min-height: 0
    height: 100%
    display: flex
    flex-direction: column
    justify-content: space-between
  &__products
    border-bottom: 1px solid $light-gray
    overflow-y: scroll
    &::-webkit-scrollbar
      -webkit-appearance: none
      width: 4px
    &::-webkit-scrollbar-thumb
      background-color: $gray
      border-radius: 2px
  &__product
    padding: 28px
    border-bottom: 1px solid $light-gray
    &:last-child
      border-bottom: none
  &__recommendations
    padding: 28px
    border-bottom: 1px solid $light-gray
  &__footer
    padding: 20px 28px 28px
  &__total-price
    display: flex
    justify-content: space-between
    margin-bottom: 20px
    &__text
      font-size: 1.25rem
    &__amount
      font-size: 1.25rem
      font-weight: $fw-semibold
  .tt-button
    height: 56px
  &__empty
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    height: 100%
    &__text
      margin-top: 24px
      font-size: 1.25rem
      font-weight: $fw-medium
      color: $dark-gray

</style>
