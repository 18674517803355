<template>
  <div class="tt-auth">
    <div class="tt-auth__tabs">
        <div class="tt-auth__tab"
             @click.prevent="showLoginTab"
             :class="{ 'tt-auth__tab--active': showLogin }">
          {{ $t('navbar_auth_enter') }}
        </div>
        <div class="tt-auth__tab"
             @click.prevent="showRegister"
             :class="{ 'tt-auth__tab--active': !showLogin }">
          {{ $t('navbar_auth_register') }}
        </div>
    </div>

    <div class="tt-auth__content">
      <ActivationCode v-if="showActivationCode"
                      :user="user" />
      <Login v-else-if="showLogin"
             @submit="loginByCode($event)" />
      <SignUp v-else
              @submit="loginByCode($event)" />
    </div>
  </div>
</template>

<script>
import SignUp from "./SignUp.vue";
import ActivationCode from "./ActivationCode.vue";
import Login from "./Login.vue";

export default {
  data: () => ({
    user: {
      phone: null,
    },
    showLogin: true,
    showActivationCode: false,
  }),
  methods: {
    loginByCode(user) {
      this.user = user;
      this.showActivationCode = true;
    },
    showRegister() {
      this.showActivationCode = false;
      this.showLogin = false;
    },
    showLoginTab() {
      this.showActivationCode = false;
      this.showLogin = true;
    }
  },
  components: {
    SignUp,
    ActivationCode,
    Login,
  }
};
</script>

<style lang="sass" scoped>
@import "src/assets/styles-new/colors"
@import "src/assets/styles-new/typography"

.tt-auth
  &__tabs
    padding: 16px 24px 0
    border-bottom: 1px solid $light-gray
    display: flex
    gap: 14px
    font-weight: $fw-medium
  &__tab
    cursor: pointer
    padding: 10px
    &--active
      padding-bottom: 8px
      border-bottom: 2px solid $main
  &__content
    padding: 24px
</style>
