<template>
  <div class="tt-city">

    <!-- Обработка для городов с разделением на районы -->
    <div v-if="hasSomeArea">
      <div v-for="(areaAddresses, area) in separatedAddresses" :key="area">
        <div class="tt-city__divider mt-2">
          {{ $t('contacts_some_area_0' + area) }}
        </div>
        <div class="tt-city__grid">
          <AddressItem v-for="(address, index) in areaAddresses" :key="index" :address="address" />
        </div>
      </div>
    </div>

    <!-- Обработка для городов без разделения на районы -->
    <div v-else>
      <div class="tt-city__grid">
        <AddressItem v-for="(address, index) in addresses" :key="index" :address="address" />
      </div>
    </div>

  </div>
</template>

<script>
import AddressItem from "@/components/Contacts/AddressItem.vue";

export default {
  props: {
    addresses: {
      type: Array,
      required: true
    },
    some_area: {
      type: Boolean
    }
  },
  computed: {
    hasSomeArea() {
      return this.addresses.some(address => address.some_area !== null);
    },
    separatedAddresses() {
      return this.addresses.reduce((result, address) => {
        const key = address.some_area || 'default';
        if (!result[key]) result[key] = [];
        result[key].push(address);
        return result;
      }, {});
    }
  },
  components: {
    AddressItem
  }
}
</script>

<style src="../../assets/styles-new/city.sass" lang="sass" scoped>
</style>