<template>
  <div class="tt-user-birthday">
    <div class="tt-user-birthday__label">
      {{ $t('PersonalArea_views_birth_date') }}
    </div>
    <div class="tt-user-birthday__value">
      <div v-if="date">
        {{ date }}
      </div>
      <div class="tt-user-birthday__toggle-button"
           @click="toggleModal"
           v-else>
        {{ $t('Cabinet_User_info_enter_detail') }}
      </div>
    </div>

    <transition name="fade">
      <div class="tt-user-birthday__modal-container"
           v-if="showModal">
        <div class="tt-user-birthday__modal">
          <div class="tt-icon-button"
               @click="toggleModal">
            <img src="@/assets/icons-new/close.svg">
          </div>

          <div class="tt-input-field">
            <label class="tt-input-field__label">
              {{ $t('PersonalArea_views_birth_date') }}
            </label>
            <input type="date"
                   class="tt-input-field__input"
                   :class="{ 'tt-input-field__input--invalid': errors.length }"
                   v-model="birthdate">
            <div class="tt-input-field__error"
                 v-for="(err, index) in errors"
                 :key="index">
              {{ err }}
            </div>
          </div>

          <button class="tt-button tt-button--primary"
                  @click="updateBirthdate">
            {{ $t('PersonalArea_views_btn2save_popup') }}
          </button>
        </div>

        <div class="tt-user-birthday__modal-backdrop"
             @click="toggleModal"></div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  props: {
    date: null
  },
  data: () => ({
    birthdate: '',
    showModal: false,
    errors: []
  }),
  methods: {
    toggleModal() {
      this.showModal = !this.showModal;
    },
    updateBirthdate() {
      console.log(this.birthdate)
      this.$store.dispatch('auth/updateUser', { birthdate: this.birthdate })
          .then(response => {
            if (response.data.id !== undefined) {
              this.$store.commit('auth/SET_USER', response.data);
              this.toggleModal();
            }
          })
          .catch((error) => {
            if (error.response && error.response.status === 422) {
              this.errors = error.response.data.errors.birthdate;
            }
          });
    }
  }
}
</script>

<style lang="sass" scoped>
@import "src/assets/styles-new/typography"
@import "src/assets/styles-new/colors"
@import "src/assets/styles-new/vars"

.tt-user-birthday
  display: flex
  flex-direction: column
  gap: 8px
  &__label
    font-size: $fs-small
    color: $dark-gray
  &__value
    font-size: $fs-medium
    font-weight: $fw-medium
  &__toggle-button
    color: $main
    cursor: pointer
  &__modal-container
    position: fixed
    z-index: $zi-fixed
    top: 0
    left: 0
    right: 0
    bottom: 0
    display: flex
    align-items: center
    justify-content: center
  &__modal
    margin: 16px
    max-width: 400px
    position: relative
    z-index: $zi-modal
    background-color: $white
    border-radius: 8px
    overflow: hidden
    padding: 32px 24px 24px
    .tt-icon-button
      position: absolute
      top: 8px
      right: 8px
    .tt-button
      margin-top: 24px
  &__modal-backdrop
    position: absolute
    z-index: $zi-modal-backdrop
    width: 100%
    height: 100%
    background-color: rgba(0, 0, 0, 0.4)

@media screen and (max-width: $tablet-width)
  .tt-user-birthday
    gap: 6px

@media screen and (max-width: $mobile-width)
  .tt-user-birthday
    gap: 0
    &__value
      font-size: 1rem
</style>