import axios from 'axios';

const state = {
    products: [],
    novelties: [],
    noTakeawayCategoryId: null,
    activeCategoryTab: 0
    // greetingCards: [],
    // greetingCardCategoryId: null
};

const getters = {
};

const mutations = {
    SET_PRODUCTS(state, products) {
        state.noTakeawayCategoryId = products.no_takeaway_category_id || null;
        state.novelties = products.novelties;
        state.products = products.categories;
        // state.greetingCardCategoryId = products.greeting_card_category_id || null;
        // if (state.greetingCardCategoryId && products.categories.some(c => c.id === state.greetingCardCategoryId)) {
        //     state.products = products.categories.filter(category => category.id !== state.greetingCardCategoryId);
        //     state.greetingCards = products.categories.find(category => category.id === state.greetingCardCategoryId).products;
        // } else {
        //     state.products = products.categories;
        // }
    },
    SET_ACTIVE_CATEGORY_TAB(state, categoryId) {
        state.activeCategoryTab = categoryId;
    }
};

const actions = {
    getProducts(context) {
        const city = context.rootState.cities.activeCity;
        if (city) {
            axios
                .get(`product/list`, {
                    params: {
                        city_id: city.id
                    }
                })
                .then(response => {
                    context.commit('SET_PRODUCTS', response.data);
                    if (response.data.product_of_the_day && city.name === 'Астана') {
                        context.dispatch('getProductOfTheDay', response.data.product_of_the_day);
                    }
                });
        }
    },
    getProductById(context, id) {
        return axios
            .get(`product/${id}`)
            .then(response => response.data)
    },
    getProductOfTheDay(context, id) {
        context.dispatch('getProductById', id)
            .then(response => {
                context.commit('productOfTheDay/SET_PRODUCT_OF_THE_DAY', response, {root: true});
            });
    },
    setActiveCategory(context, id) {
        context.commit('SET_ACTIVE_CATEGORY_TAB', id);
    }
};

// Экспортируем наружу
export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}