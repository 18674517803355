<template>
  <div
    id="delivery_type_selector"
    class="tt-delivery-type"
    v-if="allProductsPickupOnly !== null"
  >
    <div class="tt-delivery-type__tabs ff-mont">
      <button
        v-if="!allProductsPickupOnly"
        class="tt-delivery-type__tab"
        :class="{
          'tt-delivery-type__tab--active': isDelivery && !isExpressDelivery,
        }"
        :disabled="!assertDeliveryMinAmount"
        @click="setIsDelivery(true, false)"
      >
        <span v-if="assertDeliveryMinAmount">
          {{ $t("OrderBasket_views_section_tabs_delivery") }}
        </span>
        <span v-else>
          {{ $t("OrderBasket_delivery_min_amount") }}
        </span>
      </button>
      <button
        class="tt-delivery-type__tab"
        :class="{ 'tt-delivery-type__tab--active': !isDelivery }"
        :disabled="!canPickup"
        @click="setIsDelivery(false, false)"
      >
        <span v-if="canPickup">
          {{ $t("OrderBasket_views_section_tabs_samovivoz") }}
        </span>
        <span v-else>
          {{ $t("OrderBasket_views_section_tabs_cant_samovivoz") }}
        </span>
      </button>

      <button
        v-if="
          !allProductsPickupOnly &&
          yaStartHour < new Date().getHours() &&
          yaStopHour > new Date().getHours()
        "
        class="tt-delivery-type__tab"
        :class="{
          'tt-delivery-type__tab--active': isDelivery && isExpressDelivery,
        }"
        :disabled="!assertDeliveryMinAmount"
        @click="setIsDelivery(true, true)"
      >
        <span v-if="assertDeliveryMinAmount" class="express-delivery-span">
          <img
            src="@/assets/icons-new/yandex.svg"
            class="express-delivery-logo"
            alt="yandex-logo"
          />
          {{ $t("OrderBasket_views_section_tabs_yandex_express") }}
        </span>
        <span v-else>
          {{ $t("OrderBasket_delivery_min_amount") }}
        </span>
      </button>
    </div>

    <TakeExpressDelivery v-if="isDelivery && isExpressDelivery " />
    <TakeDelivery v-if="isDelivery && !isExpressDelivery" />
    <TakePickup v-else-if="!isDelivery" />
  </div>
</template>

<script>
import { mapState,mapGetters } from "vuex";
import TakeDelivery from "@/components/CreateOrder/TakeDelivery.vue";
import TakePickup from "@/components/CreateOrder/TakePickup.vue";
import TakeExpressDelivery from "@/components/CreateOrder/TakeExpressDelivery.vue";

export default {
  components: { TakePickup, TakeDelivery, TakeExpressDelivery },
  created() {
    this.$store.commit("cart/CHECK_PRODUCTS_FOR_PICKUP_ONLY");
  },
  computed: {
    ...mapState({
      isDelivery: (state) => state.cart.isDelivery,
      isExpressDelivery: (state) => state.cart.isExpressDelivery,
      canPickup: (state) => state.stopList.canPickup,
      amount: (state) => state.cart.amount,
      minAmountForDelivery: (state) => state.cart.minAmountForDelivery,
      hasPickupOnlyProducts: (state) => state.cart.hasPickupOnlyProducts,
      allProductsPickupOnly: (state) => state.cart.allProductsPickupOnly,
      yaStopHour: (state) => state.cart.yaDeliveryStopHour,
      yaStartHour: (state) => state.cart.yaDeliveryStartHour,
    }),
    ...mapGetters({
      canProductPickup: 'stopList/canPickupProduct'
    }),
    assertDeliveryMinAmount() {
      return this.amount > this.$store.state.cart.minAmountForDelivery;
    },
  },
  methods: {
    setIsDelivery(isDelivery, isExpressDelivery) {
      this.$store.commit("cart/CHECK_PRODUCTS_FOR_PICKUP_ONLY");
      if (this.$store.state.cart.hasPickupOnlyProducts && isDelivery) {
        this.$emit("scroll-to-cart-block");
        this.$toast.open({
          position: "top-right",
          type: "error",
          message: this.$t("OrderBasket_views_delivery_validation_only_pickup"),
          duration: 3000,
        });
        return;
      }
      this.$store.commit("cart/SET_IS_DELIVERY", isDelivery);
      this.$store.commit("cart/SET_IS_EXPRESS_DELIVERY", isExpressDelivery);
      this.$store.commit("filial/SET_ACTIVE_FILIAL", {});
      this.$store.commit("cart/RESET_DELIVERY_STATE", {});
      // this.$store.commit("cart/SET_SELECTED_DELIVERY_TIME", null);
    },
  },

  mounted() {
    if (this.assertDeliveryMinAmount && !this.allProductsPickupOnly) {
      this.setIsDelivery(true, false);
    }
    if(this.amount > this.$store.state.cart.minAmountForDelivery){
      this.$store.commit("cart/SET_IS_DELIVERY", false);
    }
  },

  watch: {
    assertDeliveryMinAmount(newValue, oldValue) {
      if (!newValue && this.isDelivery) {
        this.setIsDelivery(false, false);
      }
    },
    amount(){
      this.$store.commit('cart/CHECK_PRODUCTS_FOR_PICKUP_ONLY')
      if(this.allProductsPickupOnly){
       this.setIsDelivery(false, false)
      }
    }
  },
};
</script>

<style lang="sass" scoped>
@import "src/assets/styles-new/colors"
@import "src/assets/styles-new/typography"
@import "src/assets/styles-new/vars"

.tt-delivery-type
  padding-bottom: 32px
  border-bottom: 2px solid $light-gray
  &__tabs
    margin-bottom: 40px
    padding-bottom: 7px
    border-bottom: 2px solid $light-gray
    display: flex
    gap: 72px
    @media all and (max-width: $mobile-width)
      flex-direction: column
  &__tab
    cursor: pointer
    background: none
    border: none
    outline: none
    font-weight: $fw-semibold
    font-size: 1.25rem
    color: $dark-gray
    display: flex
    align-items: center
    justify-content: center
    &:disabled
      cursor: auto
    &--active
      color: $main
      margin-bottom: -9px
      padding-bottom: 8px
      border-bottom: 2px solid $main

.express-delivery-logo
  height: 20px

@media screen and (max-width: $tablet-width)
  .tt-delivery-type
    padding-bottom: 24px
    &__tabs
      margin-bottom: 24px
      gap: 40px

@media screen and (max-width: $mobile-width)
  .tt-delivery-type
    &__tabs
      gap: 24px
  .express-delivery-span
    display: flex
  .express-delivery-logo
    margin-block: auto
</style>
