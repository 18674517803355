<template>
  <div class="tt-policy">
    <div class="tt-policy__title ff-mont">
      {{ $t('Policy_views_breadcrumbs_index') }}
    </div>

    <div class="tt-policy__links">
      <router-link to="/publicy_offer">
        {{ $t('Policy_views_href2public_offerta') }}
      </router-link>

      <a href="https://docs.google.com/document/d/1NBvYhv1ybXwbFovWCvRcSCRqjpRztbxiA4RRmjo1A1A/edit?usp=sharing"
         target="_blank">
        {{ $t('policy_publicy_offerta') }}
      </a>

      <a href="https://drive.google.com/file/d/1hjGNraQUH3JDdxkItta_rb2h_vc8mr_6/view?usp=sharing"
         target="_blank">
        {{ $t('Policy_views_href2tovarni_znak') }}
      </a>

      <a href="https://docs.google.com/document/d/1RBACLkEyp9UY5gGUwEy4TF-vp2vUx5AgIoQ8Ro0XMlM/edit#heading=h.ba6juylhpm36"
         target="_blank">
        {{ $t('policy_return')}}
      </a>
    </div>
  </div>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: this.$t('Policy_views_meta_title'),
      meta: [
        {
          vmid: 'description',
          property: 'description',
          name: 'description',
          content: this.$t('Policy_views_meta_description'),
        },
        {
          vmid: 'og:title',
          property: 'og:title',
          name: 'og:title',
          content: this.$t('Policy_views_meta_og_title'),
        },
        {
          vmid: 'og:description',
          property: 'og:description',
          name: 'og:description',
          content: this.$t('Policy_views_meta_og_title'),
        },
      ],
    }
  },
}
</script>

<style lang="sass" scoped>
@import "src/assets/styles-new/typography"
@import "src/assets/styles-new/vars"

.tt-policy
  padding: 40px 0 100px
  &__title
    font-size: $fs-larger
    line-height: 1.2em
    font-weight: $fw-semibold
    margin-bottom: 48px
  &__links
    display: flex
    flex-direction: column
    gap: 24px

@media screen and (max-width: $tablet-width)
  .tt-policy
    padding: 40px 0
    &__title
      font-size: $fs-large

@media screen and (max-width: $mobile-width)
  .tt-policy
    padding-top: 16px
    &__title
      font-size: $fs-semilarge
      margin-bottom: 32px
</style>