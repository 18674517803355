import axios from "axios";
const getDefaultState = () => {
    return {
        paymentTypes: [],
        selectedPaymentType: {},
    }
};

const state = getDefaultState()

// const getters = {
//     getModalName: state => () => state.showModal,
// };

const mutations = {
    SET_PAYMENT_TYPES(state, paymentTypes) {
        state.paymentTypes = paymentTypes;
    },
    SET_SELECTED_PAYMENT_TYPE(state, paymentType) {
        state.selectedPaymentType = paymentType
    },
    RESET_STATE(state) {
        Object.assign(state, getDefaultState())
    },
};

const actions = {
    getPaymentTypes(context) {
        const city = context.rootState.cities.activeCity;
        if (city) {
            return axios.get('payment_types', {
                    params: {
                        city_id: city.id
                    }
                })
                .then(response => {
                    context.commit('SET_PAYMENT_TYPES', response.data)
                })
        }
    }
};

// Экспортируем наружу
export default {
    namespaced: true,
    state,
    // getters,
    mutations,
    actions
}