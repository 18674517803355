<template>
  <nav>
    <ul class="tt-navigation">
      <li v-for="(menuItem, key) in navLinks"
          :key="key"
          v-if="shouldShowMenuItem(menuItem)"
      >
        <router-link class="tt-navigation__link ff-mont"
                     :class="{'tt-navigation__link--active': isActiveRoute(menuItem.url)}"
                     :to="{path:menuItem.url}">
          {{ $t(menuItem.title) }}
        </router-link>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  data: () => ({
  navLinks: [
    {
      title: "navbar_menu" ,
      url: '/',
    },
    {
      title: "navbar_bonus",
      url: '/promotion',
      restrictToCity: 'Астана'
    },
    {
      title: "navbar_aboutus",
      url: '/about'
    },
    {
      title: "navbar_contacts",
      url: '/contacts'
    },
    {
      title: 'navbar_franchise',
      url: '/franchising'
    },
  ]
  }),
  methods: {
    isActiveRoute(url) {
    if (url === '/') {
      return this.$route.path === url;
    }
    return this.$route.path.includes(url);
    },
    shouldShowMenuItem(menuItem) {
      if (!menuItem.restrictToCity) {
        return true;
      }

      const city = JSON.parse(localStorage.getItem('city'));
      return city && city.name === menuItem.restrictToCity;
    }
  }
};
</script>

<style lang="sass" scoped>
@import "src/assets/styles-new/colors"
@import "src/assets/styles-new/typography"

.tt-navigation
  margin: 0
  padding: 0
  display: flex
  list-style-type: none
  font-weight: $fw-semibold
  gap: 32px
  &__link
    text-decoration: none
    color: $dark-gray
    cursor: pointer
    &:hover
      color: $main
    &--active
      color: $main
      border-bottom: 2px solid $main
</style>
