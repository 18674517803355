<template>
  <div class="tt-promotion-faq">
    <div class="tt-promotion-faq__title ff-mont">
      FAQ
    </div>
    <div class="tt-promotion-faq__questions"
         v-for="(question, index) in questions"
         :key="index">
      <Dropdown>
        <template v-slot:label>
          <div class="tt-promotion-faq__question">
            {{ $t(question.question) }}
          </div>
        </template>
        <template v-slot:content>
          <div class="tt-promotion-faq__answer"
               v-html="$t(question.answer)"></div>
        </template>
      </Dropdown>
    </div>
  </div>
</template>

<script>
import Dropdown from "@/components/shared/Dropdown.vue";

export default {
  data: () => ({
    questions: [
      {
        question: "Help_question02_02",
        answer: "Help_Answer02_02"
      },
      {
        question: "Help_question02_03",
        answer: "Help_Answer02_03"
      },
      {
        question: "Help_question02_04",
        answer: "Help_Answer02_04"
      },
      {
        question: 'Help_question02_05',
        answer: 'Help_Answer02_05'
      },
      {
        question: "Help_question02_06",
        answer: "Help_Answer02_06"
      },
      {
        question: 'Help_question02_07',
        answer: 'Help_Answer02_07'
      },
    ]
  }),
  components: {
    Dropdown
  }
}
</script>

<style lang="sass" scoped>
@import "src/assets/styles-new/typography"
@import "src/assets/styles-new/vars"

.tt-promotion-faq
  &__title
    font-size: $fs-larger
    font-weight: $fw-semibold
    margin-bottom: 48px


@media screen and (max-width: $tablet-width)
  .tt-promotion-faq
    &__title
      font-size: $fs-large
      margin-bottom: 40px

@media screen and (max-width: $mobile-width)
  .tt-promotion-faq
    &__title
      font-size: $fs-semilarge
      margin-bottom: 28px
    &__question
      font-size: 1rem
    &__answer
      font-size: $fs-small
</style>