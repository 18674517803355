<template>
  <div class="tt-delivery">
    <div v-if="canDelivery">
      <div class="tt-delivery__form">
        <DeliveryAddress/>
      </div>

      <div class="tt-delivery__regulations">
        <div class="tt-icon">
          <img src="@/assets/icons-new/info.svg">
        </div>
        <p>
          {{ $t('TakeDelivery_attention_about_delivery') }}
          {{ $t('TakeDelivery_first_br') }}
          {{ $t('TakeDelivery_second_br') }}
          {{ $t('TakeDelivery_third_br') }}
        </p>
      </div>
    </div>

    <div class="tt-delivery__no-couriers"
         v-else>
      {{ $t('OrderBasket_views_section_tabs_cant_delivery') }}
    </div>
    <div class="tt-express-delivery__spinner-wrapper" v-if="isLoading">
      <Spinner />
    </div>
  </div>
</template>

<script>

import DeliveryAddress from "@/components/CreateOrder/DeliveryAddress.vue";
import Spinner from "@/components/shared/Spinner.vue";
import {mapGetters, mapState} from "vuex";

export default {
  computed: {
    ...mapState({
      canDelivery: state => state.stopList.canDelivery,
      isStopListLoading: state => state.stopList.loading
    }),
    isLoading() {
      return this.$store.state.cart.loading;
    }
  },
  components: {
    DeliveryAddress,
    Spinner
  }
};
</script>

<style lang="sass" scoped>
@import "src/assets/styles-new/typography"
@import "src/assets/styles-new/colors"
@import "src/assets/styles-new/vars"

.tt-delivery
  position: relative
  &__time
    display: grid
    grid-template-columns: repeat(2, 200px)
    column-gap: 40px
    margin-bottom: 32px
  &__regulations
    position: absolute
    top: 0
    right: 0
    max-width: 256px
    font-size: $fs-small
    .tt-icon
      justify-content: flex-start
  &__no-couriers
    color: $error

.tt-express-delivery__spinner-wrapper
  position: fixed
  top: 0
  left: 0
  width: 100%
  height: 100%
  background: rgba(255, 255, 255, 0.8)
  z-index: 1000
  display: flex
  justify-content: center
  align-items: center

@media screen and (max-width: 1199px)
  .tt-delivery__regulations
    max-width: 200px

@media screen and (max-width: $tablet-width)
  .tt-delivery
    &__time
      margin-bottom: 24px
    &__regulations
      display: none

@media screen and (max-width: $mobile-width)
  .tt-delivery
    &__time
      display: flex
      flex-direction: column
      gap: 16px
      margin-bottom: 16px

</style>
