<template>
  <div class="tt-image-lazy">
    <img v-lazy="imgSrcLazy" :alt="altText">
  </div>
</template>

<script>
import defaultImg from '../../assets/images-new/default-lazy-load.jpg';

export default {
  props: ['imgSrc', 'altText'],
  computed: {
    imgSrcLazy() {
      return {
        src: this.imgSrc,
        loading: defaultImg,
        error: defaultImg
      }
    }
  }
}
</script>

<style lang="sass" scoped>
.tt-image-lazy
  height: 100%
  width: 100%
  display: flex
  align-items: center
  justify-content: center
  border-radius: 8px
  overflow: hidden
  img
    height: 100%
    width: 100%
    object-fit: cover
</style>