<template>
  <div class="tt-icon tt-next-day-icon">
    <img src="@/assets/icons-new/stop-delivery.png" alt="stop-delivery">
    <div class="tt-tooltip">
      {{ $t('main_vpopup_available_for_pickup') }}
    </div>
  </div>
</template>

<script>
export default {
}
</script>

<style lang="sass" scoped>
@import "src/assets/styles-new/colors"
@import "src/assets/styles-new/vars"

.tt-next-day-icon
  position: absolute
  top: -10px
  left: -10px
  cursor: pointer
  &:hover
    .tt-tooltip
      display: block
</style>
