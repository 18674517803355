import axios from 'axios';

const state = {
    contacts: [],
};

const getters = {
};

const mutations = {
    SET_CONTACTS(state, contacts) {
        state.contacts = contacts;
    }
};

const actions = {
    async getContacts(context) {
        try {
            const response = await axios.get('contacts');
            context.commit('SET_CONTACTS', response.data);
            return response.data;
        } catch (error) {
            console.error("Error fetching contacts:", error);
            throw error;
        }
    },
};

// Экспортируем наружу
export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}