<template>
  <div id="contact_info_block"
       class="tt-contact-info">
    <div class="tt-contact-info__title ff-mont">
      {{ $t('OrderBasket_views_section_contacts') }}
    </div>

    <div v-if="!loggedIn">
      <div class="tt-contact-info__auth"
           v-if="showActivationCode">
        <ActivationCode :user="user"/>
      </div>
      <SignUp v-else
              :is-create-order-page="true"
              @submit="loginByCode($event)" />
    </div>

    <div class="tt-contact-info__details" v-else>
      <div class="tt-input-field">
        <label class="tt-input-field__label">
          {{ $t('OrderBasket_views_contacts_form_name') }}
        </label>
        <input class="tt-input-field__input"
               v-model="currentUser.name"
               disabled>
      </div>
      <div class="tt-input-field">
        <label class="tt-input-field__label">
          {{ $t('OrderBasket_views_contacts_form_phone') }}
        </label>
        <input class="tt-input-field__input"
               v-mask="'+7 (###) ###-##-##'"
               v-model="currentUser.phone"
               disabled>
      </div>
      <div class="tt-input-field"
           v-if="currentUser.birthdate">
        <label class="tt-input-field__label">
          {{ $t('OrderBasket_views_contacts_form_birth') }}
        </label>
        <input class="tt-input-field__input"
               v-model="currentUser.birthdate"
               disabled>
      </div>
    </div>

    <div class="tt-contact-info__for-friend"
         v-if="isDelivery">
      <div>
        <div class="tt-contact-info__for-friend-toggle">
          <input type="checkbox"
                 id="for-friend-checkbox"
                 class="tt-checkbox"
                 v-model="isForFriend"
                 @click="$emit('isForFriendToggled')">
          <label for="for-friend-checkbox"
                 class="tt-contact-info__for-friend-label">
            {{ $t('OrderBasket_views_payment_for_friend') }}
          </label>
        </div>
        <ProductForFriend v-if="isForFriend" />
      </div>

      <div class="tt-contact-info__regulations"
           v-if="isForFriend">
        <div class="tt-icon">
          <img src="@/assets/icons-new/info.svg">
        </div>
        <p>
          <b>{{ $t('OrderPage_cmpt_ProductForFriend_attention_title') }}</b>
          {{ $t('OrderPage_cmpt_ProductForFriend_term_01') }}
          {{ $t('OrderPage_cmpt_ProductForFriend_term_03') }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapState} from "vuex";
import ActivationCode from "@/components/auth/ActivationCode.vue";
import SignUp from "@/components/auth/SignUp.vue";
import ProductForFriend from "@/components/CreateOrder/ProductForFriend.vue";

export default {
  data: () => ({
    showActivationCode: false,
    user: {
      phone: null
    }
  }),
  computed: {
    ...mapState({
      isDelivery: state => state.cart.isDelivery
    }),
    ...mapGetters({
      loggedIn: 'auth/loggedIn',
      currentUser: 'auth/user'
    }),
    isForFriend: {
      get() {
        return this.$store.state.order.isForFriend;
      },
      set(bool) {
        this.$store.commit('order/SET_FOR_FRIEND', bool)
      }
    }
  },
  methods: {
    loginByCode(user) {
      this.user = user;
      this.showActivationCode = true;
    }
  },
  components: {
    ProductForFriend,
    SignUp,
    ActivationCode
  }
}
</script>

<style lang="sass" scoped>
@import "src/assets/styles-new/colors"
@import "src/assets/styles-new/typography"
@import "src/assets/styles-new/vars"

.tt-contact-info
  padding: 32px 0
  border-bottom: 2px solid $light-gray
  &__title
    font-size: $fs-semilarge
    font-weight: $fw-semibold
    margin-bottom: 32px
  &__auth
    max-width: 400px
  &__details
    display: grid
    grid-template-columns: repeat(3, 256px)
    gap: 32px
  &__for-friend
    margin-top: 32px
    display: flex
    gap: 40px
  &__for-friend-toggle
    display: flex
    align-items: center
    gap: 11px
  &__for-friend-label
    margin-bottom: 0
    font-weight: $fw-medium
  &__regulations
    font-size: $fs-small
    .tt-icon
      justify-content: flex-start

@media screen and (max-width: $tablet-width)
  .tt-contact-info
    padding: 24px 0
    &__title
      margin-bottom: 24px
    &__details
      grid-template-columns: repeat(2, 256px)
      row-gap: 24px
    &__for-friend
      display: block
    &__regulations
      display: none

@media screen and (max-width: $mobile-width)
  .tt-contact-info
    &__title
      margin-bottom: 16px
    &__auth
      max-width: 100%
    &__details
      display: flex
      flex-direction: column
      gap: 16px
    &__for-friend
      margin-top: 24px
</style>