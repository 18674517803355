<template>
  <div class="tt-novelties"
       v-if="novelties.length">
    <div class="tt-novelties__title ff-mont">
      {{ $t('home_views_novelty_products') }}
    </div>
    <div class="tt-novelties__grid">
      <div class="tt-novelties__product"
           v-for="product in novelties"
           :key="product.id">
        <ProductCard :product="product" />
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";
import ProductCard from "@/components/shared/ProductCard.vue";

export default {
  components: {ProductCard},
  computed: {
    ...mapState({
      novelties: state => state.products.novelties
    })
  }
}
</script>

<style lang="sass" scoped>
@import "src/assets/styles-new/typography"
@import "src/assets/styles-new/vars"

.tt-novelties
  &__title
    font-size: $fs-large
    font-weight: $fw-semibold
    margin-bottom: 20px
  &__grid
    display: grid
    grid-template-columns: repeat(auto-fill, 256px)
    justify-content: space-between
    gap: 16px
  &__product
    height: 392px
    position: relative

@media screen and (max-width: $tablet-width)
  .tt-novelties
    &__grid
      grid-template-columns: repeat(auto-fill, 216px)
    &__product
      height: 340px

@media screen and (max-width: $mobile-width)
  .tt-novelties
    &__grid
      grid-template-columns: repeat(auto-fill, 164px)
    &__product
      height: 280px
</style>