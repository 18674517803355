<template>
  <div class="tt-order-info">
    <div class="tt-order-info__title ff-mont">
      {{ $t('Order_views_your_order_title') }} #{{ order.id }}
    </div>
    <div v-if="!loading">
      <div class="tt-order-info__products">
        <div class="tt-order-info__product"
             v-for="product in (order.cart || {}).products">
          <div class="tt-order-info__product-info">
            <div class="tt-order-info__product-image">
              <ImageLazy :img-src="product.image" />
            </div>
            <div>
              <div class="tt-order-info__product-name">
                {{ product.name }}
              </div>
              <div class="tt-order-info__product-inscription">
                {{ product.inscription }}dd
              </div>
            </div>
          </div>
          <div class="tt-order-info__product-quantity">
            {{ product.quantity }}
          </div>
          <div class="tt-order-info__product-price">
            {{ numberWithSpaces(Number(product.price)*Number(product.quantity)) }} ₸
          </div>
        </div>
      </div>

      <div class="tt-order-info__details">
        <div class="tt-order-info__detail"
             v-if="order.delivery_price">
          <div class="tt-order-info__detail-label">
            {{ $t('Order_views_detalized_delivery_amount') }}
          </div>
          <div class="tt-order-info__detail-value">
            {{ order.delivery_price }} ₸
          </div>
        </div>

        <div class="tt-order-info__detail">
          <div class="tt-order-info__detail-label">
            {{ $t('Order_views_detalized_delivery_time') }}
          </div>
          <div class="tt-order-info__detail-value">
            {{ order.delivery_time_interval }}
          </div>
        </div>

        <div class="tt-order-info__detail">
          <div class="tt-order-info__detail-label"
               v-if="order.is_delivery">
            {{ $t('Order_views_detalized_delivery_date') }}
          </div>
          <div class="tt-order-info__detail-label"
               v-else>
            {{ $t('Order_views_detalized_samovivoz_date') }}
          </div>
          <div class="tt-order-info__detail-value">
            {{ order.delivery_date }}
          </div>
        </div>

        <div class="tt-order-info__detail"
             v-if="order.filial_address">
          <div class="tt-order-info__detail-label">
            {{ $t('OrderPage_cmpt_SideCheck_samovivoz_magazin') }}
          </div>
          <div class="tt-order-info__detail-value">
            {{ order.filial_address }}
          </div>
        </div>

        <div class="tt-order-info__detail"
             v-if="order.delivery_address">
          <div class="tt-order-info__detail-label">
            {{ $t('adress_dostavki_order') }}
          </div>
          <div class="tt-order-info__detail-value">
            {{ order.delivery_address }}
          </div>
        </div>

        <div class="tt-order-info__detail"
             v-if="order.friend_name">
          <div class="tt-order-info__detail-label">
            {{ $t('Order_views_friend_contact') }}
          </div>
          <div class="tt-order-info__detail-value">
            {{ order.friend_name }}, {{ order.friend_phone }}
          </div>
        </div>

        <div class="tt-order-info__detail">
          <div class="tt-order-info__detail-label">
            {{ $t('Order_views_detalized_amount_products') }}
          </div>
          <div class="tt-order-info__detail-value">
            {{ numberWithSpaces(order.amount) }} ₸
          </div>
        </div>
      </div>
    </div>

    <div class="tt-order-info__spinner-container"
         v-else>
      <Spinner />
    </div>
  </div>
</template>
<script>
import {mapState} from "vuex";
import Spinner from "@/components/shared/Spinner.vue";
import ImageLazy from "@/components/shared/ImageLazy.vue";

export default {
  components: {ImageLazy, Spinner},
  computed: {
    ...mapState({
      order: state => state.order.order,
      loading: state => state.order.loading
    })
  }
}
</script>

<style lang="sass" scoped>
@import "src/assets/styles-new/typography"
@import "src/assets/styles-new/colors"
@import "src/assets/styles-new/vars"

.tt-order-info
  &__title
    font-size: $fs-semilarge
    font-weight: $fw-medium
  &__products
    max-width: 600px
    display: flex
    flex-direction: column
  &__product
    margin-top: 16px
    display: grid
    grid-template-columns: 1fr 40px 100px
    gap: 16px
    padding-bottom: 16px
    border-bottom: 1px solid $gray
  &__product-info
    display: flex
  &__product-image
    position: relative
    height: 80px
    width: 80px
    margin-right: 16px
  &__product-name
    font-weight: $fw-medium
  &__product-inscription
    font-size: $fs-small
    color: $dark-gray
    line-height: 1rem
  &__product-quantity, &__product-price
    font-weight: $fw-medium
    font-size: $fs-medium
  &__product-quantity
    justify-self: center
  &__product-price
    justify-self: end
  &__details
    margin-top: 24px
    display: flex
    flex-wrap: wrap
    gap: 32px
  &__detail-label
    font-size: $fs-small
  &__detail-value
    font-weight: $fw-medium
    font-size: $fs-medium
  &__spinner-container
    max-width: 700px
    min-height: 200px
    position: relative

@media screen and (max-width: $mobile-width)
  .tt-order-info
    &__product-image
      display: none
</style>
