<template>
  <div class="tt-spinner"></div>
</template>

<script>
export default {
}
</script>

<style lang="sass" scoped>
@import "src/assets/styles-new/colors"

.tt-spinner
  width: 80px
  height: 80px
  border: 2px solid $light-gray
  border-top: 3px solid $main
  border-radius: 100%
  position: absolute
  top: 0
  bottom: 0
  left: 0
  right: 0
  margin: auto
  animation: spin 1s infinite linear
</style>