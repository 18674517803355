<template>
  <div class="tt-user-agreement">
    <div class="tt-user-agreement__title ff-mont">
      Пользовательское соглашение (действует с августа 2018 года)
    </div>
    <div class="tt-user-agreement_content">
      <h5>1. Общие положения</h5>
      <p>
        1.1. Настоящее Пользовательское соглашение (далее – Соглашение) является соглашением между Посетителем, Пользователем и Компанией, предметом которого является предоставление Компанией Посетителю, Пользователю доступа к ознакомлению с Сайтом.
      </p>
      <p>
        1.2. Посетитель и Пользователь обязаны полностью ознакомиться с настоящим Соглашением до момента использования функционала Сайта.
      </p>
      <p>
        1.3. В случае несогласия с перечисленными условиями Посетитель и Пользователь должны воздержаться от дальнейшего использования Сайта.
      </p>
      <p>
        1.4. Настоящее Соглашение действует только на территории Российской Федерации.
      </p>
      <p>
        1.5. Отношения Посетителя, Пользователя по покупке Товаров регулируются публичной офертой о продаже товаров дистанционным способом, указанной на Сайте.
      </p>
      <h5>2. Термины и&nbsp;определения</h5>
      <p>
        2.1. в&nbsp;настоящем Пользовательском соглашении, если из&nbsp;контекста не&nbsp;следует иное, нижеприведенные термины с&nbsp;заглавной буквы имеют следующие значения:
      </p>
      <p>
        2.1.1. <strong>«Компания»</strong>
      </p>
      <p>
        2.1.2. <strong>«Персональные данные»</strong> – персональные данные, которые Пользователь предоставляет о себе (имя (в том числе псевдоним); день рождения; адрес доставки; номер телефона; адрес электронной почты). Не могут быть отнесены к персональным данным Пользователя такие данные, как: номер телефона и адрес электронной почты, если обработка этих данных осуществляется обезличенно, то есть без привязки к персональным данным конкретного Пользователя. Данные, которые автоматически передаются в процессе их использования с помощью установленного на устройстве Пользователя программного обеспечения, в том числе IP-адрес, информация из cookie, информация о браузере Пользователя (или иной программе, с помощью которой осуществляется доступ к Сайту), время доступа, адрес запрашиваемой страницы не являются персональными данными.
      </p>
      <p>
        2.1.3. <strong>«Посетитель»</strong> – любое дееспособное лицо, желающее ознакомится с содержанием Сайта.
      </p>
      <p>
        2.1.4. <strong>«Пользователь»</strong> –  Посетитель, который уже имеет личную учетную запись.
      </p>
      <p>
        2.1.5. <strong>«Товары»</strong> представленная на Сайте.
      </p>
      <p>
        2.1.6. <strong>«Сайт»</strong> – <a href="https://taptatti.kz">taptatti.kz</a>.
      </p>
      <p>
        2.2. Для целей настоящего Соглашения термины и определения в единственном числе относятся также и к терминам и определениям во множественном числе и наоборот.
      </p>
      <p>
        2.3. Условия настоящего Соглашения для Сайта действуют и для Мобильного приложения (вне зависимости от платформы).
      </p>
      <h5>3. Предмет Соглашения</h5>
      <p>
        3.1. Предметом настоящего Соглашения являются порядок и правила использования функционала (сервисов) Сайта; условия обработки персональных данных, установленные Компанией для Посетителей и Пользователей Сайта.
      </p>
      <h5>4. Регистрация</h5>
      <p>
        4.1. Для регистрации учетной записи на Сайте  Пользователю следует произвести следующие действия:
      </p>
      <p>
        4.1.1. ввести  номер телефона в федеральном формате (+ХХХХХХХХХХХ); указанный Пользователем при регистрации номер телефона будет использоваться в качестве логина учетной записи;
      </p>
      <p>
        4.1.2. ввести пароль, предоставляемый Пользователю в виде SMS–сообщения на указанный им номер телефона;
      </p>
      <p>
        4.1.4. по желанию предоставить Компании адрес электронной почты, день рождения.
      </p>
      <p>
        4.2. Регистрация будет считаться завершенной после корректного ввода Пользователем пароля, полученного им в SMS–сообщении.
      </p>
      <p>
        4.3. Пользователю запрещается передавать данные своей учетной записи третьим лицам. В случае передачи Пользователем своего логина и(или) пароля третьему лицу, Пользователь несет ответственность за несанкционированные действия третьего лица, как за свои собственные.
      </p>
      <p>
        4.4. Пользователь несет ответственность за точность и правильность, полноту и достоверность указания своих личных данных при регистрации учетной записи на Сайте и за последствия, которые могут возникнуть у Пользователя, в случае некорректного ввода указанных сведений.
      </p>
      <p>
        4.5. Пользователь обязан незамедлительно уведомить Компанию о любом случае несанкционированного доступа к учетной записи Пользователя, а также о любом случае нарушения безопасности своего логина и пароля (утеря, передача данных третьим лицам, другое).
      </p>
      <p>
        4.6. Любые действия, совершенные на Сайте с использованием логина и пароля Пользователя, считаются совершенными соответствующим Пользователем.
      </p>
      <p>
        4.7. Пользователь несет ответственность за любую информацию, которая размещается на Сайте посредством его учетной записи.
      </p>
      <p>
        4.8. Компания вправе удалить учетную запись Пользователя в случае нарушения последним положений Соглашения.
      </p>
      <h5>5. Условия использования информации, размещенной на&nbsp;Сайте</h5>
      <p>
        5.1. Сайт включает, но не ограничивается, следующим: тексты, фотографии, графические изображения, товарные знаки и иные, охраняемые законом, материалы.
      </p>
      <p>
        5.2. Посетитель и/или Пользователь обязуются использовать Сайт только в законных целях и способами, не нарушающими права третьих лиц.
      </p>
      <p>
        5.3. Компания не несет ответственность за вред, причиненный технике Посетителя и/или Пользователя в случае, если это произошло в результате перехода по гипертекстуальным ссылкам, размещенным на Сайте.
      </p>
      <p>
        5.4. Компания вправе произвести приостановление оказания услуг пользования Сайтом, либо отказать Пользователю в возможности использования отдельных ресурсов Сайта в случае нарушения последним положений Соглашения.
      </p>
      <h5>6. Полномочия Компании</h5>
      <p>
        6.1. Компания оставляет за собой право без какого-либо специального уведомления вносить изменения в настоящее Cоглашение, в связи с чем Посетитель и/или Пользователь обязуется самостоятельно контролировать наличие изменений в настоящем Соглашении. Новая редакция Cоглашения вступает в силу с момента ее размещения, если иное не предусмотрено новой редакцией Соглашения.
      </p>
      <p>
        6.2. Компания оставляет за собой право изменять Сайт, в том числе изменять или добавлять в его структуру разделы, менять дизайн и совершать прочие действия, направленные на повышение функциональности Сайта.
      </p>

      <h5 id="7">7. Условия обработки персональных данных пользователей</h5>
      <p>
        7.1. Компания не проверяет достоверность персональной информации, предоставляемой Пользователями. Однако Компания исходит из того, что Пользователь предоставляет достоверную и достаточную персональную информацию, и поддерживает эту информацию в актуальном состоянии.
      </p>
      <p>
        7.2. Под обработкой персональных данных в настоящем Соглашении понимается: сбор Персональных данных, их систематизация, накопление, хранение, уничтожение.
      </p>
      <p>
        7.3. Цели обработки персональных данных:
      </p>
      <p>
        7.3.1. Обработка заказа;
      </p>
      <p>
        7.3.2. Оценка качества оказания услуг;
      </p>
      <p>
        7.3.3. Ведение учета потребителей (клиентов).
      </p>
      <p>
        7.4. Пользователь подтверждает, что его принятие Соглашения (отметка чекбокса «Соглашаюсь на обработку персональных данных на условиях пользовательского соглашения») означает полное согласие Пользователя со всеми его условиями без исключения.
      </p>
      <p>
        7.5. Пользователь вправе изменить данные своей учетной записи на сайте или потребовать ее удаления, позвонив по телефону 8 800 333-00-60 либо посредством направления Пользователем письма по электронной почте на адрес <a href="mailto:info@taptatti.kz">info@taptatti.kz</a>.
      </p>
      <p>
        7.6. При изменении номера телефона Пользователя, указанного при регистрации учетной записи, Компания оставляет за собой право удалить все сохраненные данные, привязанные к такому номеру телефона, включая, но не ограничиваясь: адрес, имя, дату рождения.
      </p>
      <p>
        7.7.
      </p>
      <p>
        7.8. Компания вправе передать Персональные данные Пользователя третьим лицам, в том числе путем трансграничной передачи, в следующих случаях:
      </p>
      <p>
        7.8.1. для использования Пользователем Сайта;
      </p>
      <p>
        7.8.2. для оказания услуг Пользователю;
      </p>
      <p>
        7.8.3. при продаже или иной передачи бизнеса Компании (полностью или в части), при этом к приобретателю переходят все обязательства по соблюдению условий Соглашения;
      </p>
      <p>
        7.8.4. в целях обеспечения возможности защиты прав и законных интересов Пользователей, Компании или третьих лиц;
      </p>
      <p>
        7.8.5.
      </p>
      <p>
        7.9. Компания имеет право назначить лицо (оператора Компании), ответственное за организацию обработки Персональных данных.
      </p>
      <p>
        7.10. Компания принимает необходимые и достаточные организационные и технические меры для защиты Персональных данных от неправомерного или случайного доступа, уничтожения, изменения, блокирования, копирования, распространения, а также от иных неправомерных действий с ней третьих лиц.
      </p>
      <p>
        7.11. Компания устанавливает следующие меры правового, организационного и правового характера для защиты Персональной информации: назначено ответственное лицо по организации обработки персональных данных; изданы и внедрены документы, определяющие политику общества в отношении обработки персональных данных, локальные акты, устанавливающие процедуры направленные на предотвращение и выявление нарушений законодательства РФ, устранение последствий таких нарушений; работники общества непосредственно осуществляющие обработку персональных данных, ознакомлены с положениями законодательства РФ «О персональных данных», в том числе требованиями к защите персональных данных, документами определяющими политику общества в отношении обработки персональных данных, локальными актами по вопросам обработки персональных данных.
      </p>
      <p>
        7.12. Компания хранит персональную информацию Пользователей, обеспечивая их конфиденциальность и защиту от неправомерного или случайного доступа к ним третьих лиц.
      </p>
      <p>
        7.13. Компания обязуется предотвращать попытки несанкционированного доступа к персональным данным Пользователей, предоставленных Компании; своевременно обнаруживать и пресекать такие попытки.
      </p>
      <p>
        7.14. Компания вправе произвести блокировку, исключение, удаление информации, размещенной Пользователем, без согласия последнего, если такая информация не отвечает требованиям действующего законодательства Российской Федерации и положениям Соглашения.
      </p>

      <h5 id="8">8. Технологии «cookie», используемые Компанией на Сайте</h5>
      <p>
        8.1. Компания вправе использовать технологию «cookie» для сохранения информации об ip-адресе Посетителей и Пользователей Сайта. «Cookie» не используются для сохранения конфиденциальной информации о Посетителях и Пользователях Сайта, и не направлены на установление личности Посетителя и Пользователей Сайта. Вся информация, которая собирается и анализируется, анонимна.
      </p>
      <p>
        8.2. Использование технологии «cookie» представляет собой размещение на Сайте определенного набора символов  (знаков), которые сохраняются на компьютере (далее – «устройство», с которого был выполнен вход на Сайт) Посетителей и Пользователей Сайта, при доступе к определенным местам Сайта.
      </p>
      <p>
        8.3. Цели применения Компанией технологии «cookie»:
      </p>
      <p>
        8.3.1. аналитическая деятельность по исследованию предпочтений Посетителей и Пользователей Сайта;
      </p>
      <p>
        8.3.2. статистика по активности Посетителей и Пользователей на Сайте;
      </p>
      <p>
        8.3.3. повышение производительности и эффективности Сайта;
      </p>
      <p>
        8.3.4. аналитика и исправление ошибок Сайта, улучшение работы всех ресурсов Сайта;
      </p>
      <p>
        8.3.5. безопасность и целостность ресурсов Сайта.
      </p>
    </div>
  </div>
</template>

<script>
export default {
}
</script>

<style lang="sass" scoped>
@import "src/assets/styles-new/typography"
@import "src/assets/styles-new/vars"

.tt-user-agreement
  padding: 40px 0 100px
  &__title
    font-size: $fs-larger
    line-height: 1.2em
    font-weight: $fw-semibold
    margin-bottom: 48px

@media screen and (max-width: $tablet-width)
  .tt-user-agreement
    padding: 40px 0
    &__title
      font-size: $fs-large

@media screen and (max-width: $mobile-width)
  .tt-user-agreement
    padding-top: 16px
    &__title
      font-size: $fs-semilarge
      margin-bottom: 32px
</style>
