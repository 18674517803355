import cookie from 'vue-cookies'
import axios from "axios";

const getDefaultState = () => {
    return {
        unauthorizedToken: null,
        products: [],
        recommendations: [],
        amount: 0,
        cartId: null,
        productsQuantity: 0,
        cashAmount: undefined,
        pickupDates: [],
        deliveryDates: [],
        pickupTimes: [],
        deliveryTimes: [],
        selectedPickupDate: null,
        selectedPickupTime: null,
        selectedDeliveryDate: null,
        selectedDeliveryTime: null,
        selectedExpressDeliveryDate: null,
        selectedExpressDeliveryTime: null,
        isDelivery: true,
        isExpressDelivery: false,
        timeIntervals: {},
        deliveryPrice: 0,
        canDelivery: true,
        deliveryAddress: {
            'country': null,
            'city': null,
            'street': null,
            'house': null,
            'flat': null,
            'entrance': null,
            'doorphone': null,
            'floor': null,
        },
        deliveryCoords: {
            latitude: null,
            longitude: null,
        },
        geoJson: {},
        loading: false,
        addLoading: false,
        maxPostPaymentAmount: 15000,
        minAmountForDelivery: 3000,
        hasPickupOnlyProducts: false,
        allProductsPickupOnly: null,
        yaDeliveryStartHour: null,
        yaDeliveryStopHour: null,
        // greetingCards: []
    }
};

const state = getDefaultState()

const getters = {
    getUnauthorizedToken() {
        return state.unauthorizedToken ? state.unauthorizedToken : cookie.get('unauthorized_token');
    },
    cartAmount() {
        return state.amount;
    },
    products() {
        return state.cart.products;
    },
    productsQuantity() {
        return state.cart.productsQuantity;
    },
    hasPickupOnlyProducts(state) {
        return state.products.some(product => product.is_special);
    },
    allProductsPickupOnly(state) {
        return state.products.every(product => product.is_special);
    },
    getPickupTimes(state) {
        return state.pickupTimes;
    },
    getPickupDates(state) {
        return state.pickupDates;
    },
    getDeliveryTimes(state) {
        return state.deliveryTimes;
    },
    getDeliveryDates(state) {
        if (state.deliveryDates.length)
            return state.deliveryDates.slice(0,7);
        return state.deliveryDates;
    },
    getSelectedDate(state) {
        if (state.isExpressDelivery) {
            return state.selectedExpressDeliveryDate;
        } else {
            return state.isDelivery ? state.selectedDeliveryDate : state.selectedPickupDate;
        }
    },
    getSelectedTime(state) {
        if (state.isExpressDelivery) {
            return state.selectedExpressDeliveryTime;
        } else {
            return state.isDelivery ? state.selectedDeliveryTime : state.selectedPickupTime;
        }
    },
    getOrderType(state) {
        return state.isDelivery ? 'delivery' : 'pickup';
    },
    getExpressDelivery(state) {
        return state.isExpressDelivery;
    },
    // greetingCards() {
    //     return state.greetingCards;
    // },
};

const mutations = {
    SET_UNAUTHORIZED_TOKEN(state, token) {
        state.unauthorizedToken = token;
        cookie.set('unauthorized_token', token);
    },
    SET_PRODUCTS(state, products) {
        state.products = products;
    },
    SET_RECOMMENDATIONS(state, recommendations) {
        state.recommendations = recommendations;
    },
    SET_AMOUNT(state, amount) {
        state.amount = amount;
    },
    RESET_CARD(state) {
        state.amount = 0;
        state.products = [];
        state.productsQuantity = 0;
        state.cartId = null;
        state.recommendations = [];
    },
    SET_PRODUCTS_QUANTITY(state, quantity) {
        state.productsQuantity = quantity;
    },
    SET_CART_ID(state, cartId) {
        state.cartId = cartId;
    },
    RESET_TOKEN(state) {
        state.unauthorizedToken = null;
        cookie.remove('unauthorized_token');
    },
    SET_CASH_AMOUNT(state, cashAmount) {
        state.cashAmount = cashAmount;
    },
    SET_SELECTED_PICKUP_DATE(state, selectedPickupDate) {
        state.selectedPickupDate = selectedPickupDate;
        state.pickupTimes = state.timeIntervals.pickup[state.selectedPickupDate];
        if (!state.pickupTimes.includes(state.selectedPickupTime)) {
            state.selectedPickupTime = state.pickupTimes[0];
        }
    },
    SET_SELECTED_PICKUP_TIME(state, selectedPickupTime) {
        state.selectedPickupTime = selectedPickupTime;
    },
    SET_SELECTED_DELIVERY_DATE(state, selectedDeliveryDate) {
        state.selectedDeliveryDate = selectedDeliveryDate;
        if (!state.isExpressDelivery) {
            state.deliveryTimes = state.timeIntervals.delivery[state.selectedDeliveryDate];
            if (!state.deliveryTimes.includes(state.selectedDeliveryTime)) {
                state.selectedDeliveryTime = state.deliveryTimes[0];
            }
        }
    },
    SET_SELECTED_DELIVERY_TIME(state, selectedDeliveryTime) {
        state.selectedDeliveryTime = selectedDeliveryTime;
    },
    SET_SELECTED_EXPRESS_DELIVERY_DATE(state, selectedExpressDeliveryDate) {
        state.selectedExpressDeliveryDate = selectedExpressDeliveryDate;
    },
    SET_SELECTED_EXPRESS_DELIVERY_TIME(state, selectedExpressDeliveryTime) {
        state.selectedExpressDeliveryTime = selectedExpressDeliveryTime;
    },
    SET_PERIODS(state, response) {
        const intervals = response.intervals;
        state.timeIntervals = intervals;
        if(intervals.delivery) {
            state.deliveryDates = Object.keys(intervals.delivery);
        }
        if (intervals.pickup) {
            state.pickupDates = Object.keys(intervals.pickup);
        }
        if (!state.selectedDeliveryDate) {
            const currentDate = new Date().toLocaleDateString('en-GB').replaceAll('/', '.');
            const currentTime = new Date().getHours();
            if (state.deliveryDates[0] === currentDate && currentTime > 15) {
                state.selectedDeliveryDate = state.deliveryDates[1];
            } else {
                state.selectedDeliveryDate = state.deliveryDates[0];
            }
        }
        if (!state.selectedPickupDate) {
            state.selectedPickupDate = state.pickupDates[0];
        }
        if (intervals.pickup) {
            state.pickupTimes = intervals.pickup[state.selectedPickupDate];
        }
        if (intervals.delivery) {
            state.deliveryTimes = intervals.delivery[state.selectedDeliveryDate];
        }
        if (!state.selectedPickupTime) {
            state.selectedPickupTime = state.pickupTimes[0];
        }
        if (!state.selectedDeliveryTime) {
            state.selectedDeliveryTime = state.deliveryTimes[0];
        }
    },
    SET_IS_EXPRESS_DELIVERY(state, isExpress) {
        state.isExpressDelivery = isExpress;
    },
    SET_IS_DELIVERY(state, isDelivery) {
        state.isDelivery = isDelivery;
    },
    SET_DELIVERY(state, delivery) {
        state.deliveryPrice = delivery.price;
        state.canDelivery = delivery.can_delivery;
    },
    SET_DELIVERY_ADDRESS(state, obj) {
        state.deliveryAddress.country = obj.getCountry();
        state.deliveryAddress.city = obj.getLocalities() ? obj.getLocalities()[0] : null;
        state.deliveryAddress.street = obj.getThoroughfare();
        state.deliveryAddress.house = obj.getPremiseNumber();
    },
    SET_DELIVERY_FLAT(state, flat) {
        state.deliveryAddress.flat = flat;
    },
    SET_DELIVERY_ENTRANCE(state, entrance) {
        state.deliveryAddress.entrance = entrance;
    },
    SET_DELIVERY_FLOOR(state, floor) {
        state.deliveryAddress.floor = floor;
    },
    SET_DELIVERY_DOORPHONE(state, doorphone) {
        state.deliveryAddress.doorphone = doorphone;
    },
    SET_DELIVERY_COORDS(state, coords) {
        state.deliveryCoords.latitude = coords[1];
        state.deliveryCoords.longitude = coords[0];
    },
    RESET_STATE(state) {
        Object.assign(state, getDefaultState())
    },
    SET_LOADING(state, payload) {
        state.loading = payload;
    },
    SET_ADD_LOADING(state, payload) {
        state.addLoading = payload;
    },
    SET_MAX_POST_PAYMENT_AMOUNT(state, maxPostPaymentAmount) {
        state.maxPostPaymentAmount = maxPostPaymentAmount;
    },
    SET_YA_DELIVERY_STOP_HOUR(state, yaDeliveryStopHour) {
        state.yaDeliveryStopHour = parseInt(yaDeliveryStopHour);
    },
    SET_YA_DELIVERY_START_HOUR(state, yaDeliveryStartHour) {
        state.yaDeliveryStartHour = parseInt(yaDeliveryStartHour);
    },
    SET_MIN_AMOUNT_FOR_DELIVERY(state, minAmountForDelivery) {
        state.minAmountForDelivery = minAmountForDelivery;
    },
    RESET_DELIVERY_STATE(state) {
        state.deliveryPrice = 0;
        Object.assign(state.deliveryAddress, getDefaultState().deliveryAddress);
        Object.assign(state.deliveryCoords, getDefaultState().deliveryCoords);
    },
    CHECK_PRODUCTS_FOR_PICKUP_ONLY(state) {
        state.hasPickupOnlyProducts = state.products.some(product => product.is_special);
        state.allProductsPickupOnly = state.products.every(product => product.is_special);
    },
    // SET_GREETING_CARDS(state) {
    //     localStorage.setItem('tt-greeting-cards', JSON.stringify(state.greetingCards));
    // },
    // RESET_GREETING_CARDS(state) {
    //     state.greetingCards = [];
    //     localStorage.removeItem('tt-greeting-cards');
    // }
};
const actions = {
    setAmount({commit}, price) {
        commit('SET_AMOUNT', price)
    },
    addProduct(context, data) {
        let product = null;
        let combos = null;
        if (data.product) {
            product = data.product;
            combos = data.combos;
        } else {
            product = data;
        }
        context.commit('SET_LOADING', true);
        context.commit('SET_ADD_LOADING', product.id);
        const params = {
            products: [{
                id: product.id,
                quantity: 1,
                inscription: product.inscription,
                combos
            }]
        }
        if (getters.getUnauthorizedToken()) {
            params.token = getters.getUnauthorizedToken();
        }
        return axios.post('cart/product', params)
            .then(response => {
                actions.setCart(context, response.data);
                return response.data;
            });
    },
    getCart(context) {
        if (!getters.getUnauthorizedToken() && !context.rootGetters['auth/loggedIn']) {
            return;
        }
        let params = {};
        if (getters.getUnauthorizedToken()) {
            params.token = getters.getUnauthorizedToken();
        }
        return axios.get('cart', {params: params})
            .then(response => {
                actions.setCart(context, response.data);
                // actions.matchGreetingCards(context, response.data.products);
            });
    },
    removeProduct(context, hash) {
        let params = {
            hash: hash
        };
        if (getters.getUnauthorizedToken()) {
            params.token = getters.getUnauthorizedToken();
        }
        return axios.delete('cart/product', {params: params})
            .then(response => {
                actions.setCart(context, response.data);
                // context.dispatch('filterGreetingCards', hash);
            });
    },
    removeCart(context) {
        let params = {};
        if (getters.getUnauthorizedToken()) {
            params.token = getters.getUnauthorizedToken();
        }
        return axios.delete('cart', {params: params})
            .then(() => {
                context.commit('RESET_CARD');
                context.commit('RESET_TOKEN');
                // context.commit('RESET_GREETING_CARDS');
            });
    },
    decrementProduct(context, product) {
        const params = {
            id: product.id,
            inscription: product.inscription,
        }
        if (getters.getUnauthorizedToken()) {
            params.token = getters.getUnauthorizedToken();
        }
        return axios.post('cart/product/decrement', params)
            .then(response => {
                actions.setCart(context, response.data);
                // if (product.quantity === 1) {
                //     context.dispatch('filterGreetingCards', product.hash);
                // }
            });
    },
    setCart(context, responseCart) {
        if (responseCart.token && !getters.getUnauthorizedToken()) {
            context.commit('SET_UNAUTHORIZED_TOKEN', responseCart.token);
        }
        context.commit('SET_PRODUCTS', responseCart.products);
        context.commit('SET_RECOMMENDATIONS', responseCart.recommendations);
        context.commit('SET_AMOUNT', responseCart.amount);
        context.commit('SET_CART_ID', responseCart.id);
        context.commit('SET_PRODUCTS_QUANTITY', responseCart.quantity_products);
        context.commit('SET_LOADING', false);
        context.commit('SET_ADD_LOADING', false);
    },
    getDeliveryInfo(context) {
        if (!context.state.deliveryCoords.latitude) {
            return;
        }
        const city = JSON.parse(localStorage.getItem('city'));
        const params = {
            ...context.state.deliveryCoords,
            is_express: context.state.isExpressDelivery ? 1 : 0,
            city_id: city ? city.id : 1
        };
        context.commit('SET_LOADING', true);

        return axios.get(`cart/${context.state.cartId}/delivery`, {params: params})
            .then(response => {
                context.commit('SET_DELIVERY', response.data);
                if (response.data.filial_id) {
                    context.commit('filial/SET_ACTIVE_FILIAL_BY_ID', response.data.filial_id, {root: true});

                    if (context.state.isExpressDelivery) {
                        let currentDate = new Date().toLocaleDateString('en-GB').replaceAll('/', '.');
                        context.commit('SET_SELECTED_EXPRESS_DELIVERY_DATE', currentDate);
                        context.commit('SET_SELECTED_EXPRESS_DELIVERY_TIME', Math.round(response.data.yandex.eta).toString() + ' мин');
                    }
                }
            })
            .finally(() => {
                context.commit('SET_LOADING', false);
            });
    },
    getDeliveryZone(context) {
        if (context.state.geoJson.type !== undefined) {
            return context.state.geoJson;
        }
        const city = JSON.parse(localStorage.getItem('city'));
        const params = {
            name: "delivery_zones",
            city_id: city ? city.id : null
        }
        return axios.get('file', {params: params})
            .then(response => {
                context.state.geoJson = response.data;

                return response.data;
            })
    },
    setExpressDelivery({ commit }, isExpress) {
        commit('SET_IS_EXPRESS_DELIVERY', isExpress);
    },
    // addGreetingCard(context, card) {
    //     if (!context.state.greetingCards.some(item => item.hash === card.hash)) {
    //         state.greetingCards.push(card);
    //         context.commit('SET_GREETING_CARDS');
    //     }
    // },
    // filterGreetingCards(context, hash) {
    //     if (state.greetingCards.some(card => card.hash === hash)) {
    //         state.greetingCards = state.greetingCards.filter(card => card.hash !== hash);
    //         context.commit('SET_GREETING_CARDS');
    //     }
    // },
    // matchGreetingCards(context, products) {
    //     const localStorageCards = JSON.parse(localStorage.getItem('tt-greeting-cards'));
    //     if (products.length > 0 && localStorageCards) {
    //         state.greetingCards = localStorageCards.filter(card => products.some(product => product.hash === card.hash));
    //     } else {
    //         context.commit('RESET_GREETING_CARDS');
    //     }
    // }
};

// Экспортируем наружу
export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
