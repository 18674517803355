<template>
  <div class="tt-account-nav">
    <div class="tt-account-nav__link">
      <span class="tt-icon">
        <img src="@/assets/icons-new/account.svg">
      </span>
      <span @click="navigateToCabinet">{{ $t('navbar_profile_title') }}</span>
    </div>
    <div class="tt-account-nav__link">
      <span class="tt-icon">
        <img src="@/assets/icons-new/logout.svg">
      </span>
      <span @click="logout">{{ $t('navbar_profile_logout') }}</span>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    logout() {
      this.$store.dispatch("auth/logout")
        .then(() => this.$emit("close"));
      if (this.$route.name !== 'Home') {
        this.$router.push('/');
      }
    },
    navigateToCabinet() {
      if (this.$route.path !== '/cabinet') {
        this.$router.push('/cabinet');
      }
      this.$emit("close");
    }
  },
};
</script>

<style lang="sass" scoped>
.tt-account-nav
  display: flex
  flex-direction: column
  gap: 8px
  padding: 16px 24px
  &__link
    display: flex
    align-items: center
    cursor: pointer
    &:hover
      filter: invert(59%) sepia(86%) saturate(419%) hue-rotate(135deg) brightness(89%) contrast(82%)
  .tt-icon
    margin-right: 8px
    height: 22px
</style>
