<template>
 <div class="tt-promotion-info">
   <div>
     <h2 class="tt-promotion-info__title ff-mont">
       {{ $t('promotion-header__h2') }}
     </h2>
     <p class="tt-promotion-info__subtitle">
       {{ $t('promotion-header__text') }}
     </p>
     <a href="https://registration.lo.cards/?hash=QkUuIT51OOwrCHOu"
        target="_blank">
       <button class="tt-button tt-button--primary">
         {{$t('promotion-header__button')}}
       </button>
     </a>
   </div>

   <div class="tt-promotion-info__image">
     <img src="@/assets/images-new/promotion_1.png">
   </div>

   <div class="tt-promotion-info__details">
     <p>
       {{ $t('promotion-birthday__bd-text') }}
       <b>10% {{ $t('promotion-birthday__percent-text') }}</b>
     </p>
     <div>
       {{ $t('promotion-birthday__use-case-header') }}
     </div>
     <ul>
       <li>{{ $t('promotion-birthday__use-case-1') }}</li>
       <li>{{ $t('promotion-birthday__use-case-2') }}</li>
       <li>{{ $t('promotion-birthday__use-case-3') }}</li>
     </ul>
   </div>

   <div class="tt-promotion-info__grid">
     <div class="grid-header grid-left-item">
       {{ $t('promotion-table__header-1') }}
     </div>
     <div class="grid-header grid-centered-item">
       {{ $t('promotion-table__header-2') }}
     </div>
     <div class="grid-left-item">
       {{ $t('promotion-table__value-1') }}
     </div>
     <div class="grid-centered-item">
       3%
     </div>
     <div class="grid-left-item">
       {{ $t('promotion-table__value-2') }}
     </div>
     <div class="grid-centered-item">
       5%
     </div>
     <div class="grid-left-item">
       {{ $t('promotion-table__value-3') }}
     </div>
     <div class="grid-centered-item">
       7%
     </div>
     <div class="grid-left-item">
       {{ $t('promotion-table__value-4') }}
     </div>
     <div class="grid-centered-item">
       10%
     </div>
   </div>
 </div>
</template>

<script>
export default {
}
</script>

<style lang="sass" scoped>
@import "src/assets/styles-new/typography"
@import "src/assets/styles-new/colors"
@import "src/assets/styles-new/vars"

.tt-promotion-info
  display: grid
  grid-template-columns: 1fr 544px
  gap: 100px
  margin-bottom: 100px
  &__title
    font-size: $fs-larger
    font-weight: $fw-semibold
    margin-bottom: 16px
  &__subtitle
    font-size: $fs-small
    margin-bottom: 40px
  &__image
    height: 330px
    width: 544px
    overflow: hidden
    border-radius: 8px
    img
      width: 100%
      height: 100%
      object-fit: cover
  &__details
    font-size: $fs-small
    ul
      padding-left: 1rem
  &__grid
    font-size: $fs-small
    display: grid
    grid-template-columns: 1.5fr 1fr
    .grid-centered-item, .grid-left-item
      border-bottom: 1px solid $gray
      padding: 12px 0
    .grid-header
      color: $dark-gray
      padding-bottom: 8px
      padding-top: 0
    .grid-centered-item
      text-align: center

@media screen and (max-width: $tablet-width)
  .tt-promotion-info
    grid-template-columns: 1fr
    gap: 40px
    margin-bottom: 40px
    &__title
      font-size: $fs-large
    &__subtitle
      margin-bottom: 24px
    &__image
      grid-row-start: 1
      width: 100%
      height: 302px

@media screen and (max-width: $mobile-width)
  .tt-promotion-info
    gap: 32px
    &__title
      font-size: $fs-semilarge
    &__image
      grid-row-start: 2
      height: 207px
</style>