<template>
  <div class="tt-franchise">
    <div class="tt-contact-us">
      <div>
        <div class="tt-contact-us__title ff-mont">
          {{ $t('navbar_franchise') }}
        </div>
        <div class="tt-rental-premises__description">
          <div v-html="$t('franchise_description')"></div>
        </div>
<br /><br />
        <button class="tt-button tt-button--primary"
                :disabled="loading"
                @click="linkToFranchising"
        >
          {{ $t('franchise_btn_to_franchising') }}
        </button>

      </div>

      <form class="tt-contact-us__form"
            v-if="!formSent">
        <div class="tt-input-field">
          <label class="tt-input-field__label">
            {{ $t('about_component_partners_input_name') }}
          </label>
          <input class="tt-input-field__input"
                 :class="{ 'tt-input-field__input--invalid': isInvalid('name') }"
                 :placeholder="$t('franchise_fio')"
                 v-model="form.name">
          <div class="tt-input-field__error">
            {{ showError(errors.name) }}
          </div>
        </div>

        <div class="tt-input-field">
          <label class="tt-input-field__label">
            {{ $t('about_component_partners_input_email') }}
          </label>
          <input class="tt-input-field__input"
                 :class="{ 'tt-input-field__input--invalid': isInvalid('email') }"
                 :placeholder="$t('about_component_partners_placeholder_email')"
                 v-model="form.email">
          <div class="tt-input-field__error">
            {{ showError(errors.email) }}
          </div>
        </div>

        <div class="tt-input-field">
          <label class="tt-input-field__label">
            {{ $t('about_component_partners_input_phone') }}
          </label>
          <input type="tel"
                 class="tt-input-field__input"
                 :class="{ 'tt-input-field__input--invalid': isInvalid('phone') }"
                 v-mask="'+7 (###) ###-##-##'"
                 placeholder="+7 (___) ___ __ __"
                 v-model="form.phone">
          <div class="tt-input-field__error">
            {{ showError(errors.phone) }}
          </div>
        </div>

        <div class="tt-input-field" v-for="(item, index) in questionList" :key="index">
          <label class="tt-input-field__label">
            {{ $t(item.question) }}
          </label>
          <div class="tt-v-select" v-if="item.type === 'dropdown'">
            <v-select
                v-model="item.answer"
                :clearable="false"
                :searchable="false"
                :options="item.options"
                :disabled="!item.options.length"
                label="value"
                :class="{ 'tt-input-field__input--invalid': isAnswerInvalid(index) }"
            />
          </div>

          <textarea
              v-else-if="item.type === 'textarea'"
              :maxlength="item.maxLength"
              class="tt-input-field__input"
              v-model="item.answer"
              :class="{ 'tt-input-field__input--invalid': isAnswerInvalid(index) }"
          >
          </textarea>

          <input v-else class="tt-input-field__input"
                 v-model="item.answer"
                 :class="{ 'tt-input-field__input--invalid': isAnswerInvalid(index) }"
          >
        </div>

        <button class="tt-button tt-button--primary"
                :disabled="loading"
                @click.prevent="submit">
          {{ $t('contacts_widget_btn2send') }}
        </button>
      </form>

      <div class="tt-contact-us__success"
           v-else>
        <p>{{ $t('contacts_widget_success_message') }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import {required} from "vuelidate/lib/validators";
import {phoneValidator} from "@/mixins/validators";

export default {
  name: "Franchise",
  data: () => ({
    form: {},
    errors: {},
    loading: false,
    attemptedSubmit: false,
    formSent: false,
    questionList: [
      {
        question: 'franchise_form_your_social_network',
        answer: '',
      },
      {
        question: 'Ваш город и город открытия',
        answer: '',
        type: 'dropdown',
        options: [
          'Алматы',
          'Астана',
          'Шымкент',
          'Актобе',
          'Караганда',
          'Тараз',
          'Усть-Каменогорск',
          'Павлодар',
          'Атырау',
          'Семей',
          'Кызылорда',
          'Актау',
          'Костанай',
          'Уральск',
          'Туркестан',
          'Петропавловск',
          'Кокшетау',
          'Темиртау',
          'Талдыкорган',
          'Экибастуз',
          'Рудный',
          'Жезказган',
          'Каскелен',
          'Жанаозен',
          'Кентау',
          'Балхаш',
          'Сатпаев',
          'Кульсары',
          'Талгар',
          'Сарыагаш',
          'Конаев',
          'Косшы',
          'Жаркент',
          'Арыс',
          'Аксу',
          'Степногорск',
          'Щучинск',
          'Шу',
          'Жетысай',
          'Риддер',
          'Аягоз',
          'Есик',
          'Шахтинск',
          'Аральск',
          'Аксай',
          'Алтай',
          'Кандыагаш',
          'Житикара',
          'Сарань',
          'Байконур',
          'Ленгер',
          'Шардара',
          'Лисаковск',
          'Атбасар',
          'Текели',
          'Хромтау',
          'Абай',
          'Аркалык',
          'Тобыл',
          'Каратау',
          'Шалкар',
          'Жанатас',
          'Алга',
          'Ушарал',
          'Уштобе',
          'Зайсан',
          'Шемонаиха',
          'Макинск',
          'Сарканд',
          'Акколь',
          'Тайынша',
          'Эмба',
          'Ерейментау',
          'Есиль',
          'Приозёрск',
          'Курчатов',
          'Каркаралинск',
          'Форт-Шевченко',
          'Булаево',
          'Каражал',
          'Сергеевка',
          'Казалинск',
          'Серебрянск',
          'Мамлютка',
          'Державинск',
          'Шар',
          'Степняк',
          'Темир',
          'Жем'
        ]
      },
      {
        question: 'Время проживания в данном городе',
        answer: '',
      },
      {
        question: 'Ваш возраст',
        answer: '',
        maxLength: 500
      },
      {
        question: 'Ваше образование',
        answer: '',
        type: 'dropdown',
        options: [
          'Средне-специальное',
          'Бакалавр',
          'Магистратура',
          'PhD'
        ]
      },
      {
        question: 'Чем занимаетесь на данный момент? (бизнес, инвестиции, наемная работа)',
        answer: '',
      },
      {
        question: 'Расскажите про Ваш опыт в бизнесе, если он есть? Или об опыте в карьере',
        answer: '',
        type: 'textarea',
        maxLength: 500
      },
      {
        question: 'Есть ли у Вас опыт работы с франшизой?',
        answer: '',
        type: 'textarea',
        maxLength: 500
      },
      {
        question: 'Почему Вас заинтересовала именно наша сфера бизнеса?',
        answer: '',
      },
      {
        question: 'Почему Вас заинтересовала именно наша франшиза?',
        answer: '',
      },
      {
        question: 'Каким бюджетом на открытие бизнеса Вы обладаете?',
        answer: '',
        type: 'dropdown',
        options: [
          'менее 20 млн',
          'между 20 и 30 млн',
          'между 30 и 50 млн',
          'между 50-100 млн',
          'между 100-120 млн',
          'более 120 млн'
        ]
      },
      {
        question: 'Источник инвестиций?',
        answer: '',
        type: 'dropdown',
        options: [
          'собственные средства',
          'кредит',
          'прочие инвестиции',
          'займы'
        ]
      },
      {
        question: 'Сколько средств Вы планируете инвестировать?',
        answer: '',
      },
      {
        question: 'Планируете ли Вы привлекать партнеров для открытия филиала?',
        answer: '',
        type: 'dropdown',
        options: [
          'Да',
          'Нет'
        ]
      },
      {
        question: 'Какими ресурсами обладаете сейчас?',
        answer: '',
        type: 'dropdown',
        options: [
          'Помещение',
          'оборудование',
          'опытная команда под бизнес'
        ]
      },
      {
        question: 'Имеются ли у Вас коммерческие помещения?',
        answer: '',
        type: 'dropdown',
        options: [
          'Да',
          'Нет'
        ]
      },
      {
        question: 'Если имеются коммерческие помещения, то какого размера?',
        answer: '',
        type: 'dropdown',
        options: [
          'менее 40кв',
          'между 40 и 90кв',
          'между 90 и 150 кв',
          'более 150кв'
        ]
      },
      {
        question: 'Если имеются коммерческие помещения, под какую деятельность рассчитаны?',
        answer: '',
        type: 'textarea',
        maxLength: 500
      },
      {
        question: 'В собственности у Вас или в аренде?',
        answer: '',
        type: 'dropdown',
        options: [
          'собственное',
          'арендуемое'
        ]
      },
      {
        question: 'Рассматриваете ли Вы этот бизнес как основную сферу деятельности или дополнительную?',
        answer: '',
        type: 'dropdown',
        options: [
          'основная',
          'дополнительная'
        ]
      },
      {
        question: 'Уровень дохода, на который планируете выйти по нашей франшизе через год?',
        answer: '',
      },
      {
        question: 'Численность население города, в котором Вы хотите открыться?',
        answer: '',
      },
      {
        question: 'Средний доход в данной области или городе?',
        answer: '',
      },
      {
        question: 'Сколько филиалов Вы рассчитываете открыть в данном городе в течение 1 года?',
        answer: '',
      },
      {
        question: 'Сколько филиалов Вы рассчитываете открыть в данном городе в течение 3 лет?',
        answer: '',
      },
      {
        question: 'Хотите ли Вы взять область или только город?',
        answer: '',
        type: 'dropdown',
        options: [
          'область',
          'город'
        ]
      },
      {
        question: 'Намерены ли Вы лично заниматься этим бизнесом? Если нет, то напишите с кем.',
        answer: '',
        type: 'dropdown',
        options: [
          'лично',
          'партнер',
          'найму управляющего',
          'другое',
        ]
      },
      {
        question: 'Наличие и количество свободного времени?',
        answer: '',
      },
      {
        question: 'Покупку каких альтернативных франшиз Вы рассматривали?',
        answer: '',
      },
      {
        question: 'Что Вы лично ожидаете получить от сотрудничества?',
        answer: '',
      },
      {
        question: 'В случае одобрения Вашей кандидатуры Управляющей компанией, когда готовы начать открытие нашего филиала?',
        answer: '',
      }
    ]
  }),
  validations() {
    return {
      form: {
        phone: {
          required,
          phoneValidator
        },
        email: {
          required
        },
        name: {
          required
        }
      },
    }
  },
  methods: {
    linkToFranchising() {
      this.$router.push("/franchising");
    },
    isInvalid(prop) {
      return this.$v.form[prop].$dirty && this.$v.form[prop].$invalid;
    },
    showError(err) {
      return typeof err === 'object' ? err[0] : err;
    },
    isAnswerInvalid(index) {
      const item = this.questionList[index];
      const answer = item.answer || '';
      const trimmedAnswer = answer.trim();

      // Если еще не было попытки отправки, то не показываем ошибку.
      // if (!this.attemptedSubmit) {
      //   return false;
      // }
      //
      // if (!trimmedAnswer) {
      //   return true;
      // }
      //
      // if (item.type === 'textarea' && trimmedAnswer.length < 0) {
      //   return true;
      // }
      //
      // return !item.type && trimmedAnswer.length < 0;
    },
    allAnswersValid() {
      return this.questionList.every((item, index) => !this.isAnswerInvalid(index));
    },
    async submit() {
      this.attemptedSubmit = true;
      if (this.$v.form.$invalid || !this.allAnswersValid()) {
        this.$v.form.$touch();
        return;
      }

      this.loading = true;
      let formData = new FormData();

      Object.keys(this.form).forEach(item => {
        formData.append(item, this.form[item]);
      });
      formData.append('type', 'suggestion');

      const message = this.questionList 
          .map(item => `<b>${item.question}</b>: <br /> ${item.answer}`) 
          .join('<br /><br />') 
      formData.append('message', message);

      try {
        await this.$axios.post( "/feedback", formData);
        this.formSent = true;
      } catch (e) {
        console.error(e.response.data);
        this.errors = (e.response.data || {}).errors;
        if (this.errors.file !== undefined) {
          this.$toast.open({
            position: 'top-right',
            type: 'error',
            message: this.errors.file[0],
            duration: 3000,
          });
        }
      } finally {
        this.loading = false;
      }
    }
  },
  metaInfo() {
    return {
      title: this.$t('franchising_views_meta_title'),
      meta: [{
        vmid: 'description',
        property: 'description',
        content: this.$t('franchising_views_meta_description')
      }, {
        vmid: 'og:title',
        property: 'og:title',
        content: this.$t('franchising_views_meta_og_title')
      }, {
        vmid: 'og:description',
        property: 'og:description',
        content: this.$t('franchising_views_meta_og_description')
      },],
    }
  }
}
</script>

<style scoped lang="sass">
@import "src/assets/styles-new/typography"
@import "src/assets/styles-new/vars"
@import "src/assets/styles-new/colors"

.tt-franchise
  padding: 40px 0 100px

.tt-v-select .v-select.tt-input-field__input--invalid
  border-color: red

.tt-v-select .v-select.tt-input-field__input--invalid .vs__selected-options
  border-color: red

.tt-contact-us
  display: flex
  justify-content: space-between
  gap: 32px
  &__title
    font-size: $fs-larger
    line-height: 1.2em
    font-weight: $fw-semibold
    margin-bottom: 24px
  &__subtitle
    margin-bottom: 16px
    font-size: $fs-small
  &__contacts
    display: grid
    grid-template-columns: 100px 1fr
    font-weight: $fw-medium
  &__form
    width: 100%
    max-width: 544px
    .tt-input-field
      margin-bottom: 10px
    .tt-button
      margin-top: 30px
      height: 56px
    .tt-textarea
      height: 140px
  &__success
    margin-top: 8px
    color: $main
    font-weight: $fw-medium

@media screen and (max-width: $tablet-width)
  .tt-contact-us
    &__title
      font-size: $fs-large

@media screen and (max-width: $mobile-width)
  .tt-contact-us
    flex-direction: column
    gap: 0
    &__title
      font-size: $fs-semilarge
      margin-bottom: 16px
    &__contacts
      font-size: $fs-small
      margin-bottom: 40px

</style>
