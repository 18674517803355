<template>
  <div class="tt-contacts">
    <div class="tt-contacts__title ff-mont">
      {{ $t('navbar_contacts') }}
    </div>

    <!-- Если есть контакты, отобразите Dropdown для каждого из них -->
    <div v-if="contacts && contacts.length">
      <Dropdown v-for="city in contacts" :key="city.id">
        <template v-slot:label>{{ city.name }}</template>
        <template v-slot:content>
          <CityContacts :addresses="city.contacts" :some_area="!!city.is_some_area"/>
        </template>
      </Dropdown>
    </div>
    <!-- Если нет контактов, отобразите Spinner -->
    <div class="tt-product__spinner-wrapper" v-else>
      <Spinner />
    </div>

    <div class="tt-contacts__contact-us">
      <ContactUsForm />
    </div>
  </div>
</template>

<script>
import ContactUsForm from "@/components/shared/ContactUsForm.vue";
import Spinner from "@/components/shared/Spinner.vue";
import Dropdown from "@/components/shared/Dropdown.vue";
import CityContacts from "@/components/Contacts/CityContacts.vue";

export default {
  data: () => ({
    contacts: []
  }),
  components: {
    Spinner,
    CityContacts,
    Dropdown,
    ContactUsForm,
  },
  metaInfo() {
    return {
      title: this.$t('contacts_title_page_meta'),
      meta: [
        {
          vmid: 'description',
          name: 'description',
          content: this.$t('contacts_description_meta')
        },
        {
          vmid: 'og:title',
          name: 'og:title',
          content: this.$t('contacts_og_title_meta')
        },
        {
          vmid: 'og:description',
          name: 'og:description',
          content: this.$t('contacts_og_description_meta')
        },
      ],
    }
  },
  mounted() {
    this.$store.dispatch('contacts/getContacts')
        .then(resp => this.contacts = resp)
        .catch(error => {
          console.error("Error fetching contacts:", error);
        });
  },
}
</script>

<style lang="sass" scoped>
@import "src/assets/styles-new/typography"
@import "src/assets/styles-new/vars"

.tt-contacts
  padding: 40px 0 100px
  &__title
    font-size: $fs-larger
    line-height: 1.2em
    font-weight: $fw-semibold
    margin-bottom: 48px
  &__contact-us
    padding-top: 60px

@media screen and (max-width: $tablet-width)
  .tt-contacts
    padding: 40px 0
    &__title
      font-size: $fs-large
    &__contact-us
      padding-top: 40px

@media screen and (max-width: $mobile-width)
  .tt-contacts
    padding-top: 16px
    &__title
      font-size: $fs-semilarge
      margin-bottom: 32px
    &__contact-us
      padding-top: 32px
</style>
