<template>
  <form class="tt-login">
    <div class="tt-input-field">
      <label class="tt-input-field__label"
             for="login-phone-input">
        {{ $t('navbar_login_index_loginPhoneInput') }}
      </label>
      <input type="tel"
             id="login-phone-input"
             class="tt-input-field__input"
             :class="{ 'tt-input-field__input--invalid': validationErrors.phone || isInvalid }"
             v-mask="'+7 (###) ###-##-##'"
             placeholder="+7 (___) ___ __ __"
             v-model="user.phone">
      <div class="tt-input-field__error"
           v-for="(errorMessage, errorKey) in validationErrors.phone"
           :key="errorKey">
        {{ $t(errorMessage) }}
      </div>
    </div>

    <input
      type="submit"
      class="tt-button tt-button--primary"
      :value="$t('navbar_login_index_get_code')"
      @click.prevent="sendActivationCode"
    />
  </form>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import { phoneValidator } from "../../mixins/validators";

export default {
  data: () => ({
    validationErrors: {},
    user: {
      phone: null,
    }
  }),
  computed: {
    isInvalid() {
      return this.$v.user.$dirty && this.$v.user.$invalid;
    }
  },
  validations: {
    user: {
      phone: {
        required,
        phoneValidator,
      },
    },
  },
  methods: {
    sendActivationCode() {
      if (this.$v.user.$invalid) {
        this.$v.user.$touch();
        return;
      }

      this.$store.dispatch("auth/getActivationCode", {
        phone: this.user.phone.replace(/\D+/g, ""),
        city_id: this.user?.city?.id || 1
      })
        .then(() => {
          this.$emit("submit", this.user);
        })
        .catch((error) => {
          if (error.response && error.response.status === 422) {
            this.validationErrors = error.response.data.errors;
          }
        });
    },
  },
};
</script>

<style lang="sass" scoped>
@import "src/assets/styles-new/colors"
@import "src/assets/styles-new/typography"

.tt-input-field
  margin-bottom: 24px
</style>
