<template>
  <div class="tt-product-modal">
    <div v-if="modifierProducts  && modifierProducts.length" class="tt-product-modal__modifier">
      <div 
        v-for="productModifier in modifierProducts" 
        :key="productModifier.id" 
        @click="changeSelectedComboProducts(productModifier.id, productModifier.pivot.group_modifier_id)"
        class="modifiers"
      >
        <img :src="productModifier.image" class="modifier-image" />
        <div class="modifier-name">{{  productModifier.name }}</div>
        <div v-if="getPriceDiff(productModifier.price, productModifier.pivot.group_modifier_id) > 0" class="modifier-diff">
          <button class="modifier-diff-button" type="button">
            +{{ getPriceDiff(productModifier.price, productModifier.pivot.group_modifier_id) }} тг
          </button>
        </div>
      </div>
    </div>
    <div class="tt-product-modal__images" v-else>
      <VueSlickCarousel ref="c1"
                        :as-nav-for="c2"
                        :draggable="false"
                        :focus-on-select="true"
                        :arrows="false">
        <div class="tt-product-modal__selected-image"
             v-for="image in product.images">
          <ImageLazy :img-src="image.img" />
        </div>
      </VueSlickCarousel>
      <VueSlickCarousel ref="c2"
                        :as-nav-for="c1"
                        :draggable="false"
                        :focus-on-select="true"
                        :slides-to-show="5">
        <div class="tt-product-modal__carousel"
             v-for="image in product.images">
          <ImageLazy :img-src="image.img" />
        </div>
      </VueSlickCarousel>
    </div>

    <div class="tt-product-modal__info">
      <div>
        <div class="tt-product-modal__title ff-mont">
          {{ product.name }}
        </div>
        <div class="tt-product-modal__description"
             v-html="product.description"></div>
        <div v-if="product.info">
          <div class="tt-product-modal__ingredients-title">
            {{ $t('main_vpopup_composition_title') }}
          </div>
          <div class="tt-product-modal__ingredients"
               v-html="product.info"></div>
        </div>
        <div v-if="product.combo">
          <div v-for="comboProduct in chosenProducts" :key="comboProduct.id" class="edit" @click="setProductsByModifier(comboProduct.pivot.group_modifier_id)">
            <img class="image" :src="comboProduct.image" />
            <div class="info">
              <p class="name">{{  comboProduct.name }}</p>
              <div class="slot-controls">
                <button class="select-button">Заменить</button>
              </div>
            </div>
          </div>
          <!-- <p v-for="(select, i) in selectedComboProducts" :key="i">
          {{ select }}</p> -->
        </div>
      </div>

      <div>
        <div class="tt-product-modal__price-row">
          <div class="tt-product-modal__price-title">
            {{ $t('main_vpopup_sum_title') }}
          </div>
          <div class="tt-product-modal__price" v-if="product.combo">
            {{ getTotalComboPrice }}
            <span v-if="product.measure_unit">/ {{ product.measure_unit }}</span>
          </div>
          <div class="tt-product-modal__price" v-else>
            {{ numberWithSpaces(product.price) }} ₸
            <span v-if="product.measure_unit">/ {{ product.measure_unit }}</span>
          </div>
        </div>

        <button class="tt-button tt-button--outlined"
                v-if="showRequestModalButton"
                @click="openRequestModal">
          {{ $t(buttonName) }}
        </button>
        <button class="tt-button tt-button--outlined"
                v-else
                :disabled="isButtonDisabled"
                @click="addToCart">
          {{ $t(buttonName) }}
        </button>

        <button class="tt-button tt-button--primary"
                v-if="!isButtonDisabled && !showRequestModalButton"
                @click="createOrderImmediately">
          {{ $t('main_vpopup_btn_buy2direct') }}
        </button>
        <RequestModal v-if="showRequestOrderModal" />
      </div>
    </div>
  </div>
</template>

<script>
import ImageLazy from "@/components/shared/ImageLazy.vue";
import {cartMixin} from "@/mixins/cartMixin";
import RequestModal from "@/components/modal/RequestModal.vue";
import {mapState} from "vuex";

export default {
  props: ['product'],
  data: () => ({
    c1: null,
    c2: null,
    count: 1,
    city: JSON.parse(localStorage.getItem('city')) || {},
    modifierProducts: [],
    selectedComboProducts: [],
  }),
  mixins: [cartMixin],
  computed: {
    ...mapState({
      showRequestOrderModal: state => state.modalRequestOrder.showRequestOrderModal,
      defaultComboProductsInit: state => state.modalProduct.defaultComboProducts,
    }),
    isButtonDisabled() {
      return this.product.is_onlyview;
    },
    chosenProducts() {
      if (this.product.combo) {
        return this.product.combo.filter(product => this.selectedComboProducts.indexOf(product.id) >= 0)
      }
      return [];
    },
    getTotalComboPrice () {
      const chosenProducts = this.product.combo.filter(product => this.selectedComboProducts.indexOf(product.id) >= 0);
      let sum = 0;
      for (let i = 0; i < chosenProducts.length; i++) {
        sum+=chosenProducts[i].price;
      }
      return sum;
    },
    buttonName() {
      switch (true) {
        case !!this.product.is_onlyview:
          return 'home_list_btn_only_view_product';
        case !!this.product.is_special:
          return 'home_list_btn_special_product';
        case !!this.product.is_request:
          return 'home_list_btn_request_product';
        case !!this.product.next_day:
          return 'home_list_btn_next_day_product';
        default:
          return 'home_list_btn_add2cart';
      }
    },
    showRequestModalButton() {
      return (this.city.name === 'Астана' && this.product.is_request) || this.city.name !== 'Астана';
    }
  },
  methods: {
    getPriceDiff(price, modifierId) {
      const defaultProduct = this.product.combo.filter((product) => product.pivot.group_modifier_id===modifierId && product.pivot.is_default === 1)
      console.log(defaultProduct);
      return price - defaultProduct[0].price;
    },
    setProductsByModifier(id) {
      this.modifierProducts = this.product.combo.filter((product) => product.pivot.group_modifier_id===id)
    },
    changeSelectedComboProducts (id, modifierId) {
      //this.selectedComboProducts[modifierId] = {id: id}
      this.$set(this.selectedComboProducts, modifierId, id)
    },
    addToCart() {
      if (!this.canAddProductToCart(this.product)) {
        return;
      }
      this.$store.dispatch("cart/addProduct", this.product)
          .then(response => {
            this.$toast.open({
              position: 'top-right',
              type: 'success',
              message: 'Ваш продукт ' + this.product.name + ' добавлен в корзину!',
              duration: 3000,
            });
          });
      this.$store.dispatch("modalProduct/setModalName", null)
    },
    createOrderImmediately() {
      if (!this.canAddProductToCart(this.product)) {
        return;
      }
      this.$store.dispatch("cart/addProduct", { product: this.product, combos: this.selectedComboProducts });
      this.$store.dispatch("modalProduct/setModalName", null);
      if (this.$router.currentRoute.path !== '/create-order') {
        this.$router.push('/create-order');
      }
    },
    openRequestModal() {
      this.$store.dispatch("modalRequestOrder/setModalProduct", this.product);
      this.$store.dispatch('modalRequestOrder/setShowRequestOrderModal', true);
      const productId = this.product.id;
      const selectedComboProducts = this.selectedComboProducts
      this.$store.commit('modalRequestOrder/SET_COMBO_PRODUCTS', {
        productId: selectedComboProducts
      });
    },
  },
  mounted() {
    this.c1 = this.$refs.c1;
    this.c2 = this.$refs.c2;
    // this.selectedComboProducts = this.defaultComboProducts
    for (let i = 0; i < this.defaultComboProductsInit.length; i++) {
      this.$set(this.selectedComboProducts, this.defaultComboProductsInit[i].pivot.group_modifier_id, this.defaultComboProductsInit[i].id)
      //this.selectedComboProducts[this.defaultComboProductsInit[i].pivot.group_modifier_id] = this.defaultComboProductsInit[i].id
    }
  },
  components: {
    ImageLazy,
    RequestModal
  }
}
</script>

<style lang="sass" scoped>
@import "src/assets/styles-new/typography"
@import "src/assets/styles-new/colors"

.tt-product-modal
  position: relative
  display: flex
  gap: 40px
  &__images
    width: 377px
  &__selected-image
    height: 461px
  &__carousel
    cursor: pointer
    margin-top: 20px
    height: 76px
    min-width: 66px
    max-width: 66px
  &__info
    width: 452px
    display: flex
    flex-direction: column
    justify-content: space-between
  &__title
    font-size: $fs-larger
    font-weight: $fw-semibold
  &__description
    margin-top: 18px
    font-size: $fs-small
  &__ingredients-title
    margin-top: 16px
    font-weight: $fw-semibold
  &__ingredients::v-deep
    margin-top: 8px
    font-size: $fs-small
    span
      color: $dark-gray
  &__price-row
    display: flex
    justify-content: space-between
  &__price-title
    font-size: $fs-medium
    font-weight: $fw-semibold
  &__price
    font-size: $fs-semilarge
    font-weight: $fw-semibold
  .tt-button
    height: 56px
    margin-top: 20px
  .tt-product-modal__modifier
    width: 377px;

.edit
  width: 100%;
  min-height: 94px;
  position: relative;
  margin-bottom: 12px;
  box-shadow: rgba(6, 5, 50, 0.1) 0px 2px 4px;
  padding: 12px;
  background: rgb(255, 255, 255);
  border-radius: 16px;
  transition: opacity 0.15s ease-in-out 0s, box-shadow, border-color;
  border-width: 1px;
  border-style: solid;
  border-color: rgb(255, 255, 255);
  border-image: initial;
.image
  width: 68px;
  height: 68px;
  position: absolute;
  top: 12px;
  left: 12px;
.info
  margin-left: 76px;
.name
  font-size: 16px;
  line-height: 20px;
  color: rgb(0, 0, 0);
  margin-bottom: 4px;
  padding-right: 38px;
  font-weight: 600;
.slot-controls
  display: flex;
  margin-top: 8px;
  margin-bottom: 0px;
  -webkit-box-align: center;
  align-items: center;
  transition: opacity 0.15s ease-in-out 0s, margin-bottom;
.select-button
  display: block;
  width: 100px;
  margin-right: 16px;
  font-weight: 600;
  transition: opacity 0.15s ease-in-out 0s, margin-right;
  outline: none;
  border: none;
  border-radius: 9999px;
  text-align: center;
  font-weight: 500;
  text-decoration: none;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  user-select: none;
.modifiers
  flex: 0 0 auto;
  width: 156px;
  margin: 8px;
  padding-bottom: 12px;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: rgb(0, 0, 0);
  position: relative;
  cursor: pointer;
  border-radius: 16px;
  border: 1px solid rgb(255, 255, 255);
  transition: border-color 150ms ease-out 0s;
.modifier-image
  padding: 8px;
  margin: 3px -3px -3px 3px;
  width: 156px;
  height: 156px;
  transition: padding 150ms ease-out 0s;
.modifier-name
  margin-top: 2px;
  padding: 0px 8px;
.modifier-diff
  color: rgb(92, 99, 112);
  margin-top: 2px;
  font-size: 14px;
.modifier-diff-button
  height: 20px;
  margin-top: 4px;
  padding: 3px 10px;
  font-size: 14px;
  line-height: 16px;
  outline: none;
  border: none;
  border-radius: 9999px;
  text-align: center;
  text-decoration: none;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  user-select: none;
  transition-property: background-color, color;
  transition-duration: 200ms;
  transition-timing-function: ease-out;
.tt-product-modal__modifier
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
  padding: 20px 0px 20px 20px;
</style>