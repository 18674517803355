<template>
  <div class="tt-gift-info">
    <div>
      <h2 class="tt-gift-info__title ff-mont">
        {{ $t('gift-certificate__header') }}
      </h2>
      <p class="tt-gift-info__subtitle"
         v-html="$t('gift-certificate__rules')">
      </p>
    </div>

    <div class="tt-gift-info__image">
      <img src="@/assets/images-new/gift-certificate.png">
    </div>
  </div>
</template>

<script>
export default {
}
</script>

<style lang="sass" scoped>
@import "src/assets/styles-new/typography"
@import "src/assets/styles-new/vars"

.tt-gift-info
  display: grid
  grid-template-columns: 1fr 544px
  gap: 100px
  margin-bottom: 100px
  &__title
    font-size: $fs-larger
    font-weight: $fw-semibold
    margin-bottom: 16px
  &__subtitle
    font-size: $fs-small
    margin-bottom: 40px
  &__image
    overflow: hidden
    border-radius: 8px
    img
      height: 100%
      width: auto

@media screen and (max-width: $tablet-width)
  .tt-gift-info
    grid-template-columns: 1fr
    gap: 40px
    margin-bottom: 40px
    &__title
      font-size: $fs-large
    &__subtitle
      margin-bottom: 24px
    &__image
      grid-row-start: 1
      height: 302px

@media screen and (max-width: $mobile-width)
  .tt-gift-info
    gap: 32px
    &__title
      font-size: $fs-semilarge
    &__image
      grid-row-start: 2
      height: 207px
</style>