import axios from "axios";

const RED_MARKER = 'red';
const GREEN_MARKER = 'green';
const YELLOW_MARKER = 'yellow';

const state = {
    stopList: {},
    canDelivery: true,
    canPickup: false,
    categories: [],
    filials: [],
    categoryNames: [],
    selectedCategoryNames: [],
    organizations: [],
    selectedOrganizations: [],
    selectedFilials: [],
    update_interval: null,
    red_zone: null,
    yellow_zone: null,
    loading: false,
};

const getters = {
    filialIsOutOfStock: (state, getters) => filialId => {
        return [RED_MARKER, YELLOW_MARKER].indexOf(getters.filialMarker(filialId)) !== -1;
    },
    canPickupProduct:(state)=>{
        return state.canPickup
    },
    filialMarker: (state, getters, rootState, rootGetters) => filialId => {
        try {
            let filialStopList;
            if (rootState.cart.isExpressDelivery) {
                filialStopList = state.stopList["express_delivery"][filialId];
            } else {
                filialStopList = state.stopList[rootGetters["cart/getSelectedDate"]][rootGetters["cart/getOrderType"]][rootGetters["cart/getSelectedTime"]][filialId];
            }
            const filialStopListArray = Object.keys(filialStopList).map(item => filialStopList[item]);
            const filialStopListLength = filialStopListArray.length;
            const productCount = rootState.cart.products.length;
            const isOutOfStock = filialStopListArray.every(productClass => {return productClass === RED_MARKER});
            if ((filialStopListLength === productCount) && isOutOfStock) {
                return RED_MARKER;
            }
            const someIsOutOfStock = filialStopListArray.some(productClass => {return productClass === RED_MARKER});
            if (someIsOutOfStock) {
                return YELLOW_MARKER;
            }
        }catch (e){}

        return GREEN_MARKER
    },
    productFilialMarker: (state, getters, rootState) => (stopList, filialId) => {
        try {
            const filialStopList = stopList.find(filial => filial.id === filialId);
            if (!filialStopList) return GREEN_MARKER;

            const filialStopListArray = Object.keys(filialStopList).map(item => filialStopList[item]);
            const filialStopListLength = filialStopListArray.length;
            const productCount = rootState.cart.products.length;
            const isOutOfStock = filialStopListArray.every(productClass => { return productClass === RED_MARKER; });

            if ((filialStopListLength === productCount) && isOutOfStock) {
                return RED_MARKER;
            }
            const someIsOutOfStock = filialStopListArray.some(productClass => { return productClass === RED_MARKER; });
            if (someIsOutOfStock) {
                return YELLOW_MARKER;
            }
        } catch (e) {
            console.error(e);
        }

        return GREEN_MARKER;
    },
    productIsOutOfStock: (state, getters, rootState, rootGetters) => productId =>{
        try {
            if (rootState.cart.isExpressDelivery) {
                return state.stopList["express_delivery"][rootState.filial.activeFilial.id][productId] === RED_MARKER;
            } else {
                return state.stopList[rootGetters["cart/getSelectedDate"]][rootGetters["cart/getOrderType"]][rootGetters["cart/getSelectedTime"]][rootState.filial.activeFilial.id][productId] === RED_MARKER;
            }
        }catch (e) {
        }
        return false;
    },
    categories(state) {
        const categories = JSON.parse(JSON.stringify(state.categories))
        return categories.filter(category => state.selectedCategoryNames.includes(category.name));
    },
    filials(state) {
        let filials = [];
        state.selectedOrganizations.map(organization => {
            filials.push.apply(filials, state.organizations[organization])
        })
        state.filials = filials;

        return filials;
    },
    categoryNames(state) {
        return state.categoryNames;
    },
    selectedCategoryNames(state) {
        return state.selectedCategoryNames;
    },
    selectedFilials(state) {
        const intersection = state.selectedFilials.filter(element => state.filials.includes(element));

        return intersection;
    },
};

const mutations = {
    SET_STOP_LIST(state, stopList) {
        state.stopList = stopList;
    },
    SET_CAN_DELIVERY(state, canDelivery) {
        state.canDelivery = canDelivery;
    },
    SET_CAN_PICKUP(state, canPickup) {
        state.canPickup = canPickup;
    },
    SET_PRODUCTS_STOP_LIST(state, products) {
        state.update_interval = products.update_interval * 1000;
        state.red_zone = products.red_zone;
        state.yellow_zone = products.yellow_zone;
        state.categories = products.categories;
        state.filials = products.filials;
        state.selectedFilials = JSON.parse(JSON.stringify(products.filials));
        state.categoryNames = products.category_names;
        state.selectedCategoryNames = JSON.parse(JSON.stringify(products.category_names));
        state.organizations = products.organizations;
        state.selectedOrganizations = Object.keys(state.organizations);
    },
    SET_SELECTED_FILIAL(state, filial) {
        const index = state.selectedFilials.indexOf(filial);
        if (index > -1) {
            state.selectedFilials.splice(index, 1);
        } else {
            state.selectedFilials.push(filial)
        }
    },
    SET_SELECTED_CATEGORY_NAME(state, categoryName) {
        const index = state.selectedCategoryNames.indexOf(categoryName);
        if (index > -1) {
            state.selectedCategoryNames.splice(index, 1);
        } else {
            state.selectedCategoryNames.push(categoryName)
        }
    },
    SET_SELECTED_ORGANIZATION(state, organization) {
        const index = state.selectedOrganizations.indexOf(organization);
        if (index > -1) {
            state.selectedOrganizations.splice(index, 1);
        } else {
            state.selectedOrganizations.push(organization)
        }
    },
    SET_LOADING(state, value) {
        state.loading = value;
    },
};

const actions = {
    getCartStopList(context) {
        if (!context.rootState.cart.cartId || !context.rootState.cart.products.length){
            return;
        }
        const city = JSON.parse(localStorage.getItem('city'));
        if (city) {
            context.commit('SET_LOADING', true);
            axios.get(`cart/${context.rootState.cart.cartId}/stop_list`, {
                params: {
                    city_id: city.id
                }
            })
                .then(response => {
                    context.commit('SET_STOP_LIST', response.data.stop_list);
                    context.commit('SET_CAN_DELIVERY', response.data.can_delivery);
                    context.commit('SET_CAN_PICKUP', response.data.can_pickup);
                    context.commit('cart/SET_PERIODS', response.data, {root: true});
                    if (!response.data.can_delivery && response.data.can_pickup) {
                        context.commit('cart/SET_IS_DELIVERY', response.data.can_delivery, {root: true})
                    }
                }).finally(() => context.commit('SET_LOADING', false))
        }
    },
    getProductsStopList(context) {
        return axios
            .get('product/stop_list/list')
            .then(response => {
                context.commit('SET_PRODUCTS_STOP_LIST', response.data)
            });
    },
};

// Экспортируем наружу
export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
