<template>
  <div class="tt-user-detail">
    <div class="tt-user-detail__label">
      {{ $t(i18nKey) }}
    </div>
    <div class="tt-user-detail__value">
      {{ value}}
    </div>
  </div>
</template>

<script>
export default {
  props: ['i18nKey', 'value']
}
</script>

<style lang="sass" scoped>
@import "src/assets/styles-new/typography"
@import "src/assets/styles-new/colors"
@import "src/assets/styles-new/vars"

.tt-user-detail
  display: flex
  flex-direction: column
  gap: 8px
  &__label
    font-size: $fs-small
    color: $dark-gray
  &__value
    font-size: $fs-medium
    font-weight: $fw-medium

@media screen and (max-width: $tablet-width)
  .tt-user-detail
    gap: 6px

@media screen and (max-width: $mobile-width)
  .tt-user-detail
    gap: 0
    &__value
      font-size: 1rem
</style>