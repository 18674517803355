<template>
  <div class="tt-vacancies">
    <div class="tt-vacancies__flex">
      <div class="tt-vacancies__title ff-mont">
        {{ $t('about_vacancies') }}
      </div>
      <div class="tt-vacancies__subtitle">
        {{ $t('about_component_vacancy_description_01') }}
      </div>
    </div>

    <Vacancies />

    <ContactUsForm :for-vacancy="true" />
  </div>
</template>


<script>

import Vacancies from "@/components/Carrier/Vacancies.vue";
import ContactUsForm from "@/components/shared/ContactUsForm.vue";

export default {
  components: {
    ContactUsForm,
    Vacancies
  }
}
</script>

<style lang="sass" scoped>
@import "src/assets/styles-new/typography"
@import "src/assets/styles-new/vars"

.tt-vacancies
  padding: 40px 0 100px
  &__flex
    display: flex
    justify-content: space-between
    margin-bottom: 48px
    gap: 24px
  &__title
    font-size: $fs-larger
    line-height: 1.2em
    font-weight: $fw-semibold
  &__subtitle
    font-size: $fs-small
    width: 100%
    max-width: 544px

@media screen and (max-width: $tablet-width)
  .tt-vacancies
    padding: 40px 0
    &__flex
      flex-direction: column
    &__title
      font-size: $fs-large

@media screen and (max-width: $mobile-width)
  .tt-vacancies
    padding-top: 16px
    &__flex
      margin-bottom: 32px
    &__title
      font-size: $fs-semilarge
</style>