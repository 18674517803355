<template>
  <div class="tt-recommendation-product">
    <div class="tt-recommendation-product__image">
      <ImageLazy :img-src="product.image"
                 :alt-text="product.name" />
    </div>
    <div class="tt-recommendation-product__info">
      <span class="tt-recommendation-product__name">
        {{ product.name }}
      </span>
      <span class="tt-recommendation-product__price">
        {{ numberWithSpaces(product.price) }} ₸
      </span>
    </div>
    <button class="tt-button tt-button--outlined"
            @click="addToCart">
      {{ $t("home_list_btn_add2cart") }}
    </button>
  </div>
</template>

<script>

import ImageLazy from "@/components/shared/ImageLazy.vue";
import defaultImg from "@/assets/images-new/default-lazy-load.jpg";
import {cartMixin} from "@/mixins/cartMixin";

export default {
  props: ["product"],
  mixins: [cartMixin],
  computed: {
    imgSrcLazy() {
      return {
        src: this.product.image,
        loading: defaultImg,
        error: defaultImg
      }
    }
  },
  methods: {
    addToCart() {
      if (!this.canAddProductToCart(this.product)) {
        return;
      }
      this.$store.dispatch("cart/addProduct", this.product)
          .then(response => {
            this.$toast.open({
              position: 'top-right',
              type: 'success',
              message: 'Ваш продукт ' + this.product.name + ' добавлен в корзину!',
              duration: 3000,
            });
          });
    }
  },
  components: {
    ImageLazy
  }
}
</script>

<style lang="sass" scoped>
@import "src/assets/styles-new/typography"

.tt-recommendation-product
  font-size: $fs-small
  line-height: 1rem
  max-width: 148px
  &__info
    margin-top: 12px
    display: flex
    justify-content: space-between
    height: 34px
  &__price
    font-weight: $fw-semibold
    white-space: nowrap
  &__image
    width: 148px
    height: 112px
  .tt-button
    margin-top: 12px
    height: 38px
    font-size: 14px
    padding: 0
</style>