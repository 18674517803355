<template>
  <footer class="tt-footer">
    <div class="container">
      <div class="tt-footer__links">
        <div class="tt-icon tt-footer__logo">
          <img src="@/assets/icons-new/tt-logo.svg">
        </div>

        <NavRoutes />

        <div class="tt-footer__contacts">
          <div class="tt-footer__tel">
            <a class="ff-mont"
               href="tel: +77715556060">
              +7 (771) 555 60 60
            </a>
          </div>
          <SocialLinks />
        </div>
      </div>

      <div class="tt-footer__law">
        <div class="tt-footer__rights">
          <router-link class="tt-footer__user-agreement"
                       to="/publicy_offer">
            {{ $t('footer_user-agreement') }}
          </router-link>
          <router-link class="tt-footer__policy"
                       to="/policy">
            {{ $t('Policy_views_breadcrumbs_index') }}
          </router-link>
        </div>

        <div class="tt-footer__copyright">{{ $t('Footer_copyright') }}</div>
      </div>
    </div>
  </footer>
</template>

<script>
import NavRoutes from "@/components/Footer/NavFooterRoutes.vue";
import SocialLinks from "@/components/Footer/SocialLinks.vue";

export default {
  components: {
    SocialLinks,
    NavRoutes
  }
}
</script>

<style src="../assets/styles-new/footer.sass" lang="sass" scoped>
</style>