<template>
  <form class="tt-code-validator">
    <div class="tt-code-validator__code">
      <CodeValidator :user="user"
                     :loading="loading"
                     @onComplete="updateCode" />

      <div class="tt-code-validator__error"
            v-for="(errorMessage, errorKey) in  validationErrors.code"
            :key="errorKey">
        {{ $t(errorMessage) }}
      </div>
    </div>

    <input type="submit"
           class="tt-button tt-button--primary"
           :value="$t('navbar_login_resetPassword_confirmation_submit')"
           @click.prevent="loginByCode" />
  </form>
</template>

<script>
import { required, minLength } from "vuelidate/lib/validators";
import CodeValidator from "./CodeValidator.vue";

export default {
  props: ["user"],
  data: () => ({
    validationErrors: {},
    payload: {
      code: null,
    },
    loading: false
  }),
  validations: {
    payload: {
      code: {
        required,
        minLength: minLength(6),
      },
    },
  },
  methods: {
    updateCode(code) {
      this.payload.code = code;
      if (this.payload.code.length === 6) {
        this.loginByCode();
      }
    },
    loginByCode() {
      if (this.$v.payload.$invalid) {
        this.$v.payload.$touch();
        return;
      }
      this.validationErrors = {};
      this.loading = true;
      this.$store
        .dispatch("auth/loginByCode", {
          phone: this.user.phone.replace(/\D+/g, ""),
          code: this.payload.code,
        })
        .then(() => {
          this.$eventBus.$emit('successfullyLoggedIn');
        })
        .catch((error) => {
          if (error.response && error.response.status === 422) {
            this.validationErrors = error.response.data.errors;
          }
        }).finally(() => this.loading = false);
    },
  },
  components: {
    CodeValidator,
  }
};
</script>

<style lang="sass" scoped>
@import "src/assets/styles-new/colors"

.tt-code-validator
  &__code
    position: relative
    margin-bottom: 24px
  &__error
    margin-top: 8px
    font-size: 12px
    line-height: 14px
    color: $error
</style>
