<template>
  <div class="tt-hero">
    <div v-if="!currentCity" class="tt-hero__text">
      <button class="tt-button tt-button--primary" @click="openCitySelectModal">
        Выберите город
      </button>
    </div>
    <div
      v-else-if="loading"
      class="tt-hero__spinner-wrapper"
    >
      <Spinner />
    </div>
    <div class="tt-hero__carousel"
         v-else-if="slides.length">
      <VueSlickCarousel ref="tt-hero-slider"
                        lazy-load="progressive"
                        :arrows="false"
                        :dots="true">
        <div class="tt-hero__slide"
             v-for="slide in slides"
             :key="slide.id">
          <img :src="imgSrc(slide)"
               :alt="slide.caption">
          <a class="tt-hero__link"
             v-if="slide.link_url && slide.link_text"
             :href="slide.link_url"
             target="_blank">
            {{ slide.link_text }}
          </a>
          <div class="tt-hero__prev-button"
               @click="$refs['tt-hero-slider'].prev()"></div>
          <div class="tt-hero__next-button"
               @click="$refs['tt-hero-slider'].next()"></div>
        </div>
      </VueSlickCarousel>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Spinner from "@/components/shared/Spinner.vue";

export default {
  data() {
    return {
      currentCity: JSON.parse(localStorage.getItem('city')) || null,
    }
  },
  computed: {
    ...mapState({
      loading: state => state.carousel.loading,
      slides: state => state.carousel.carouselItems,
    }),
  },
  methods: {
    imgSrc(slide) {
      switch (true) {
        case slide.mobile_image && window.innerWidth < 768:
          return slide.mobile_image;
        case slide.tablet_image && window.innerWidth < 992:
          return slide.tablet_image;
        default:
          return slide.image;
      }
    },
    openCitySelectModal() {
      this.$store.dispatch('cities/setShowModal', true);
    }
  },
  mounted() {
    this.$store.dispatch('carousel/getCarouselItems');
  },
  components: {
    Spinner
  }
}
</script>

<style lang="sass" scoped>
@import "src/assets/styles-new/colors"
@import "src/assets/styles-new/vars"

.tt-hero
  position: relative
  &__spinner-wrapper, &__slide
    position: relative
    height: 448px
  &__text
    text-align: center
    font-size: 22px
    font-weight: 600
    padding: 60px 0
    .tt-button
      width: auto
      padding: 0 20px
  &__carousel
    margin-bottom: 60px
  &__slide
    display: flex
    align-items: center
    justify-content: center
    border-radius: 8px
    overflow: hidden
    img
      height: 100%
      width: 100%
      object-fit: cover
  &__link
    background-color: rgba(255, 255, 255, 0.8)
    position: absolute
    z-index: 1
    bottom: 32px
    right: 32px
    padding: 8px 16px
    border-radius: 8px
    font-weight: 500
    color: $main
    border: 1px solid $main
    &:hover
      color: $eastern-blue
      border: 1px solid $eastern-blue
    &:active
      transform: scale(0.95)
  &__next-button, &__prev-button
    position: absolute
    top: 0
    width: 30%
    height: 100%
    cursor: pointer
  &__next-button
    right: 0
  &__prev-button
    left: 0
.tt-hero::v-deep
  .slick-dots
    position: absolute
    bottom: 16px
    button:before
      color: $white
      opacity: 0.4
      scale: 1.5
    .slick-active button:before
      opacity: 1


@media screen and (max-width: $tablet-width)
  .tt-hero
    &__spinner-wrapper, &__slide
      height: 308px
    &__carousel
      margin-bottom: 40px


@media screen and (max-width: $mobile-width)
  .tt-hero
    &__spinner-wrapper, &__slide
      height: 160px
    &__carousel
      margin-bottom: 32px
</style>
