<template>
  <div class="tt-about-us">
    <We />
    <Certificates />

    <div class="tt-about-us__contact-us">
      <ContactUsForm :for-partners="true" />
    </div>

    <AboutUsFaq />
  </div>
</template>

<script>
import We from '@/components/AboutUs/We.vue'
import Certificates from "@/components/AboutUs/Certificates.vue";
import ContactUsForm from "@/components/shared/ContactUsForm.vue";
import AboutUsFaq from "@/components/AboutUs/AboutUsFaq.vue";
export default {
  components: {
    AboutUsFaq,
    ContactUsForm,
    Certificates,
    We
  },
 metaInfo() {
   return {
     title: 'TapTatti – Кондитерская сеть | Доставка кондитерских изделий в Астане (Нур-султан)',
     meta: [
       {
         vmid: 'description',
         property: 'description',
         content: 'О компании TapTatti – Кондитерская сеть'
       },
       {
         vmid: 'og:title',
         property: 'og:title',
         content: 'TapTatti Delivery'
       },
       {
         vmid: 'og:description',
         property: 'og:description',
         content: 'О компании TapTatti – Кондитерская сеть'
       }
     ]
   }
 }
};
</script>

<style lang="sass" scoped>
@import "src/assets/styles-new/typography"
@import "src/assets/styles-new/vars"

.tt-about-us
  padding: 40px 0 100px
  &__contact-us
    padding-top: 60px
    padding-bottom: 70px

@media screen and (max-width: $tablet-width)
  .tt-about-us
    padding: 40px 0
    &__contact-us
      padding: 40px 0

@media screen and (max-width: $mobile-width)
  .tt-about-us
    padding-top: 16px
    &__contact-us
      padding: 32px 0
</style>
