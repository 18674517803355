<template>
  <div class="tt-order-summary">
    <div class="tt-order-summary__title ff-mont">
      {{ $t('OrderPage_cmpt_SideCheck_widget_title') }}
    </div>

    <div class="tt-order-summary__list">
      <OrderDetail i18n-key="OrderPage_cmpt_SideCheck_count_product"
                   :value="productsQuantity" />
      <OrderDetail i18n-key="OrderPage_cmpt_SideCheck_sum"
                   :value="`${numberWithSpaces(amount)} ₸`" />

      <OrderDetail i18n-key="OrderPage_cmpt_SideCheck_delivery_title"
                   :value="`${numberWithSpaces(deliveryPrice)} ₸`"
                   v-if="isDelivery" />
      <OrderDetail i18n-key="OrderPage_cmpt_SideCheck_delivery_time"
                   :value="deliveryTime"
                   v-if="isDelivery && !isExpressDelivery" />
      <OrderDetail i18n-key="OrderPage_cmpt_SideCheck_delivery_date"
                   :value="deliveryDate"
                   v-if="isDelivery && !isExpressDelivery" />

      <OrderDetail i18n-key="OrderPage_cmpt_SideCheck_samovivoz_magazin"
                   :value="activeFilial.name || $t('not_selected')"
                   v-if="!isDelivery" />
      <OrderDetail i18n-key="OrderPage_cmpt_SideCheck_samovivoz_time"
                   :value="pickupTime"
                   v-if="!isDelivery" />
      <OrderDetail i18n-key="OrderPage_cmpt_SideCheck_samovivoz_date"
                   :value="pickupDate"
                   v-if="!isDelivery" />

      <OrderDetail i18n-key="OrderPage_cmpt_SideCheck_delivery_time"
                   :value="expressDeliveryTime"
                   v-if="isDelivery && isExpressDelivery" />
      <OrderDetail i18n-key="OrderPage_cmpt_SideCheck_delivery_date"
                   :value="expressDeliveryDate"
                   v-if="isDelivery  && isExpressDelivery" />

      <OrderDetail i18n-key="OrderPage_cmpt_SideCheck_all_sum"
                   :value="`${numberWithSpaces(totalAmount)} ₸`" />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import OrderDetail from "@/components/CreateOrder/OrderDetail.vue";

export default {
  computed: {
    ...mapState({
      isDelivery: state => state.cart.isDelivery,
      isExpressDelivery: state => state.cart.isExpressDelivery,
      productsQuantity: state => state.cart.productsQuantity,
      amount: state => state.cart.amount,
      deliveryPrice: state => state.cart.deliveryPrice,
      deliveryTime: state => state.cart.selectedDeliveryTime,
      deliveryDate: state => state.cart.selectedDeliveryDate,
      activeFilial: state => state.filial.activeFilial,
      pickupTime: state => state.cart.selectedPickupTime,
      pickupDate: state => state.cart.selectedPickupDate,
      expressDeliveryTime: state => state.cart.selectedExpressDeliveryTime,
      expressDeliveryDate: state => state.cart.selectedExpressDeliveryDate
    }),
    totalAmount() {
      return this.isDelivery ? this.amount + this.deliveryPrice : this.amount;
    }
  },
  components: {
    OrderDetail
  }
}
</script>

<style lang="sass" scoped>
@import "src/assets/styles-new/colors"
@import "src/assets/styles-new/typography"
@import "src/assets/styles-new/vars"

.tt-order-summary
  padding: 32px 0
  &__title
    font-size: $fs-semilarge
    font-weight: $fw-semibold
    margin-bottom: 32px
  &__list
    display: flex
    gap: 60px
    row-gap: 24px
    flex-wrap: wrap

@media screen and (max-width: $tablet-width)
  .tt-order-summary
    padding: 24px 0
    &__title
      margin-bottom: 24px
    &__list
      display: grid
      grid-template-columns: 1fr 1fr 1fr

@media screen and (max-width: $mobile-width)
  .tt-order-summary
    &__title
      margin-bottom: 16px
    &__list
      display: flex
      flex-direction: column
      gap: 16px
</style>
