<template>
  <div class="tt-modal-container">
    <div class="tt-modal-container__modal">
      <div class="tt-modal-container__header">
        <h2 class="tt-modal-container__title ff-mont">
          {{ $t('city_modal_title') }}
        </h2>
        <div class="tt-icon-button" @click="close">
          <img src="@/assets/icons-new/close_xl.svg">
        </div>
      </div>
      <div class="tt-modal_city__body">
        <div class="tt-modal_city__search">
          <input class="tt-modal_city__search_input" v-model="searchText">
        </div>
        <div class="tt-modal_city__grid">
          <div v-for="chunk in cityChunks" :key="chunk[0].name" class="tt-modal_city__info">
            <div v-for="city in chunk" :key="city.name" class="tt-modal_city__name" @click="selectCity(city)">
              {{ $t(city.name) }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="tt-modal-container__backdrop" @click="close"></div>
  </div>
</template>

<script>

export default {
  data() {
    return {
      searchText: ""
    };
  },
  computed: {
    cities() {
      return this.$store.state.cities.cities;
    },
    filteredCities() {
      if (!this.searchText.trim()) return this.cities;
      return this.cities.filter(city => city.name.toLowerCase().includes(this.searchText.toLowerCase()));
    },
    cityChunks() {
      return this.chunkArray(this.filteredCities, 3);
    }
  },
  async created() {
    if (!this.cities.length) {
      await this.$store.dispatch("cities/getCities");
    }
  },
  methods: {
    close() {
      this.setShowModal(false);
    },
    selectCity(city) {
      this.$store.dispatch("cities/setActiveCity", city);
      this.setShowModal(false);
      location.reload();
    },
    chunkArray(array, chunkSize) {
      return Array(Math.ceil(array.length / chunkSize)).fill().map((_, index) => index * chunkSize).map(begin => array.slice(begin, begin + chunkSize));
    },
    setShowModal(value) {
      this.$store.dispatch("cities/setShowModal", value);
    }
  }
};
</script>

<style lang="sass" scoped>
@import "src/assets/styles-new/colors"
@import "src/assets/styles-new/typography"
@import "src/assets/styles-new/vars"

.tt-modal-container
  position: fixed
  z-index: $zi-fixed
  top: 0
  left: 0
  right: 0
  bottom: 0
  display: flex
  align-items: center
  justify-content: center
  &__modal
    position: relative
    z-index: $zi-modal
    background-color: $white
    border-radius: 8px
    overflow: hidden
    padding: 32px
  &__header
    display: flex
    justify-content: space-between
    align-items: center
    margin-bottom: 24px
  &__title
    font-size: $fs-semilarge
    font-weight: $fw-semibold
    margin: 0
    color: var(--Black, #1D1D1D)
    text-align: center
    font-style: normal
    line-height: normal
  .tt-icon-button
    position: absolute
    top: 20px
    right: 20px
  &__backdrop
    position: absolute
    z-index: $zi-modal-backdrop
    width: 100%
    height: 100%
    background-color: rgba(0, 0, 0, 0.4)

.tt-modal_city
  &__search_input
    display: inline-flex
    padding: 8px 232px 8px 8px
    align-items: center
    border-radius: 8px
    border: 1px solid var(--Gray-1, #989898)
  &__grid
    display: grid
    grid-template-columns: 1fr 1fr
    column-gap: 128px
    row-gap: 32px
    padding-top: 44px
  &__info
    display: inline-flex
    flex-direction: column
    align-items: flex-start
    gap: 24px
  &__name
    width: 160px
    color: #000
    font-size: 14px
    font-style: normal
    font-weight: $fw-semibold
    line-height: 16px
    &:hover
      cursor: pointer
      color: $main


@media screen and (max-width: $mobile-width)
  .tt-modal_city
    &__search_input
      padding: 8px 100px 8px 8px
    &__grid
      column-gap: 64px

</style>