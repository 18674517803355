<template>
  <form class="tt-sign-up"
        :class="{ 'tt-sign-up--grid' : isCreateOrderPage }">
      <div class="tt-input-field">
        <label class="tt-input-field__label"
               for="sign-up-name-input">
          {{ $t('navbar_signup_register_name') }}
        </label>
        <input type="text"
               id="sign-up-name-input"
               class="tt-input-field__input"
               :class="{ 'tt-input-field__input--invalid': isFormFieldInvalid('name') }"
               :placeholder="$t('navbar_signup_register_placeholder')"
               v-model="user.name">
        <div class="tt-input-field__error"
             v-for="(errorMessage, errorKey) in validationErrors.name"
             :key="`name-${errorKey}`">
          {{ errorMessage }}
        </div>
      </div>
      <div class="tt-input-field">
        <label class="tt-input-field__label"
               for="sign-up-phone-input">
          {{ $t('navbar_signup_register_phoneInput') }}
        </label>
        <input type="tel"
               id="sign-up-phone-input"
               class="tt-input-field__input"
               :class="{ 'tt-input-field__input--invalid': validationErrors.phone || isFormFieldInvalid('phone') }"
               v-mask="'+7 (###) ###-##-##'"
               placeholder="+7 (___) ___ __ __"
               v-model="user.phone">
        <div class="tt-input-field__error"
               v-for="(errorMessage, errorKey) in validationErrors.phone"
               :key="`phone-${errorKey}`">
          {{ $t(errorMessage) }}
        </div>
      </div>
      <input type="submit"
             class="tt-button tt-button--outlined"
             v-if="isCreateOrderPage"
             :value="$t('navbar_signup_check')"
             @click.prevent="signUp">
      <input type="submit"
             class="tt-button tt-button--primary"
             v-else
             :value="$t('navbar_signup_signUp')"
             @click.prevent="signUp">
  </form>
</template>

<script>
import {required} from "vuelidate/lib/validators";
import {phoneValidator} from "../../mixins/validators";

export default {
  props: {
    isCreateOrderPage: false
  },
  data: () => ({
    user: {
      phone: null,
      name: undefined,
      city: JSON.parse(localStorage.getItem('city')) || null,
    },
    validationErrors: {}
  }),
  validations() {
    return {
      user: {
        phone: {
          required,
          phoneValidator,
        },
        name: {
          required,
        },
      },
    }
  },
  methods: {
    isFormFieldInvalid(prop) {
      return this.$v.user[prop].$dirty && this.$v.user[prop].$invalid;
    },
    signUp() {
      if (this.$v.user.$invalid || !this.user.city) {
        this.$v.user.$touch();

        if (!this.user.city) {
          this.$toast.open({
            position: 'top-right',
            type: 'error',
            message: this.$t('navbar_auth_error_city_required'),
            duration: 3000,
          });
        }

        return;
      }

      this.$store.dispatch("auth/getActivationCode", {
        phone: this.user.phone.replace(/\D+/g, ""),
        name: this.user.name,
        city_id: this.user.city.id || 1,
        is_registration: true,
      })
        .then(() => {
          this.$emit("submit", this.user);
        })
        .catch((error) => {
          if (error.response && error.response.status === 422) {
            this.validationErrors = error.response.data.errors;
          }
        });
    },
  },
};
</script>

<style lang="sass" scoped>
@import "src/assets/styles-new/vars"

.tt-sign-up
  .tt-input-field
    margin-bottom: 16px
  &--grid
    display: grid
    grid-template-columns: 200px 200px 300px
    align-items: end
    gap: 20px
    .tt-button
      margin-bottom: 16px

@media screen and (max-width: $tablet-width)
  .tt-sign-up
    &--grid
      grid-template-columns: 200px 200px
      .tt-button
        grid-column-start: 1
        grid-column-end: 3

@media screen and (max-width: $mobile-width)
  .tt-sign-up
    &--grid
      display: flex
      flex-direction: column
      align-items: flex-start
      gap: 0
      .tt-button
        margin-top: 12px
      .tt-input-field
        width: 100%
</style>
