import axios from "axios";

const state = {
    filials: [],
    activeFilial: {},
    showFilialModal: false
};

const getters = {

};

const mutations = {
    SET_FILIALS(state, filials) {
        state.filials = filials;
    },
    SET_ACTIVE_FILIAL(state, filial) {
        state.activeFilial = filial;
    },
    SET_ACTIVE_FILIAL_BY_ID(state, filialId) {
        const filials = state.filials;
        state.activeFilial = filials.filter(filial => {
            return filial.id === filialId;
        })[0];
    },
    SET_SHOW_FILIAL_MODAL(state, name) {
        state.showFilialModal = name
    },
};

const actions = {
    getFilials(context) {
        const city = JSON.parse(localStorage.getItem('city'));
        if (city) {
            axios.get("filial", {
                params: {
                    city_id: city.id
                }
            })
                .then(response => {
                    context.commit('SET_FILIALS', response.data.filials);
                    context.commit('cart/SET_MAX_POST_PAYMENT_AMOUNT', response.data.max_post_payment_amount, {root: true});
                    context.commit('cart/SET_YA_DELIVERY_START_HOUR', response.data.ya_delivery_start_hour, {root: true});
                    context.commit('cart/SET_YA_DELIVERY_STOP_HOUR', response.data.ya_delivery_stop_hour, {root: true});
                    if (response.data.min_amount_for_delivery > 0) {
                        context.commit('cart/SET_MIN_AMOUNT_FOR_DELIVERY', response.data.min_amount_for_delivery, {root: true});
                    }
                });
        }
    },
    setShowFilialModal({ commit }, name) {
        commit('SET_SHOW_FILIAL_MODAL', name)
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}