<template>
<div class="tt-we">
  <div class="tt-we__flex">
    <div>
      <div class="tt-we__title ff-mont">
        {{ $t('About_us_We_title') }}
      </div>
      <div class="tt-we__subtitle">
        {{ $t('About_us_We_subtitle') }}
      </div>
    </div>

    <div class="tt-we__image">
      <img src="@/assets/images-new/shop.png" alt="shop">
    </div>
  </div>

  <div class="tt-we__grid">
    <div class="tt-we__info"
         v-for="(info, index) in infos"
         :key="index">
      <div class="tt-icon">
        <img :src="info.icon" alt="icon">
      </div>
      <div class="tt-we__info-title ff-mont">
        {{ $t(info.title) }}
      </div>
      <div class="tt-we__info-subtitle">
        {{ $t(info.subtitle) }}
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  data: () => ({
    infos: [
      {
        title: 'About_us_We_info_1_title',
        subtitle: 'About_us_We_info_1_subtitle',
        icon: require('@/assets/icons-new/we_1.svg')
      },
      {
        title: 'About_us_We_info_2_title',
        subtitle: 'About_us_We_info_2_subtitle',
        icon: require('@/assets/icons-new/we_2.svg')
      },
      {
        title: 'About_us_We_info_3_title',
        subtitle: 'About_us_We_info_3_subtitle',
        icon: require('@/assets/icons-new/we_3.svg')
      },
      {
        title: 'About_us_We_info_4_title',
        subtitle: 'About_us_We_info_4_subtitle',
        icon: require('@/assets/icons-new/we_4.svg')
      },
      {
        title: 'About_us_We_info_5_title',
        subtitle: 'About_us_We_info_5_subtitle',
        icon: require('@/assets/icons-new/we_5.svg')
      },
      {
        title: 'About_us_We_info_6_title',
        subtitle: 'About_us_We_info_6_subtitle',
        icon: require('@/assets/icons-new/we_6.svg')
      }
    ]
  })
}
</script>

<style lang="sass" scoped>
@import "src/assets/styles-new/typography"
@import "src/assets/styles-new/vars"
@import "src/assets/styles-new/colors"

.tt-we
  padding-bottom: 140px
  border-bottom: 2px solid $gray
  &__flex
    display: flex
    align-items: center
    justify-content: space-between
    gap: 54px
    margin-bottom: 140px
  &__image
    min-width: 522px
    height: 342px
    overflow: hidden
    border-radius: 8px
    img
      height: 100%
      width: 100%
      object-fit: cover !important
  &__title
    font-size: $fs-larger
    line-height: 1.2em
    font-weight: $fw-semibold
    margin-bottom: 16px
  &__grid
    display: grid
    grid-template-columns: repeat(3, 1fr)
    align-items: start
    gap: 80px
  &__info .tt-icon
    height: 64px
    width: 64px
    margin-bottom: 24px
  &__info-title
    font-size: 1.25rem
    line-height: 1.25em
    font-weight: $fw-semibold
    margin-bottom: 12px
  &__info-subtitle
    font-size: 1rem
    line-height: 1.25em

@media screen and (max-width: $tablet-width)
  .tt-we
    padding-bottom: 64px
    &__flex
      flex-direction: column
      gap: 32px
      margin-bottom: 80px
    &__image
      min-width: 100%
    &__title
      font-size: $fs-large
    &__grid
      grid-template-columns: repeat(2, 1fr)
      column-gap: 70px
      row-gap: 40px
    &__info .tt-icon
      margin-bottom: 18px


@media screen and (max-width: $mobile-width)
  .tt-we
    padding-bottom: 40px
    &__flex
      gap: 24px
      margin-bottom: 32px
    &__image
      height: 226px
    &__title
      font-size: $fs-semilarge
    &__subtitle
      font-size: $fs-small
    &__grid
      grid-template-columns: 1fr
      gap: 24px
</style>
