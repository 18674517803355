<template>
  <div class="tt-for-friend">
    <div class="tt-input-field">
      <label class="tt-input-field__label">
        {{ $t('OrderPage_cmpt_ProductForFriend_input_title_01') }}
      </label>
      <input type="text"
             class="tt-input-field__input"
             :class="{ 'tt-input-field__input--invalid' : $v.name.$error}"
             :placeholder="$t('OrderPage_cmpt_ProductForFriend_input_name')"
             v-model="name">
      <div class="tt-input-field__error"
           v-for="(errorMessage, errorKey) in $store.state.order.validationErrors['friend.name']"
           :key="errorKey">
        {{ errorMessage }}
      </div>
    </div>

    <div class="tt-input-field">
      <label class="tt-input-field__label">
        {{ $t('OrderPage_cmpt_ProductForFriend_input_title_02') }}
      </label>
      <input type="tel"
             class="tt-input-field__input"
             :class="{ 'tt-input-field__input--invalid' : $v.phone.$error}"
             v-mask="'+7 (###) ###-##-##'"
             placeholder="+7 (___) ___ __ __"
             v-model="phone">
      <div class="tt-input-field__error"
           v-for="(errorMessage, errorKey) in $store.state.order.validationErrors['friend.phone']"
           :key="errorKey">
        {{ errorMessage }}
      </div>
    </div>
  </div>
</template>

<script>
import { required, minLength } from 'vuelidate/lib/validators'
import {phoneValidator} from "@/mixins/validators";

export default {
  validations: {
    name: {
      required,
      minLength: minLength(2)
    },
    phone: {
      required,
      phoneValidator
    }
  },
  computed: {
    name: {
      get() {
        return this.$store.state.order.friend.name;
      },
      set(name) {
        this.$store.commit('order/SET_FRIEND_NAME', name)
        this.$v.name.$touch()
      }
    },
    phone: {
      get() {
        return this.$store.state.order.friend.phone;
      },
      set(phone) {
        this.$store.commit('order/SET_FRIEND_PHONE', phone)
        this.$v.phone.$touch()
      }
    }
  }
};
</script>

<style lang="sass" scoped>
@import "src/assets/styles-new/vars"

.tt-for-friend
  margin-top: 32px
  display: grid
  grid-template-columns: repeat(2, 256px)
  gap: 32px

@media screen and (max-width: $tablet-width)
  .tt-for-friend
    margin-top: 24px

@media screen and (max-width: $mobile-width)
  .tt-for-friend
    display: flex
    flex-direction: column
    gap: 16px
</style>
