<template>
  <header class="tt-header">
    <div class="tt-icon tt-header__logo"
         @click="navigateHome">
      <img src="@/assets/icons-new/tt-logo.svg" alt="logo">
    </div>

    <div class="d-none d-lg-block">
      <NavHeaderRoutes/>
    </div>

    <div class="tt-header__items">
      <div class="tt-icon tt-header__halal-logo d-none d-lg-block">
        <img src="@/assets/icons-new/halal.png" alt="halal">
      </div>

      <div class="d-none d-lg-block">
        <LanguageSelector/>
      </div>

      <div class="d-none d-lg-block">
        <CitySelector/>
      </div>

      <div class="tt-icon-button d-none d-md-block"
           v-if="shouldShowPage()"
           :class="{ 'tt-icon-button--blue': isAccountIconBlue }"
           @click="toggleAuthModal">
        <img src="@/assets/icons-new/account.svg" alt="account">
      </div>

      <div class="tt-icon-button tt-header__cart d-none d-lg-block"
           v-if="shouldShowPage()"
           @click="toggleBasketModal">
        <img src="@/assets/icons-new/cart.svg" alt="cart">
        <span class="tt-header__cart-badge"
              v-if="isCartBadgeVisible">
          {{ productsQuantity }}
        </span>
      </div>

      <div class="tt-icon-button tt-header__cart d-lg-none"
           v-if="shouldShowPage()"
           @click="createOrder">
        <img src="@/assets/icons-new/cart.svg" alt="cart">
        <span class="tt-header__cart-badge"
              v-if="isCartBadgeVisible">
          {{ productsQuantity }}
        </span>
      </div>

      <div class="d-lg-none">
        <BurgerButton :active="mobileMenuOpened"
                      @clicked="toggleMobileMenu"/>

        <transition name="slide-right">
          <MobileNav v-if="mobileMenuOpened"
                     @navLinkClicked="toggleMobileMenu"/>
        </transition>
      </div>
    </div>

    <transition name="fade-away">
      <div
        v-if="authModalOpened && shouldShowPage()"
        class="tt-auth-modal"
      >
        <Profile v-if="loggedIn"
                 @close="toggleAuthModal"/>
        <Auth v-else/>
        <div class="tt-icon-button tt-auth-modal__close-icon"
             @click="toggleAuthModal">
          <img src="@/assets/icons-new/close_xl.svg" alt="close">
        </div>
        <div class="tt-auth-modal__wrapper"
             @click="toggleAuthModal"></div>
      </div>
    </transition>

    <transition name="fade-away">
      <div class="tt-basket-side__backdrop"
           v-if="basketModalOpened && shouldShowPage()"
           @click="toggleBasketModal"></div>
    </transition>
    <transition name="slide-left">
      <div class="tt-basket-side__modal"
           v-if="basketModalOpened && shouldShowPage()">
        <Basket @closeButtonClicked="toggleBasketModal"/>
      </div>
    </transition>

    <transition name="fade-away">
      <div class="tt-basket-side__backdrop"
           v-if="basketModalOpened"
           @click="toggleBasketModal"></div>
    </transition>
    <transition name="slide-left">
      <div class="tt-basket-side__modal"
           v-if="basketModalOpened">
        <Basket @closeButtonClicked="toggleBasketModal"/>
      </div>
    </transition>

    <transition name="fade-away">
      <div class="tt-basket-side__backdrop"
           v-if="basketModalOpened"
           @click="toggleBasketModal"></div>
    </transition>
    <transition name="slide-left">
      <div class="tt-basket-side__modal"
           v-if="basketModalOpened">
        <Basket @closeButtonClicked="toggleBasketModal"/>
      </div>
    </transition>
  </header>
</template>

<script>
import {mapGetters, mapState} from "vuex";
import NavHeaderRoutes from "@/components/Header/NavHeaderRoutes.vue";
import LanguageSelector from "@/components/Header/LanguageSelector.vue";
import CitySelector from "@/components/Header/CitySelector.vue";
import BurgerButton from "@/components/Header/BurgerButton.vue";
import MobileNav from "@/components/Header/MobileNav.vue";
import Profile from "@/components/auth/Profile.vue";
import Auth from "@/components/auth/Auth.vue";
import Basket from "@/components/basket/Basket.vue";

export default {
  data: () => ({
    authModalOpened: false,
    mobileMenuOpened: false,
    basketModalOpened: false
  }),
  computed: {
    ...mapState({
      productsQuantity: state => state.cart.productsQuantity
    }),
    ...mapGetters({
      loggedIn: 'auth/loggedIn'
    }),
    isAccountIconBlue() {
      return this.authModalOpened || this.$route.path === '/cabinet';
    },
    isCartBadgeVisible() {
      return this.productsQuantity > 0;
    },
    isMobile() {
      return window.innerWidth <= 768;
    }
  },
  methods: {
    toggleAuthModal() {
      this.authModalOpened = !this.authModalOpened;
    },
    toggleMobileMenu() {
      this.mobileMenuOpened = !this.mobileMenuOpened;
    },
    toggleBasketModal() {
      this.basketModalOpened = !this.basketModalOpened;
    },
    createOrder() {
      if (this.$route.path !== '/create-order') {
        this.$router.push('/create-order');
      }
    },
    navigateHome() {
      if (this.$route.path !== '/') {
        this.$router.push('/');
      }
    },
    shouldShowPage() {
      try {
        const city = JSON.parse(localStorage.getItem('city'));
        return city && city.name === 'Астана';
      } catch (e) {
        console.error('Error reading from localStorage', e);
        return false;
      }
    }
  },
  created() {
    this.$eventBus.$on(['successfullyLoggedIn', 'login'], this.toggleAuthModal);
  },
  beforeDestroy() {
    this.$eventBus.$off(['successfullyLoggedIn', 'login'], this.toggleAuthModal);
  },
  components: {
    Basket,
    Auth,
    Profile,
    MobileNav,
    BurgerButton,
    CitySelector,
    LanguageSelector,
    NavHeaderRoutes
  }
};
</script>

<style src="../assets/styles-new/header.sass" lang="sass" scoped>
</style>