<template>
  <div class="tt-create-order">
    <div class="tt-create-order__title ff-mont">
      {{ $t('CreateOrder_payment') }}
    </div>

    <div v-if="canOrder">
      <OrderedProducts class="tt-create-order__ordered-products" :products="products"/>

      <DeliveryType @scroll-to-cart-block="scrollToId('#cart_block')" />

      <ContactInformation @isForFriendToggled="updatePayment" />

      <Payment :key="paymentKey" />

      <OrderSummary />

      <button class="tt-button tt-button--primary"
              :disabled="creatingOrder"
              @click="createOrder">
        {{ $t('OrderPage_cmpt_SideCheck_btn2order') }}
      </button>

      <div class="tt-create-order__policy">
        {{ $t('OrderPage_cmpt_SideCheck_text_policy_01') }}
        <router-link to="/policy"
                     target="_blank">
          {{ $t('OrderPage_cmpt_SideCheck_text_policy_02') }}
        </router-link>
      </div>
    </div>

    <div class="tt-create-order__empty-cart"
         v-else>
      <div class="tt-icon">
        <img src="@/assets/icons-new/cart_xl.svg" alt/>
      </div>
      <div class="tt-create-order__empty-cart__title">
        {{ $t('OrderBasket_views_validation_cart_empty') }}
      </div>
      <div class="tt-create-order__empty-cart__subtitle">
        {{ $t('OrderBasket_views_choose_product_from_menu') }}
      </div>
      <router-link to="/">
        <button class="tt-button tt-button--primary">
          {{ $t('OrderBasket_views_btn2menu') }}
        </button>
      </router-link>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import dayjs from "dayjs";
import {cartMixin} from "@/mixins/cartMixin";
import OrderedProducts from "@/components/CreateOrder/OrderedProducts.vue";
import DeliveryType from "@/components/CreateOrder/DeliveryType.vue";
import ContactInformation from "@/components/CreateOrder/ContactInformation.vue";
import Payment from "@/components/CreateOrder/Payment.vue";
import OrderSummary from "@/components/CreateOrder/OrderSummary.vue";
const customParseFormat = require('dayjs/plugin/customParseFormat')
const isToday = require('dayjs/plugin/isToday')
const isTomorrow = require('dayjs/plugin/isTomorrow')
dayjs.extend(customParseFormat)
dayjs.extend(isToday)
dayjs.extend(isTomorrow)


export default {
  data: () => ({
    validationErrors: [],
    isOutOfStock: false,
    createOrderError: null,
    creatingOrder: false,
    paymentKey: 0,
  }),
  mixins: [cartMixin],
  computed: {
    ...mapState({
      products: state => state.cart.products,
      productsQuantity: state => state.cart.productsQuantity,
      canDelivery: state => state.stopList.canDelivery,
      canPickup: state => state.stopList.canPickup,
      minAmountForDelivery: state => state.cart.minAmountForDelivery,
      loading: state => state.cart.loading
    }),
    canOrder() {
      return this.productsQuantity && (this.canPickup || this.canDelivery);
    },
    checkDeliveryMinAmount() {
      return this.amount > this.minAmountForDelivery;
    },
    amount() {
      return this.cartProducts.reduce((acc, item) => {
        return acc + item.price * item.quantity
      }, 0)
    }
  },
  methods: {
    updatePayment() {
      this.paymentKey++;
    },
    scrollToId(id) {
      const el = this.$el.querySelector(id);
      el.scrollIntoView({
        behavior: 'smooth',
        block: 'center'
      })
    },
    createOrder() {
      if (!this.checkCartForNonAdditionalProduct()) {
        this.$toast.open({
          position: 'top-right',
          type: 'error',
          message: this.$t('no_purchase_without_cake'),
          duration: 3000,
        });
        return;
      }
      if (this.creatingOrder) {
        return;
      }
      this.isOutOfStock = false;
      if (this.$store.getters["stopList/filialIsOutOfStock"](this.$store.state.filial.activeFilial.id)) {
        this.isOutOfStock = true;
        this.scrollToId('#cart_block');
        return;
      }
      this.validationErrors = {};
      this.createOrderError = null;
      this.creatingOrder = true;

      this.$store.commit('cart/SET_LOADING', true);
      this.$store.dispatch('order/createOrder')
          .then(response => {
            if (response.data.order_id !== undefined) {
              this.$store.commit('cart/RESET_STATE');
              this.$store.commit('order/RESET_STATE');
              this.$store.commit('paymentType/RESET_STATE');
              this.$store.commit('stopList/SET_STOP_LIST', {});
              this.$router.push({name: 'ConfirmationOrder', params: { orderId: response.data.order_id } });
            }
            this.$store.commit('cart/SET_LOADING', false);
          })
          .catch(error => {
            this.$store.commit('cart/SET_LOADING', false);
            if (error.response && error.response.status === 401) {
              this.scrollToId('#contact_info_block');
                this.$toast.open({
                    position: 'top-right',
                    type: 'error',
                    message: this.$t('OrderBasket_views_validation_contact_information'),
                    duration: 3000,
                });
              return;
            }
            if (error.response && error.response.status === 422) {
              this.validationErrors = error.response.data.errors;
              this.$store.commit('order/SET_VALIDATION_ERRORS', this.validationErrors);
              if (this.validationErrors.shop_id !== undefined) {
                this.scrollToId('#delivery_type_selector');
                  this.$toast.open({
                      position: 'top-right',
                      type: 'error',
                      message: this.$t('OrderBasket_views_delivery_validation_choose_address'),
                      duration: 3000,
                  });
                return;
              }

              if (this.validationErrors.next_day !== undefined) {
                this.scrollToId('#cart_block');
                  this.$toast.open({
                      position: 'top-right',
                      type: 'error',
                      message: this.$t('OrderBasket_views_delivery_validation_next_day'),
                      duration: 3000,
                  });
                return;
              }

              if (this.validationErrors.selectedDate !== undefined || this.validationErrors.selectedTime !== undefined) {
                this.scrollToId('#delivery_type_selector');
                this.$toast.open({
                  position: 'top-right',
                  type: 'error',
                  message: 'Выберите время и дату',
                  duration: 3000,
                });
                return;
              }

              if (this.validationErrors.payment_id !== undefined) {
                this.scrollToId('#payment_selector');
                return;
              }
              if (this.validationErrors.cart_id !== undefined) {
                alert("Обновите страницу");
                location.reload();
              }
            }
            //TooSmallDeliveryDate
            if (error.response && error.response.data && error.response.data.orderInfo && error.response.data.orderInfo.errorInfo) {
              this.createOrderError = error.response.data.orderInfo.errorInfo;
            }

            const deliveryErrors = ['TooSmallDeliveryDate', 'AddressNotFound'];
            if (this.createOrderError && this.createOrderError.code && deliveryErrors.includes(this.createOrderError.code)) {
                this.scrollToId('#delivery_type_selector');
            }
          })
        .finally(() => {
          this.creatingOrder = false;
        })
    }
  },
  mounted() {
    this.$store.dispatch('stopList/getCartStopList');
    this.$store.dispatch('filial/getFilials');
    if(!this.$store.state.cart.allProductsPickupOnly){
      this.$store.commit('cart/SET_IS_DELIVERY', this.checkDeliveryMinAmount);
    }
    this.$store.commit('order/SET_FOR_FRIEND', false);
    this.$store.dispatch('products/getProducts');
  },
  updated() {
    if (this.$store.state.stopList.canPickup === false && !this.$store.state.cart.allProductsPickupOnly && this.amount > this.$store.state.cart.minAmountForDelivery) {
      this.$store.commit('cart/SET_IS_DELIVERY', true);
    }
  },
  metaInfo() {
    return {
      title: this.$t('OrderBasket_views_meta_title'),
      meta: [
        {
          vmid: 'description',
          property: 'description',
          name: 'description',
          content: this.$t('OrderBasket_views_meta_description'),
        },
        {
          vmid: 'og:title',
          property: 'og:title',
          name: 'og:title',
          content: this.$t('OrderBasket_views_meta_og_title'),
        },
        {
          vmid: 'og:description',
          property: 'og:description',
          name: 'og:description',
          content: this.$t('OrderBasket_views_meta_og_description'),
        },
      ],
    }
  },
  components: {
    OrderSummary,
    Payment,
    ContactInformation,
    DeliveryType,
    OrderedProducts
  }
};
</script>

<style src="../assets/styles-new/create-order.sass" lang="sass" scoped>
</style>