<template>
  <div class="tt-dropdown">
    <div class="tt-dropdown__container ff-mont">
      <slot name="label"></slot>
      <div class="tt-icon-button"
           :class="{ 'tt-icon-button--reversed' : isDropped }"
           @click="toggleDropdown">
        <img src="@/assets/icons-new/chevron_down.svg">
      </div>
    </div>

    <transition name="slide-down">
      <div class="tt-dropdown__content"
           v-if="isDropped">
        <slot name="content"></slot>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  data: () => ({
    isDropped: false
  }),
  methods: {
    toggleDropdown() {
      this.isDropped = !this.isDropped;
    }
  }
}
</script>

<style lang="sass" scoped>
@import "src/assets/styles-new/typography"
@import "src/assets/styles-new/colors"

.tt-dropdown
  width: 100%
  margin-bottom: 16px
  &__container
    display: flex
    align-items: center
    justify-content: space-between
    font-weight: $fw-semibold
    font-size: $fs-medium
    border-bottom: 1px solid $gray
    padding-bottom: 12px
  &__content
    margin-top: 24px
    margin-bottom: 48px
  .tt-icon-button
    height: 24px
    &:hover
      filter: invert(59%) sepia(86%) saturate(419%) hue-rotate(135deg) brightness(89%) contrast(82%)
    &--reversed
      transform: rotate(180deg)
</style>