import { render, staticRenderFns } from "./RecommendationProduct.vue?vue&type=template&id=2f73df59&scoped=true"
import script from "./RecommendationProduct.vue?vue&type=script&lang=js"
export * from "./RecommendationProduct.vue?vue&type=script&lang=js"
import style0 from "./RecommendationProduct.vue?vue&type=style&index=0&id=2f73df59&prod&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2f73df59",
  null
  
)

export default component.exports