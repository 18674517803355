<template>
  <div class="tt-confirm-order">
    <div class="tt-confirm-order__title ff-mont">
      <h2 v-if="hasError">{{ $t('ConfirmationOrder_status_tech_error') }}</h2>
      <h2 v-else-if="!order.id">{{ $t('ConfirmationOrder_status_load_order') }}</h2>
      <h2 v-else-if="order.status === 'Cancelled'">{{ $t('ConfirmationOrder_status_cancelled') }}</h2>
      <h2 v-else-if="order.creation_status === 'Success'">{{ $t('ConfirmationOrder_status_success') }}</h2>
      <h2 v-else-if="order.status === 'New' && !order.creation_status && !order.payed_at && order.payment_url_expired">{{ $t('ConfirmationOrder_has_not_been_paid_and_payment_url_expired') }}</h2>
      <h2 v-else-if="order.status === 'New' && !order.creation_status && !order.payed_at">{{ $t('ConfirmationOrder_has_not_been_paid') }}</h2>
      <h2 v-else>{{ $t('ConfirmationOrder_status_loading') }}</h2>
    </div>

    <div class="tt-confirm-order__inform">
      <p v-if="hasError"
         v-html="$t('ConfirmationOrder_views_hasError')"></p>
      <p v-else-if="order.creation_status === 'InProgress'"
         v-html="$t('ConfirmationOrder_views_creation_status_InProgress_01').replace('${order.id}', order.id)"></p>
    </div>

    <div class="tt-confirm-order__kaspi"
         v-if="order.payment !== undefined && ['kspi', 'kpay'].includes(order.payment.type) && !order.payed_at">
      <div class="tt-confirm-order__kaspi-info">
        <h5>{{ $t('OrderPage_cmpt_Payment_how_to_pay2title') }}</h5>
        <div>{{ $t('ConfirmationOrder_kaspi_how_to_pay_1') }}</div>
        <div v-html="$t('ConfirmationOrder_kaspi_how_to_pay_2').replace('${order.id}', order.id)"></div>
      </div>

      <form action="https://kaspi.kz/online"
            method="post" >
        <input type="hidden" name="TranId" :value="order.payment_unique_number" />
        <input type="hidden" name="OrderId" :value="order.id" />
        <input type="hidden" name="Amount" :value="order.amount * 100" />
        <input type="hidden" name="Service" value="TapTattiOnline" />
        <input type="hidden" name="returnUrl" :value="getReturnUrl()" />
        <input type="hidden" name="Signature" value="" />
        <button type="submit"
                class="tt-button tt-button--primary">
          Оплатить через kaspi
        </button>
      </form>
    </div>

    <OrderInfo />

    <div class="tt-confirm-order__buttons">
      <router-link to="/">
        <button class="tt-button tt-button--primary">
          {{ $t('ConfirmationOrder_btn2back_main_page') }}
        </button>
      </router-link>
      <a v-if="order.payment_url && !order.payed_at && !order.payment_url_expired"
         :href="order.payment_url">
        <button class="tt-button tt-button--outlined">
          {{ $t('paybox_payment_button') }}
        </button>
      </a>
      <router-link v-else-if="currentUser"
                   to="/cabinet">
        <button class="tt-button tt-button--outlined">
          {{ $t('ConfirmationOrder_btn2cabinet') }}
        </button>
      </router-link>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import OrderInfo from "@/components/ConfirmOrder/OrderInfo.vue";

export default {
  computed: {
    ...mapState({
      hasError: state => state.order.hasError,
      order: state => state.order.order,
    })
  },
  methods: {
    getReturnUrl() {
      return window.location.origin + '/order/' + this.order.id;
    }
  },
  created() {
    this.$store.commit('order/SET_ORDER', {});
    this.$store.dispatch('order/getOrder', this.$route.params.orderId);
  },
  components: {
    OrderInfo,
  },
  metaInfo() {
    return {
      title: this.$t('ConfirmationOrder_title_meta'),
      meta: [
        { vmid: 'description', property: 'description', content: this.$t('ConfirmationOrder_description_meta') },
        { vmid: 'og:title', property: 'og:title', content: this.$t('ConfirmationOrder_og_title_meta') },
        { vmid: 'og:description', property: 'og:description', content: this.$t('ConfirmationOrder_og_description_meta') },
      ],
    }
  }
};
</script>

<style src="../assets/styles-new/confirm-order.sass" lang="sass" scoped>
</style>
