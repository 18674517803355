<template>
  <div class="tt-gift">
    <GiftInfo />

    <GiftFAQ />
  </div>
</template>

<script>
import GiftInfo from "@/components/GiftCertificate/GiftInfo.vue";
import GiftFAQ from "@/components/GiftCertificate/GiftFAQ.vue";

export default {
  components: {GiftFAQ, GiftInfo}
}
</script>

<style lang="sass" scoped>
@import "src/assets/styles-new/typography"
@import "src/assets/styles-new/vars"

.tt-gift
  padding: 40px 0 100px

@media screen and (max-width: $tablet-width)
  .tt-gift
    padding: 40px 0

@media screen and (max-width: $mobile-width)
  .tt-gift
    padding-top: 16px
</style>