<template>
  <div class="tt-count-control">
    <button class="tt-count-control__control"
          @click="$emit('minus')">
     <i class="fas fa-minus"></i>
    </button>
    <span class="tt-count-control__count ff-mont">
      {{ count }}
    </span>
    <button class="tt-count-control__control"
          @click="$emit('plus')">
      <i class="fas fa-plus"></i>
    </button>
  </div>
</template>

<script>
export default {
  props: ['count']
}
</script>

<style lang="sass" scoped>
@import "src/assets/styles-new/colors"
@import "src/assets/styles-new/typography"

.tt-count-control
  width: 100px
  height: 40px
  display: flex
  border: 1px solid $dark-gray
  border-radius: 8px
  justify-content: space-around
  align-items: center
  font-size: 1rem
  font-weight: $fw-semibold
  &__control
    border: none
    outline: none
    background: none
    color: $dark-gray
    font-size: $fs-small
    &:hover
      color: $main
    &:active
      transform: scale(0.95)
</style>