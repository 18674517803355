import Vue from 'vue'
import Vuex from 'vuex'
import products from "@/store/modules/products";
import cart from "@/store/modules/cart";
import modalProduct from "@/store/modules/modalProduct";
import carousel from "@/store/modules/carousel";
import auth from "@/store/modules/auth";
import review from "@/store/modules/review";
import filial from "@/store/modules/filial";
import stopList from "@/store/modules/stopList";
import paymentType from "@/store/modules/paymentType";
import order from "@/store/modules/order";
import productOfTheDay from "@/store/modules/productOfTheDay";
import contacts from "@/store/modules/contacts";
import cities from "@/store/modules/cities";
import modalFilial from "@/store/modules/modalFilial";
import modalRequestOrder from "@/store/modules/modalRequestOrder";

Vue.use(Vuex)


export default new Vuex.Store({
    modules: {
        products,
        cart,
        modalProduct,
        carousel,
        auth,
        review,
        filial,
        stopList,
        paymentType,
        order,
        productOfTheDay,
        contacts,
        cities,
        modalFilial,
        modalRequestOrder
    },
})
