import axios from "axios";

const state = {
    cities: [],
    activeCity: null,
    isLoading: true,
    showModal: false,
    isCityOne: false,
};

const mutations = {
    SET_CITIES(state, cities) {
        state.cities = cities;
    },
    SET_ACTIVE_CITY(state, city) {
        state.activeCity = city;
    },
    SET_ACTIVE_CITY_BY_ID(state, cityId) {
        const city = state.cities.find(c => c.id === cityId);
        if (city) {
            state.activeCity = city;
        }
    },
    SET_LOADING_STATE(state, loading) {
        state.isLoading = loading;
    },
    SET_SHOW_MODAL(state, name) {
        state.showModal = name
    },
    SET_ACTIVE_CITY_ONE(context, city) {
        state.activeCity = city;
        localStorage.setItem('city', JSON.stringify(city));
        state.isCityOne = true
    },
};

const getters = {
    activeCity() {
        return state.activeCity;
    },
};

const actions = {
    setShowModal({ commit }, name) {
        commit('SET_SHOW_MODAL', name)
    },
    async getCities({commit,state}) {
       commit('SET_LOADING_STATE', true);
        try {
            const response = await axios.get("cities");
           commit('SET_CITIES', response.data);

            if (response.data.length <= 1 && !state.activeCity) {
                commit('SET_ACTIVE_CITY_ONE',response.data[0])
                setTimeout(()=>{
                    location.reload()
                },100)
            }
        } catch (error) {
            console.error("An error occurred while fetching cities:", error);
        } finally {
            context.commit('SET_LOADING_STATE', false);
        }
    },
    setActiveCity(context, city) {
        context.commit('SET_ACTIVE_CITY', city);
        localStorage.setItem('city', JSON.stringify(city));
    },
    loadCity(context) {
        const savedCity = localStorage.getItem('city');
        if (savedCity) {
            try {
                const parsedCity = JSON.parse(savedCity);
                if (parsedCity && parsedCity.name && parsedCity.url) {
                    context.commit('SET_ACTIVE_CITY', parsedCity);
                }
            } catch (error) {
                console.error("Error parsing city from localStorage:", error);
            }
        } else {
            context.commit('SET_SHOW_MODAL', true);
        }
    }
};

export default {
    namespaced: true,
    state,
    mutations,
    actions
}