<template>
  <div class="tt-categories ff-mont">
    <div v-for="(category, index) in products"
         :key="index">
      <div class="tt-categories__tab"
           :class="{ 'tt-categories__tab--active' : isActiveCategory(index) }"
           @click="setActiveCategory(index)">
        {{ category.name }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    products: Array,
    activeCategory: Number
  },
  methods: {
    isActiveCategory(index) {
      return this.activeCategory === index;
    },
    setActiveCategory(index) {
      this.$emit('activeCategoryChanged', index);
    }
  }
}
</script>

<style lang="sass" scoped>
@import "src/assets/styles-new/colors"
@import "src/assets/styles-new/typography"
@import "src/assets/styles-new/vars"

.tt-categories
  position: sticky
  top: 80px
  height: 80px
  z-index: $zi-sticky
  background-color: $white
  font-weight: $fw-semibold
  justify-content: space-between
  display: flex
  align-items: center
  gap: 40px
  overflow-x: scroll
  &__tab
    text-decoration: none
    color: $dark-gray
    cursor: pointer
    white-space: nowrap
    &:hover
      color: $main
    &--active
      color: $main
      border-bottom: 2px solid $main
  &::-webkit-scrollbar
    -webkit-appearance: none
    -moz-appearance: none
    appearance: none
    height: 4px
  &::-webkit-scrollbar-thumb
    background-color: $gray
    border-radius: 2px

@media screen and (max-width: $tablet-width)
  .tt-categories
    top: 68px
    height: 68px
</style>