<template>
  <div class="tt-product-info">
    <div class="tt-product-info__images">
      <VueSlickCarousel ref="c1"
                        :as-nav-for="c2"
                        :draggable="false"
                        :focus-on-select="true"
                        :arrows="false">
        <div class="tt-product-info__selected-image"
             v-for="image in product.images">
          <ImageLazy  :alt-text="product.name" :img-src="image.img" />
        </div>
      </VueSlickCarousel>
      <VueSlickCarousel ref="c2"
                        v-bind="carouselSettings"
                        :as-nav-for="c1">
        <div class="tt-product-info__carousel"
             v-for="image in product.images">
          <ImageLazy :alt-text="product.name"  :img-src="image.img" />
        </div>
      </VueSlickCarousel>
    </div>

    <div class="tt-product-info__info">
      <div>
        <div class="tt-product-info__title ff-mont">
          {{ product.name }}
        </div>

        <div
          v-if="multipleProducts && multipleProducts.length"
          class="tt-product-info__sizes"
        >
          <div
            v-for="item in multipleProducts"
            :key="item.id"
            :class="{'active': item.id === product.id}"
            class="tt-product-info__sizes-tab"
            @click="onSelectSize(item.related_product_id)"
          >
            {{ item.weight }}
          </div>
        </div>

        <div class="tt-product-info__description"
             v-html="product.description"></div>
        <div v-if="product.info">
          <div class="tt-product-info__ingredients-title">
            {{ $t('main_vpopup_composition_title') }}
          </div>
          <div class="tt-product-info__ingredients"
               v-html="product.info"></div>
        </div>
      </div>

      <div class="tt-product-info__footer">
        <div class="tt-product-info__price-row">
          <div class="tt-product-info__price-title">
            {{ $t('main_vpopup_sum_title') }}
          </div>
          <div class="tt-product-info__price">
            {{ numberWithSpaces(product.price) }} ₸
            <span v-if="product.measure_unit">/ {{ product.measure_unit }}</span>
          </div>
        </div>

        <button class="tt-button tt-button--outlined"
                v-if="showRequestModalButton"
                @click="openRequestModal">
          {{ $t(buttonName) }}
        </button>
        <button class="tt-button tt-button--outlined"
                v-else
                :disabled="isButtonDisabled"
                @click="addToCart">
          {{ $t(buttonName) }}
        </button>

        <button class="tt-button tt-button--primary"
                v-if="!isButtonDisabled && !showRequestModalButton"
                @click="createOrderImmediately">
          {{ $t('main_vpopup_btn_buy2direct') }}
        </button>

        <RequestModal v-if="showRequestOrderModal" />
      </div>
    </div>
  </div>
</template>

<script>
import ImageLazy from "@/components/shared/ImageLazy.vue";
import {cartMixin} from "@/mixins/cartMixin";
import RequestModal from "@/components/modal/RequestModal.vue";
import {mapState} from "vuex";
import product from "@/views/Product.vue";

export default {
  props: ['product'],
  data: () => ({
    c1: null,
    c2: null,
    count: 1,
    carouselSettings: {
      draggable: false,
      focusOnSelect: true,
      slidesToShow: 7,
      responsive: [
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 8,
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 5,
            arrows: false
          }
        }
      ]
    },
    city: JSON.parse(localStorage.getItem('city')) || {},
  }),
  mixins: [cartMixin],
  computed: {
    ...mapState({
      showRequestOrderModal: state => state.modalRequestOrder.showRequestOrderModal,
    }),
    isButtonDisabled() {
      return this.product.is_onlyview || this.product.stock === 'red';
    },
    buttonName() {
      switch (true) {
        case this.product.stock === 'red':
          return 'product_card_btn_name';
        case !!this.product.is_onlyview:
          return 'home_list_btn_only_view_product';
        case !!this.product.is_special:
          return 'home_list_btn_special_product';
        case !!this.product.is_request:
          return 'home_list_btn_request_product';
        case !!this.product.next_day:
          return 'home_list_btn_next_day_product';
        default:
          return 'home_list_btn_add2cart';
      }
    },
    showRequestModalButton() {
      return (this.city.name === 'Астана' && this.product.is_request) || this.city.name !== 'Астана';
    },
    multipleProducts() {
      if (!this.product?.products_size_related) return false
      const products = [this.product, ...this.product.products_size_related]
      return products.sort((a, b) => a.price - b.price)
    },
    selectedProduct() {
      return this.multipleProducts && this.multipleProducts[0] || this.product
    }
  },
  methods: {
    addToCart() {
      if (!this.canAddProductToCart(this.product)) {
        return;
      }
      this.$store.dispatch("cart/addProduct", this.product)
          .then(response => {
            this.$toast.open({
              position: 'top-right',
              type: 'success',
              message: 'Ваш продукт ' + this.product.name + ' добавлен в корзину!',
              duration: 3000,
            });
          });
      this.$store.dispatch("modalProduct/setModalName", null)
    },
    createOrderImmediately() {
      if (!this.canAddProductToCart(this.product)) {
        return;
      }
      this.$store.dispatch("cart/addProduct", this.product);
      this.$store.dispatch("modalProduct/setModalName", null);
      if (this.$router.currentRoute.path !== '/create-order') {
        this.$router.push('/create-order');
      }
    },
    openRequestModal() {
      // if (this.activeFilial.id) {
      // this.$store.commit('filial/SET_ACTIVE_FILIAL', this.activeFilial);
      this.$store.dispatch("modalRequestOrder/setModalProduct", this.product);
      this.$store.dispatch('modalRequestOrder/setShowRequestOrderModal', true);
      // }
    },
    onSelectSize(id) {
      if (!id) return
      console.log('qq11')
      this.$emit('sizeChange', id)
    }
  },
  mounted() {
    this.c1 = this.$refs.c1;
    this.c2 = this.$refs.c2;
  },
  components: {
    ImageLazy,
    RequestModal
  }
}
</script>

<style lang="sass" scoped>
@import "src/assets/styles-new/typography"
@import "src/assets/styles-new/vars"

.tt-product-info
  position: relative
  display: flex
  gap: 40px
  margin-bottom: 60px
  &__images
    width: 544px
  &__selected-image
    height: 572px
  &__carousel
    cursor: pointer
    margin-top: 20px
    height: 76px
    min-width: 66px
    max-width: 66px
  &__info
    flex: 1
    //max-height: 572px
    display: flex
    flex-direction: column
    justify-content: space-between
  &__title
    font-size: $fs-larger
    font-weight: $fw-semibold
  &__sizes
    //display: flex
    justify-content: center
    align-items: center
    width: 100%
    border-radius: 6px
    &-tab
      display: flex
      justify-content: center
      padding: 5px 20px
      user-select: none
      cursor: pointer
      &.active
        background-color: #25B6BF
        border-radius: 6px
        color: #ffffff
  &__description
    margin-top: 18px
    font-size: $fs-small
  &__ingredients-title
    margin-top: 16px
    font-weight: $fw-semibold
  &__ingredients
    margin-top: 8px
    font-size: $fs-small
  &__price-row
    display: flex
    justify-content: space-between
  &__price-title
    font-size: $fs-medium
    font-weight: $fw-semibold
  &__price
    font-size: $fs-semilarge
    font-weight: $fw-semibold
  .tt-button
    height: 56px
    margin-top: 20px

@media screen and (max-width: $tablet-width)
  .tt-product-info
    flex-direction: column
    gap: 20px
    &__images
      width: 100%
    &__selected-image
      height: 529px
    &__title
      font-size: $fs-large
    &__description
      margin-top: 16px
    &__info
      gap: 24px
    &__price
      font-size: $fs-medium
    &__footer
      position: fixed
      bottom: 0
      left: 0
      width: 100%
      background-color: #fff
      padding: 10px 5px
      box-shadow: 0 -3px 3px -3px #999
      z-index: 2
      display: flex
      flex-wrap: wrap
      justify-content: space-between
      .tt-product-info__price-row
        width: 100%
      .tt-button
        max-width: 47%


@media screen and (max-width: $mobile-width)
  .tt-product-info
    &__selected-image
      height: 360px
    &__title
      font-size: $fs-semilarge
</style>