<template>
  <div class="tt-modal-container">
    <div class="tt-modal-container__modal">
      <div class="tt-modal-container__header">
        <h2 class="tt-modal-container__title ff-mont">
          {{ $t('home_list_btn_request_product') }}
        </h2>
        <div class="tt-icon-button" @click="close">
          <img src="@/assets/icons-new/close_xl.svg">
        </div>
      </div>
      <div class="tt-request-modal">
        <form class="tt-request-modal__form"
              v-if="!formSent">
          <div class="tt-input-field">
            <label class="tt-input-field__label">
              {{ $t('request_order_modal_input_name') }}
            </label>
            <input class="tt-input-field__input"
                   :class="{ 'tt-input-field__input--invalid': isInvalid('name') }"
                   :placeholder="$t('request_order_modal_fio_placeholder')"
                   v-model="form.name">
            <div class="tt-input-field__error">
              {{ nameError }}
            </div>
          </div>

          <div class="tt-input-field">
            <label class="tt-input-field__label">
              {{ $t('request_order_modal_input_phone') }}
            </label>
            <input type="tel"
                   class="tt-input-field__input"
                   :class="{ 'tt-input-field__input--invalid': isInvalid('phone') }"
                   v-mask="'+7 (###) ###-##-##'"
                   placeholder="+7 (___) ___ __ __"
                   v-model="form.phone">
            <div class="tt-input-field__error">
              {{ phoneError }}
            </div>
          </div>

          <p
              v-if="city.delivery_commission === 0"
              class="tt-request-modal__form-text-delivery"
          >
            {{ $t('delivery_regions_for_yandex') }}
          </p>

          <p
              v-else
              class="tt-request-modal__form-text-delivery"
          >
            {{ $t('delivery_regions_for_comission') }} <span>{{ city.delivery_commission }}</span>
          </p>

          <p
              v-if="!isWithinTimeRange"
              class="tt-request-modal__form-text-work"
          >
            {{ $t('validation_for_work_time_01') }} <span> {{ city.name }}</span> {{ $t('validation_for_work_time_02') }}
            <br />
            {{ $t('validation_for_work_time_03') }} <span>{{ city.start_work.slice(0, 5) }}</span>
            {{ $t('validation_for_work_time_04') }} <span>{{ city.end_work.slice(0, 5) }}</span>
          </p>

          <button class="tt-button tt-button--primary"
                  :disabled="loading || !isWithinTimeRange"
                  @click.prevent="submitRequest">
            {{ $t('request_order_modal_btn2send') }}
          </button>
        </form>

        <div class="tt-request-modal__success"
             v-else>
          <p class="justify-content-center align-items-center">
            {{ $t('request_order_modal_save_success_send_to_messenger') }}
          </p>
          <div class="float-right">
            <a
                :href="
                  'https://wa.me/' +
                  this.city.whatsapp_phone +
                  '/' +
                  `?text=${frontURL}/product/${this.form.product_id}%0A` +
                  `product: ${this.modalProduct.name}%0A` +
                  `city: ${this.city.name}%0A` +
                  `name: ${this.form.name}%0A` +
                  `mobile: ${this.form.phone}`
                "
                target="_blank"
                class="tt-button tt-button--primary tt-request-modal__link"
            >
              {{ $t('request_order_modal_save_success_send_to_messenger_button') }}
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="tt-modal-container__backdrop" @click="close"></div>
  </div>
</template>

<script>
import {mapState} from "vuex";
import {required} from "vuelidate/lib/validators";
import {phoneValidator} from "@/mixins/validators";

export default {
  data: () => ({
    city: JSON.parse(localStorage.getItem('city')) || {},
    form: {
      name: '',
      phone: '',
      city_id: null,
      product_id: null,
    },
    errors: {},
    loading: false,
    attemptedSubmit: false,
    formSent: false,
    frontURL: process.env.VUE_APP_BASE_URL || "http://localhost:8080/",
  }),
  computed: {
    ...mapState({
      showRequestOrderModal: state => state.modalRequestOrder.showRequestOrderModal,
      modalProduct: state => state.modalRequestOrder.modalProduct,
    }),
    nameError() {
      return this.errors.name ? this.errors.name[0] : null;
    },
    phoneError() {
      return this.errors.phone ? this.errors.phone[0] : null;
    },
    isWithinTimeRange() {
      const currentTime = new Date();
      const startWork = this.parseTime(this.city.start_work);
      const endWork = this.parseTime(this.city.end_work);

      return currentTime >= startWork && currentTime <= endWork;
    },
  },
  validations() {
    return {
      form: {
        phone: {
          required,
          phoneValidator
        },
        name: {
          required
        },
        city_id: {
          required
        },
        product_id: {
          required
        },
      },
    }
  },
  methods: {
    parseTime(timeString) {
      const date = new Date();
      const [hours, minutes, seconds] = timeString.split(':').map(Number);

      date.setHours(hours);
      date.setMinutes(minutes);
      date.setSeconds(seconds);

      return date;
    },

    isInvalid(prop) {
      return this.$v.form[prop].$dirty && this.$v.form[prop].$invalid;
    },
    showError(err) {
      return typeof err === 'object' ? err[0] : err;
    },
    close() {
      this.setShowModal(false);
    },
    setShowModal(value) {
      this.$store.dispatch("modalRequestOrder/setShowRequestOrderModal", value);
    },
    async submitRequest() {
      console.log("otpravit?")
      // сюда нужно отправлять данные по адресу доставки если форма адрес доставки не пустая
      // сюда нужно добавить количество продуктов + и -
      this.attemptedSubmit = true;
      if(this.modalProduct) {
        this.form.product_id = this.modalProduct.id;
      }
      if(this.city) {
        this.form.city_id = this.city.id;
      }

      if (this.$v.form.$invalid) {
        this.$v.form.$touch();
        return;
      }
      this.loading = true;
      let formData = new FormData();
      Object.keys(this.form).forEach(item => {
        formData.append(item, this.form[item]);
      });

      try {
        await this.$axios.post("/request-order", formData);
        console.log('sent');
        this.formSent = true;
        this.$toast.open({
          position: 'top-right',
          type: 'success',
          message: 'Your message was sent successfully!',
          duration: 3000,
        });
      } catch (e) {
        this.$toast.open({
          position: 'top-right',
          type: 'error',
          message: e.response.data.message,
          duration: 3000,
        });
      } finally {
        this.loading = false;
      }
    },
  }
};
</script>

<style lang="sass" scoped>
@import "src/assets/styles-new/colors"
@import "src/assets/styles-new/typography"
@import "src/assets/styles-new/vars"

.tt-modal-container
  position: fixed
  z-index: $zi-fixed
  top: 0
  left: 0
  right: 0
  bottom: 0
  display: flex
  align-items: center
  justify-content: center
  &__modal
    position: relative
    z-index: $zi-modal
    background-color: $white
    border-radius: 8px
    overflow: hidden
    padding: 32px
  &__header
    display: flex
    justify-content: space-between
    align-items: center
    margin-bottom: 24px
  &__title
    font-size: $fs-semilarge
    font-weight: $fw-semibold
    margin: 0
    color: var(--Black, #1D1D1D)
    text-align: center
    font-style: normal
    line-height: normal
  .tt-icon-button
    position: absolute
    top: 20px
    right: 20px
  &__backdrop
    position: absolute
    z-index: $zi-modal-backdrop
    width: 100%
    height: 100%
    background-color: rgba(0, 0, 0, 0.4)

.tt-request-modal
  display: flex
  justify-content: space-between
  gap: 32px
  &__form
    width: 100%
    max-width: 544px
    .tt-input-field
      margin-bottom: 10px
    .tt-button
      margin-top: 30px
      height: 56px
  &__form-text-delivery
    margin-top: 16px
    font-size: 14px
    font-weight: 400
    line-height: 19px

    span
      font-weight: 700
  &__form-text-work
    font-size: 14px
    font-weight: 400
    line-height: 19px

    span
      font-weight: 700
  &__success
    margin-top: 8px
    color: $black
    font-weight: $fw-medium
  &__link
    display: flex
    align-items: center
    padding: 0 10px

</style>