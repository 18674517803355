<template>
  <div class="tt-modal-wrapper">
    <div class="tt-product-of-the-day">
      <div class="tt-product-of-the-day__header">
        <h2 class="tt-product-of-the-day__title ff-mont">
          {{ $t('main_ProductOfTheDay_title') }}
        </h2>
        <div class="tt-icon-button"
             @click="close">
          <img src="@/assets/icons-new/close_xl.svg">
        </div>
      </div>
      <div class="tt-card">
        <div class="tt-card__image">
          <ImageLazy :img-src="product.images[0].img"
                     :alt-text="product.name" />
        </div>
        <div class="tt-card__info">
          <div class="tt-card__name">
            {{ product.name }}
          </div>
          <div class="tt-card__price">
            {{ product.price }} ₸
          </div>
        </div>
        <button class="tt-button tt-button--primary"
                @click="addToCart">
          {{ $t('main_ProductOfTheDay_add_to_cart') }}
        </button>
      </div>
    </div>

    <div class="tt-modal-wrapper__backdrop"
         @click="close"></div>
  </div>
</template>

<script>
import ImageLazy from "@/components/shared/ImageLazy.vue";

export default {
  computed: {
    product() {
      return this.$store.state.productOfTheDay.product;
    }
  },
  methods: {
    close() {
      this.$store.dispatch("productOfTheDay/setIsShownToTrue");
    },
    addToCart() {
      this.$store.dispatch("cart/addProduct", this.product)
          .then(response => {
            this.$toast.open({
              position: 'top-right',
              type: 'success',
              message: 'Ваш продукт ' + this.product.name + ' добавлен в корзину!',
              duration: 3000,
            });
          });
      this.close();
    }
  },
  components: {
    ImageLazy
  }
}
</script>

<style lang="sass" scoped>
@import "src/assets/styles-new/colors"
@import "src/assets/styles-new/typography"
@import "src/assets/styles-new/vars"

.tt-modal-wrapper
  position: fixed
  z-index: $zi-fixed
  top: 0
  left: 0
  right: 0
  bottom: 0
  display: flex
  align-items: center
  justify-content: center
  &__backdrop
    position: absolute
    z-index: $zi-modal-backdrop
    width: 100%
    height: 100%
    background-color: rgba(0, 0, 0, 0.4)

.tt-product-of-the-day
  position: relative
  z-index: $zi-modal
  max-width: 90%
  background-color: $white
  padding: 32px
  border-radius: 8px
  &__header
    display: flex
    justify-content: space-between
    align-items: center
    margin-bottom: 24px
  &__title
    font-size: $fs-semilarge
    font-weight: $fw-semibold
    margin: 0

.tt-card
  display: flex
  flex-direction: column
  align-items: center
  &__image
    width: 256px
    height: 256px
    margin-bottom: 12px
  &__info
    width: 100%
    display: flex
    justify-content: space-between
  &__name
    font-weight: $fw-medium
    line-height: 1.375rem
    height: 2.75rem
  &__price
    margin-left: 16px
    font-weight: $fw-semibold
    font-size: $fs-medium
    white-space: nowrap
</style>