<template>
  <div class="tt-order-detail">
    <div class="tt-order-detail__label">
      {{ $t(i18nKey) }}
    </div>
    <div class="tt-order-detail__value">
      <div v-if="!forStatus">
        {{ value }}
      </div>
      <div class="tt-order-detail__status"
           :class="{ 'tt-order-detail__status--success' : value === 'Served',
                     'tt-order-detail__status--error' : value === 'Cancelled' }"
           v-else>
        {{ statusName }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    i18nKey: '',
    value: null,
    forStatus: false,
    paymentExpired: false,
    payedAt: null
  },
  computed: {
    statusName() {
      switch (this.value) {
        case 'Cancelled': return this.$t('PersonalArea_views_status_cancelled');
        case 'Unconfirmed': return this.$t('PersonalArea_Order_statuses_unconfirmed');
        case 'WaitCooking': return this.$t('PersonalArea_Order_statuses_waiting');
        case 'ReadyForCooking': return this.$t('PersonalArea_Order_statuses_readyforcooking');
        case 'CookingStarted': return this.$t('PersonalArea_Order_statuses_waitcooking');
        case 'CookingCompleted': return this.$t('PersonalArea_Order_statuses_cookingcompleted');
        case 'Waiting': return this.$t('PersonalArea_Order_statuses_waiting');
        case 'OnWay': return this.$t('PersonalArea_Order_statuses_onway');
        case 'Delivered': return this.$t('PersonalArea_Order_statuses_delivered');
        case 'Served': return this.$t('PersonalArea_Order_statuses_closed');
        case 'New': {
          if (this.paymentExpired) {
            return this.$t('PersonalArea_has_not_been_paid_and_payment_url_expired');
          } else if (!this.payedAt) {
            return this.$t('PersonalArea_has_not_been_paid');
          } else if (this.payedAt){
            return this.$t('PersonalArea_Order_statuses_error');
          } else {
            return 'NEW';
          }
        }
        default: return 'PENDING';
      }
    }
  }
}
</script>

<style lang="sass" scoped>
@import "src/assets/styles-new/typography"
@import "src/assets/styles-new/colors"
@import "src/assets/styles-new/vars"

.tt-order-detail
  display: flex
  flex-direction: column
  gap: 8px
  &__label
    font-size: $fs-small
    color: $dark-gray
  &__value
    font-size: $fs-medium
    font-weight: $fw-medium
  &__status
    color: $secondary
    &--error
      color: $error
    &--success
      color: $green

@media screen and (max-width: $tablet-width)
  .tt-order-detail
    gap: 6px

@media screen and (max-width: $mobile-width)
  .tt-order-detail
    gap: 0
    &__value
      font-size: 1rem
</style>