<template>
  <div class="franchising-landing">
    <div class="franchising-hero">
      <p class="franchising-hero__title">{{ $t('franchise_landing_header_title') }}</p>
      <p class="franchising-hero__content">
        {{ $t('franchise_landing_content_01')}}
      </p>
      <div class="franchising-hero__link" @click="linkToFranchise">
        {{ $t('franchise_landing_btn_to_forms') }}
      </div>
      <img
        class="franchising-hero__cake1"
        src="@/assets/images/franchising_hero_cake1.png"
        alt=""
      />
      <img
        class="franchising-hero__cake2"
        src="@/assets/images/franchising_hero_cake2.png"
        alt=""
      />
      <img
        class="franchising-hero__cake3"
        src="@/assets/images/franchising_hero_cake3.png"
        alt=""
      />
    </div>
    <div class="franchising-points">
      <div class="franchising-points__point">
        <div class="franchising-points__point__img-container">
          <img
            height="24"
            width="auto"
            src="@/assets/images/franchising_point_1.svg"
          />
        </div>
        <span v-html="$t('franchise_landing_feature_01')"></span>
      </div>
      <div class="franchising-points__point">
        <div class="franchising-points__point__img-container">
          <img
            height="24"
            width="auto"
            src="@/assets/images/franchising_point_2.svg"
          />
        </div>
        <span v-html="$t('franchise_landing_feature_01')"></span>
      </div>
      <div class="franchising-points__point">
        <div class="franchising-points__point__img-container">
          <img
            height="24"
            width="auto"
            src="@/assets/images/franchising_point_3.svg"
          />
        </div>
        <span v-html="$t('franchise_landing_feature_03')"></span>
      </div>
    </div>
    <div class="franchising-accomplishments">
      <div class="franchising-accomplishments__left">
        <img src="@/assets/images/franchising_accomplishments.png" alt="" />
      </div>
      <div class="franchising-accomplishments__right">
        <p class="franchising-accomplishments__right__title">{{ $t('franchise_landing_accomplishments_title') }}</p>
        <div class="franchising-accomplishments__right__row1">
          <div class="franchising-accomplishments__right__row1__item">
            <img
              src="@/assets/images/franchising_accomplishments_1.svg"
              alt=""
            />
            <p class="franchising-accomplishments__right__row1__item__text">
              {{ $t('franchise_landing_accomplishments_01') }}
            </p>
          </div>
          <div class="franchising-accomplishments__right__row1__item">
            <img
              src="@/assets/images/franchising_accomplishments_2.svg"
              alt=""
            />
            <p class="franchising-accomplishments__right__row1__item__text" v-html="$t('franchise_landing_accomplishments_02')"></p>
          </div>
          <div class="franchising-accomplishments__right__row1__item">
            <img
              src="@/assets/images/franchising_accomplishments_3.svg"
              alt=""
            />
            <p class="franchising-accomplishments__right__row1__item__text" v-html="$t('franchise_landing_accomplishments_03')"></p>
          </div>
          <div class="franchising-accomplishments__right__row1__item">
            <img
              src="@/assets/images/franchising_accomplishments_4.svg"
              alt=""
            />
            <p class="franchising-accomplishments__right__row1__item__text" v-html="$t('franchise_landing_accomplishments_04')"></p>
          </div>
        </div>
        <div class="franchising-accomplishments__right__row2">
          <div class="franchising-accomplishments__right__row2__item">
            <p class="franchising-accomplishments__right__row2__item__title">
              {{ $t('franchise_landing_accomplishments_row_01') }}
            </p>
            <p class="franchising-accomplishments__right__row2__item__subtitle" v-html="$t('franchise_landing_accomplishments_row2_01')"></p>
          </div>
          <div class="franchising-accomplishments__right__row2__item">
            <p class="franchising-accomplishments__right__row2__item__title">
              {{ $t('franchise_landing_accomplishments_row_02') }}
            </p>
            <p class="franchising-accomplishments__right__row2__item__subtitle" v-html="$t('franchise_landing_accomplishments_row2_02')"></p>
          </div>
          <div class="franchising-accomplishments__right__row2__item">
            <p class="franchising-accomplishments__right__row2__item__title">
              {{ $t('franchise_landing_accomplishments_row_03') }}
            </p>
            <p class="franchising-accomplishments__right__row2__item__subtitle" v-html="$t('franchise_landing_accomplishments_row2_03')"></p>
          </div>
        </div>
        <button class="tt-button tt-button--primary"
                :disabled="loading"
                @click="linkToFranchise"
        >
          {{ $t('franchise_landing_accomplishments_button_to_franchise_form') }}
        </button>
      </div>
    </div>
    <div class="franchising-values">
      <p class="franchising-values__title">{{ $t('franchise_landing_taptatti_values_title') }}</p>
      <p class="franchising-values__content">
        {{ $t('franchise_landing_taptatti_values_content_01') }}
      </p>
      <div class="franchising-values__list">
        <div
          v-for="(v, k) in valuesSectionItems"
          :key="k"
          class="franchising-values__list__box"
        >
          <div class="franchising-values__list__box__number">
            <span>{{ k + 1 }}</span>
          </div>
          <p class="franchising-values__list__box__title">{{ $t(v.title) }}</p>
          <p class="franchising-values__list__box__content">{{ $t(v.content) }}</p>
        </div>
      </div>
    </div>
    <div class="franchising-why">
      <p class="franchising-why__title">{{ $t('franchise_landing_why_taptatti_title') }}</p>
      <div class="franchising-why__list">
        <div
          v-for="(w, k) in whySectionItems"
          :key="k"
          class="franchising-why__list__box"
        >
          <div class="franchising-why__list__box__top">
            <img :src="w.img" alt="" />
            <p class="franchising-why__list__box__top__title">{{ $t(w.title) }}</p>
          </div>
          <p class="franchising-why__list__box__content">{{ $t(w.content) }}</p>
        </div>
      </div>
    </div>
<!--    <div class="franchising-reviews">-->
<!--      <p class="franchising-reviews__title">Наши отзывы</p>-->
<!--      <div class="franchising-reviews__items">-->
<!--        <div-->
<!--          v-for="(reviewItem, k) in reviewsCurrentShow"-->
<!--          :key="k + '_' + reviewsCurrentIndex"-->
<!--          class="franchising-reviews__items__box"-->
<!--        >-->
<!--          <div class="franchising-reviews__items__box__top">-->
<!--            <img :src="reviewItem.img" alt="" />-->
<!--            <div>-->
<!--              <p class="franchising-reviews__items__box__top__title">-->
<!--                {{ reviewItem.title }}-->
<!--              </p>-->
<!--              <p-->
<!--                class="franchising-reviews__items__box__top__subtitle"-->
<!--                @click="linkToTwoGis"-->
<!--              >-->
<!--                {{ reviewItem.subtitle }}-->
<!--              </p>-->
<!--            </div>-->
<!--          </div>-->
<!--          <p class="franchising-reviews__items__box__content">-->
<!--            {{ reviewItem.content }}-->
<!--          </p>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="franchising-reviews__controls">-->
<!--        <div-->
<!--          @click="reviewsLeftClick"-->
<!--          class="franchising-reviews__controls__arrow-container"-->
<!--        >-->
<!--          <img-->
<!--            src="@/assets/images/franchising_reviews_left-arrow.svg"-->
<!--            alt=""-->
<!--          />-->
<!--        </div>-->
<!--        <div-->
<!--          @click="reviewsRightClick"-->
<!--          class="franchising-reviews__controls__arrow-container"-->
<!--        >-->
<!--          <img-->
<!--            src="@/assets/images/franchising_reviews_right-arrow.svg"-->
<!--            alt=""-->
<!--          />-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
    <br />
    <div class="franchising-help">
      <p class="franchising-help__title">{{ $t('franchise_landing_support_franchisi_title') }}</p>
      <br /><br /><br />
      <div class="franchising-help__construction-section">
        <div class="franchising-help__construction-section__left">
          <img src="@/assets/images/franchising_help_construction.png" alt="" />
        </div>
        <div class="franchising-help__construction-section__right">
          <p class="franchising-help__construction-section__right__title">
            {{ $t('franchise_landing_support_header_01') }}
          </p>
          <ul class="franchising-help__construction-section__right__ul">
            <li class="franchising-help__construction-section__right__ul__li">
              {{ $t('franchise_landing_support_description_01') }}
            </li>
            <li class="franchising-help__construction-section__right__ul__li">
              {{ $t('franchise_landing_support_description_02') }}
            </li>
            <li class="franchising-help__construction-section__right__ul__li">
              {{ $t('franchise_landing_support_description_03') }}
            </li>
            <li class="franchising-help__construction-section__right__ul__li">
              {{ $t('franchise_landing_support_description_04') }}
            </li>
          </ul>
        </div>
      </div>
      <div class="franchising-help__purchase-section">
        <div class="franchising-help__purchase-section__left">
          <p class="franchising-help__purchase-section__left__title">{{ $t('franchise_landing_support_header_02') }}</p>
          <ul class="franchising-help__purchase-section__left__ul">
            <li class="franchising-help__purchase-section__left__ul__li">
              {{ $t('franchise_landing_support_description_02_01') }}
            </li>
            <li class="franchising-help__purchase-section__left__ul__li">
              {{ $t('franchise_landing_support_description_02_02') }}
            </li>
            <li class="franchising-help__purchase-section__left__ul__li">
              {{ $t('franchise_landing_support_description_02_03') }}
            </li>
            <li class="franchising-help__purchase-section__left__ul__li">
              {{ $t('franchise_landing_support_description_02_04') }}
            </li>
          </ul>
        </div>
        <div class="franchising-help__purchase-section__right">
          <img src="@/assets/images/franchising_help_purchase.png" alt="" />
        </div>
      </div>
      <p class="franchising-help__training-section__title">{{ $t('franchise_landing_support_header_03') }}</p>
      <div class="franchising-help__training-section">
        <div class="franchising-help__training-section__column">
          <img src="@/assets/images/franchising_help_training1.png" alt="" />
          <ul class="franchising-help__training-section__column__ul">
            <li class="franchising-help__training-section__column__ul__li">
              {{ $t('franchise_landing_support_description_03_01') }}
            </li>
            <li class="franchising-help__training-section__column__ul__li">
              {{ $t('franchise_landing_support_description_03_02') }}
            </li>
          </ul>
        </div>
        <div class="franchising-help__training-section__column">
          <img src="@/assets/images/franchising_help_training2.png" alt="" />
          <ul class="franchising-help__training-section__column__ul">
            <li class="franchising-help__training-section__column__ul__li">
              {{ $t('franchise_landing_support_description_03_03') }}
            </li>
            <li class="franchising-help__training-section__column__ul__li">
              {{ $t('franchise_landing_support_description_03_04') }}
            </li>
            <li class="franchising-help__training-section__column__ul__li">
              {{ $t('franchise_landing_support_description_03_05') }}
            </li>
          </ul>
        </div>
      </div>
      <div class="franchising-help__support-section">
        <div class="franchising-help__support-section__left">
          <img src="@/assets/images/franchising_help_support.png" alt="" />
        </div>
        <div class="franchising-help__support-section__right">
          <p class="franchising-help__support-section__right__title">
            {{ $t('franchise_landing_support_header_04') }}
          </p>
          <ul class="franchising-help__support-section__right__ul">
            <li class="franchising-help__support-section__right__ul__li">
              {{ $t('franchise_landing_support_description_04_01') }}
            </li>
            <li class="franchising-help__support-section__right__ul__li">
              {{ $t('franchise_landing_support_description_04_02') }}
            </li>
            <li class="franchising-help__support-section__right__ul__li">
              {{ $t('franchise_landing_support_description_04_03') }}
            </li>
            <li class="franchising-help__support-section__right__ul__li">
              {{ $t('franchise_landing_support_description_04_04') }}
            </li>
          </ul>
        </div>
      </div>
      <div class="franchising-help__marketing-section">
        <div class="franchising-help__marketing-section__column">
          <img src="@/assets/images/franchising_help_marketing1.png" alt="" />
          <p class="franchising-help__marketing-section__column__title">
            {{ $t('franchise_landing_support_header_05') }}
          </p>
          <p class="franchising-help__marketing-section__column__li">
            {{ $t('franchise_landing_support_description_05_01') }}
          </p>
          <p class="franchising-help__marketing-section__column__li">
            {{ $t('franchise_landing_support_description_05_02') }}
          </p>
        </div>
        <div class="franchising-help__marketing-section__column">
          <img src="@/assets/images/franchising_help_marketing2.png" alt="" />
          <p class="franchising-help__marketing-section__column__title">
            {{ $t('franchise_landing_support_header_06') }}
          </p>
          <p class="franchising-help__marketing-section__column__li">
            {{ $t('franchise_landing_support_description_06_01') }}
          </p>
          <p class="franchising-help__marketing-section__column__li">
            {{ $t('franchise_landing_support_description_06_02') }}
          </p>
          <p class="franchising-help__marketing-section__column__li">
            {{ $t('franchise_landing_support_description_06_03') }}
          </p>
        </div>
      </div>
    </div>
    <div class="franchising-dynamics">
      <p class="franchising-dynamics__title">
        {{ $t('franchise_landing_dynamic_title') }}
      </p>
      <div class="franchising-dynamics__row">
        <div class="franchising-dynamics__row__col">
          <img src="@/assets/images/franchising_dynamics_city1.png" alt="" />
          <p>{{ $t('franchise_landing_dynamic_descriotion_01') }}</p>
        </div>
        <div class="franchising-dynamics__row__col">
          <img src="@/assets/images/franchising_dynamics_city2.png" alt="" />
          <p>{{ $t('franchise_landing_dynamic_descriotion_02') }}</p>
        </div>
        <div class="franchising-dynamics__row__col">
          <img src="@/assets/images/franchising_dynamics_city3.png" alt="" />
          <p>{{ $t('franchise_landing_dynamic_descriotion_03') }}</p>
        </div>
      </div>
      <p class="franchising-dynamics__asterisk">
        {{ $t('franchise_landing_dynamic_source_data') }}
      </p>
    </div>
    <div class="franchising-terms">
      <p class="franchising-terms__title">{{ $t('franchise_landing_terms_title') }}</p>
      <div class="franchising-terms__row">
        <div class="franchising-terms__row__box">
          <div class="franchising-terms__row__box__icon-container">
            <img src="@/assets/images/franchising_terms_icon1.svg" alt="" />
          </div>
          <p class="franchising-terms__row__box__content">
            {{ $t('franchise_landing_terms_descr_01') }}
          </p>
        </div>
        <div class="franchising-terms__row__box">
          <div class="franchising-terms__row__box__icon-container">
            <img src="@/assets/images/franchising_terms_icon2.svg" alt="" />
          </div>
          <p class="franchising-terms__row__box__content">
            {{ $t('franchise_landing_terms_descr_02') }}
          </p>
        </div>
        <div class="franchising-terms__row__box">
          <div class="franchising-terms__row__box__icon-container">
            <img src="@/assets/images/franchising_terms_icon3.svg" alt="" />
          </div>
          <p class="franchising-terms__row__box__content">
              {{ $t('franchise_landing_terms_descr_03') }}
          </p>
        </div>
      </div>
    </div>
    <div class="franchising-options">
      <p class="franchising-options__title">
        {{ $t('franchise_landing_options_title') }}
      </p>
      <div class="franchising-options__row">
        <div class="franchising-options__row__box">
          <p class="franchising-options__row__box__title">
            {{ $t('franchise_landing_options_header_01') }}
          </p>
          <ul class="franchising-options__row__box__ul">
            <li class="franchising-options__row__box__ul__li">
              {{ $t('franchise_landing_options_descr_01') }}
            </li>
          </ul>
        </div>
        <div class="franchising-options__row__box">
          <p class="franchising-options__row__box__title">
            {{ $t('franchise_landing_options_header_02') }}
          </p>
          <ul class="franchising-options__row__box__ul">
            <li class="franchising-options__row__box__ul__li">
              {{ $t('franchise_landing_options_descr_02') }}
            </li>
            <li class="franchising-options__row__box__ul__li">{{ $t('franchise_landing_options_descr_03') }}</li>
          </ul>
        </div>
        <div class="franchising-options__row__box">
          <p class="franchising-options__row__box__title" v-html="$t('franchise_landing_options_header_03')"></p>
          <ul class="franchising-options__row__box__ul">
            <li class="franchising-options__row__box__ul__li">
              {{ $t('franchise_landing_options_descr_03_01') }}
            </li>
            <li class="franchising-options__row__box__ul__li">{{ $t('franchise_landing_options_descr_03_02') }}</li>
            <li class="franchising-options__row__box__ul__li">
              {{ $t('franchise_landing_options_descr_03_03') }}
            </li>
            <li class="franchising-options__row__box__ul__li">{{ $t('franchise_landing_options_descr_03_04') }}</li>
            <li class="franchising-options__row__box__ul__li">
              {{ $t('franchise_landing_options_descr_03_05') }}
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="franchising-finance">
      <p class="franchising-finance__title">{{ $t('franchise_landing_finance_title') }}</p>
      <div class="franchising-finance__row">
        <div class="franchising-finance__row__box">
          <p class="franchising-finance__row__box__title">{{ $t('franchise_landing_finance_header_01') }}</p>
          <p class="franchising-finance__row__box__subtitle">{{ $t('franchise_landing_finance_description_01') }}</p>
        </div>
        <div class="franchising-finance__row__box">
          <p class="franchising-finance__row__box__title">{{ $t('franchise_landing_finance_header_02') }}</p>
          <p class="franchising-finance__row__box__subtitle">
            {{ $t('franchise_landing_finance_description_02') }}
          </p>
        </div>
        <div
          class="franchising-finance__row__box franchising-finance__row__box--yellow"
        >
          <p class="franchising-finance__row__box__title">{{ $t('franchise_landing_finance_header_03') }}}</p>
          <p class="franchising-finance__row__box__subtitle">
            {{ $t('franchise_landing_finance_description_03') }}
          </p>
        </div>
      </div>
    </div>
    <div class="franchising-exclusive">
      <p class="franchising-exclusive__title">
        {{ $t('franchise_landing_exclusive_title') }}
      </p>
      <div class="franchising-exclusive__big-circle">
        <p class="franchising-exclusive__big-circle__title" v-html="$t('franchise_landing_exclusive_header_01')"></p>
        <p class="franchising-exclusive__big-circle__subtitle">
          {{ $t('franchise_landing_exclusive_header_01_02') }}
        </p>
      </div>
      <div class="franchising-exclusive__row">
        <div class="franchising-exclusive__row__col">
          <p class="franchising-exclusive__row__col__title">{{ $t('franchise_landing_exclusive_header_02') }}</p>
          <p class="franchising-exclusive__row__col__description" v-html="$t('franchise_landing_exclusive_description_02_01')"></p>
        </div>
        <div class="franchising-exclusive__row__col">
          <p class="franchising-exclusive__row__col__title">{{ $t('franchise_landing_exclusive_header_03') }}</p>
          <p class="franchising-exclusive__row__col__description">
            {{ $t('franchise_landing_exclusive_description_03') }}
          </p>
        </div>
        <div class="franchising-exclusive__row__col">
          <p class="franchising-exclusive__row__col__title">{{ $t('franchise_landing_exclusive_header_04') }}</p>
          <p class="franchising-exclusive__row__col__description" v-html="$t('franchise_landing_exclusive_description_04')"></p>
        </div>
        <div class="franchising-exclusive__row__col">
          <p class="franchising-exclusive__row__col__title">{{ $t('franchise_landing_exclusive_header_05') }}</p>
          <p class="franchising-exclusive__row__col__description" v-html="$t('franchise_landing_exclusive_description_05')"></p>
        </div>
        <div class="franchising-exclusive__row__col">
          <p class="franchising-exclusive__row__col__title">{{ $t('franchise_landing_exclusive_header_06') }}</p>
          <p class="franchising-exclusive__row__col__description">{{ $t('franchise_landing_exclusive_description_06') }}</p>
        </div>
        <div class="franchising-exclusive__row__col">
          <p class="franchising-exclusive__row__col__title">{{ $t('franchise_landing_exclusive_header_07') }}</p>
          <p class="franchising-exclusive__row__col__description">
            {{ $t('franchise_landing_exclusive_description_07') }}
          </p>
        </div>
        <div class="franchising-exclusive__row__col">
          <p class="franchising-exclusive__row__col__title">{{ $t('franchise_landing_exclusive_header_08') }}</p>
          <p class="franchising-exclusive__row__col__description">
            {{ $t('franchise_landing_exclusive_description_08') }}
          </p>
        </div>
        <div class="franchising-exclusive__row__col">
          <p class="franchising-exclusive__row__col__title">{{ $t('franchise_landing_exclusive_header_09') }}</p>
          <p class="franchising-exclusive__row__col__description">
            {{ $t('franchise_landing_exclusive_description_09') }}
          </p>
        </div>
      </div>
    </div>
    <img
      class="franchising-landing__side-vector-1"
      src="@/assets/images/franchising_side-vector_left.svg"
      alt=""
    />
    <img
      class="franchising-landing__side-vector-2"
      src="@/assets/images/franchising_side-vector_right.svg"
      alt=""
    />
    <img
      class="franchising-landing__side-vector-3"
      src="@/assets/images/franchising_side-vector_left.svg"
      alt=""
    />
    <img
      class="franchising-landing__side-vector-4"
      src="@/assets/images/franchising_side-vector_right.svg"
      alt=""
    />
    <img
      class="franchising-landing__side-vector-5"
      src="@/assets/images/franchising_side-vector_left.svg"
      alt=""
    />
    <img
      class="franchising-landing__side-vector-6"
      src="@/assets/images/franchising_side-vector_right.svg"
      alt=""
    />
  </div>
</template>

<script>
export default {
  name: "Franchising",
  data() {
    return {
      valuesSectionItems: [
        {
          title: "franchise_landing_taptatti_values_header_01",
          content:
            "franchise_landing_taptatti_values_description_01",
        },
        {
          title: "franchise_landing_taptatti_values_header_02",
          content:
            "franchise_landing_taptatti_values_description_02",
        },
        {
          title: "franchise_landing_taptatti_values_header_03",
          content:
            "franchise_landing_taptatti_values_description_03",
        },
        {
          title: "franchise_landing_taptatti_values_header_04",
          content:
            "franchise_landing_taptatti_values_description_04",
        },
        {
          title: "franchise_landing_taptatti_values_header_05",
          content:
            "franchise_landing_taptatti_values_description_05",
        },
      ],
      whySectionItems: [
        {
          img: require("@/assets/images/franchising_why_1.svg"),
          title: "franchise_landing_why_taptatti_header_01",
          content:
            "franchise_landing_why_taptatti_description_01",
        },
        {
          img: require("@/assets/images/franchising_why_2.svg"),
          title: "franchise_landing_why_taptatti_header_02",
          content:
            "franchise_landing_why_taptatti_description_02",
        },
        {
          img: require("@/assets/images/franchising_why_3.svg"),
          title: "franchise_landing_why_taptatti_header_03",
          content:
            "franchise_landing_why_taptatti_description_03",
        },
        {
          img: require("@/assets/images/franchising_why_4.svg"),
          title: "franchise_landing_why_taptatti_header_04",
          content:
            "franchise_landing_why_taptatti_description_04",
        },
      ],
      reviewSectionItems: [
        {
          img: require("@/assets/images/franchising_review_ava_1.png"),
          title: "Асель Джакупова",
          subtitle: "Комментарии с 2GIS",
          content:
            "Оформила впервые заказ. Менеджер вежливая, приятная девушка. И капкейки и пироги очень вкусные. А главное свежие. Доставку сделали вовремя, как и просила. Спасибо!",
        },
        {
          img: require("@/assets/images/franchising_review_ava_2.png"),
          title: "Абдикаримова Диля",
          subtitle: "Комментарии с 2GIS",
          content:
            "Приятно сюда возвращаться, не только из-за вкусности но и из-за сервиса. Другим заведениям следовала бы у них поучиться такому сервису 👍🏾",
        },
        {
          img: require("@/assets/images/franchising_review_ava_2.png"),
          title: "3",
          subtitle: "Комментарии с 2GIS",
          content:
            "Приятно сюда возвращаться, не только из-за вкусности но и из-за сервиса. Другим заведениям следовала бы у них поучиться такому сервису 👍🏾",
        },
        {
          img: require("@/assets/images/franchising_review_ava_2.png"),
          title: "4",
          subtitle: "Комментарии с 2GIS",
          content:
            "Приятно сюда возвращаться, не только из-за вкусности но и из-за сервиса. Другим заведениям следовала бы у них поучиться такому сервису 👍🏾",
        },
        {
          img: require("@/assets/images/franchising_review_ava_2.png"),
          title: "5",
          subtitle: "Комментарии с 2GIS",
          content:
            "Приятно сюда возвращаться, не только из-за вкусности но и из-за сервиса. Другим заведениям следовала бы у них поучиться такому сервису 👍🏾",
        },
      ],
      reviewsCurrentIndex: 0,
    };
  },
  computed: {
    reviewsCurrentShow() {
      const cur = this.reviewsCurrentIndex;
      const len = this.reviewSectionItems.length;
      return [
        this.reviewSectionItems[cur],
        this.reviewSectionItems[(cur + 1) % len],
      ];
    },
  },
  methods: {
    linkToFranchise() {
      this.$router.push("/franchise");
    },
    linkToTwoGis() {
      const link =
        "https://2gis.kz/astana/branches/70000001018081728/firm/70000001030630928/71.425645%2C51.095068/tab/reviews?m=71.425645%2C51.095068%2F13.85";
      window.open(link, "_blank");
    },
    reviewsLeftClick() {
      const len = this.reviewSectionItems.length;
      const cur = this.reviewsCurrentIndex;
      this.reviewsCurrentIndex = (len + cur - 1) % len;
      console.log(this.reviewsCurrentIndex);
    },
    reviewsRightClick() {
      const len = this.reviewSectionItems.length;
      const cur = this.reviewsCurrentIndex;
      this.reviewsCurrentIndex = (cur + 1) % len;
      console.log(this.reviewsCurrentIndex);
    },
  },
  metaInfo() {
    return {
      title: this.$t('franchising_views_meta_title'),
      meta: [{
        vmid: 'description',
        property: 'description',
        content: this.$t('franchising_views_meta_description')
      }, {
        vmid: 'og:title',
        property: 'og:title',
        content: this.$t('franchising_views_meta_og_title')
      }, {
        vmid: 'og:description',
        property: 'og:description',
        content: this.$t('franchising_views_meta_og_description')
      },],
    }
  }
};
</script>

<style lang="scss">
@import "@/assets/fonts/ZonaPro/style.scss";
$mobile-width: 1024px;

@mixin custom-hover-mixin {
  transition: transform 0.3s linear, box-shadow 0.3s linear;

  &:hover {
    box-shadow: 0px 10px 20px 10px #0000001f;
    transform: translate(0, -5px);
  }
}

.franchising-landing {
  &__side-vector-1 {
    position: absolute;
    left: 0;
    top: 182px;
    z-index: 1;
  }
  &__side-vector-2 {
    position: absolute;
    right: 0;
    top: 170px;
    z-index: 1;
  }
  &__side-vector-3 {
    position: absolute;
    top: 1722px;
    left: 0;
    z-index: 1;
  }
  &__side-vector-4 {
    position: absolute;
    right: 0;
    top: 1500px;
    z-index: 1;
  }
  &__side-vector-5 {
    position: absolute;
    top: 3380px;
    left: 0;
    z-index: 1;
  }
  &__side-vector-6 {
    position: absolute;
    right: 0;
    top: 3564px;
    z-index: 1;
  }
}
.franchising-hero {
  z-index: 2;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 100px;

  &__title {
    width: 426px;
    font-family: "Zona Pro";
    font-size: 38px;
    font-weight: 600;
    line-height: 36px;
    letter-spacing: 0em;
    text-align: center;
    color: #1d1d1d;
    margin: 60px auto 25px;

    @media all and (max-width: $mobile-width) {
      width: auto;
    }
  }
  &__content {
    width: 497px;
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: center;
    color: #1d1d1dbf;
    margin-bottom: 32px;

    @media all and (max-width: $mobile-width) {
      width: auto;
    }
  }

  &__link {
    font-family: "Zona Pro";
    font-size: 18px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: -0.02em;
    text-align: left;
    padding: 20px 63px;
    color: #ffffff;
    background: #25b6bf;
    border-radius: 8px;
    cursor: pointer;

    &:hover {
      text-decoration: none;
      color: #ffffff;
      opacity: 0.8;
    }

    @media all and (max-width: $mobile-width) {
      margin-bottom: 200px;
    }
  }

  &__cake1 {
    width: 298px;
    height: 153px;
    position: absolute;
    left: -22px;
    bottom: -40px;

    @media all and (max-width: $mobile-width) {
      left: -125px;
    }
  }

  &__cake2 {
    width: 251px;
    height: 251px;
    position: absolute;
    right: -8px;
    bottom: -54px;

    @media all and (max-width: $mobile-width) {
      right: -15px;
      width: 125px;
      display: block;
      object-fit: cover;
      object-position: left;
    }
  }

  &__cake3 {
    width: 165px;
    height: 118px;
    position: absolute;
    right: 203px;
    bottom: -60px;

    @media all and (max-width: $mobile-width) {
      right: calc(50% - 165px / 2);
    }
  }
}

.franchising-points {
  z-index: 2;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 100px;
  margin-bottom: 160px;

  @media all and (max-width: $mobile-width) {
    flex-direction: column;
    gap: 19px;
    align-items: flex-start;
    justify-content: left;
    margin-bottom: 60px;
  }

  &__point {
    display: flex;
    align-items: center;

    &__img-container {
      width: 54px;
      height: 54px;
      border-radius: 10px;
      border: 1px solid #25b6bf;
      background: #fff;
      margin-right: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    span {
      padding: 0;
      font-size: 18px;
      font-weight: 600;
      line-height: 22px;
      letter-spacing: 0em;
      text-align: left;
      color: #1d1d1d;
    }
  }
}

.franchising-accomplishments {
  z-index: 2;
  position: relative;
  display: flex;
  margin-bottom: 160px;
  gap: 32px;

  @media all and (max-width: $mobile-width) {
    flex-direction: column-reverse;
    margin-bottom: 60px;
  }

  &__left {
    flex: 1 0 calc(50% - 16px);
    @media all and (max-width: $mobile-width) {
      img {
        max-width: 100vw;
        width: 100%;
      }
    }
  }
  &__right {
    flex: 1 0 calc(50% - 16px);
    &__title {
      font-family: "Zona Pro";
      font-size: 36px;
      font-weight: 600;
      line-height: 34px;
      letter-spacing: 0em;
      color: #1d1d1d;
      margin-bottom: 48px;
    }
    &__row1 {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      gap: 32px;
      margin-bottom: 42px;
      &__item {
        flex: 1 0 calc(40% - 16px);
        &:nth-child(even) {
          flex: 1 0 calc(60% - 16px);
        }
        display: flex;
        img {
          width: 42px;
          height: 42px;
          margin-right: 10px;
        }
        &__text {
          font-family: Inter;
          font-size: 18px;
          font-weight: 400;
          line-height: 20px;
          letter-spacing: 0em;
          text-align: left;
          color: #1d1d1d;
          margin-bottom: 0;
        }
      }
    }
    &__row2 {
      display: flex;
      gap: 100;
      margin-bottom: 40px;

      @media all and (max-width: $mobile-width) {
        max-width: 100vw;
        gap: 0;
        justify-content: space-between;
      }

      &__item {
        flex: 1 0 calc(100% - 100 * 2 / 3);
        margin-right: 100px;
        @media all and (max-width: $mobile-width) {
          margin-right: 0;
        }
        &__title {
          font-family: "Zona Pro";
          font-size: 34px;
          font-weight: 600;
          line-height: 28px;
          letter-spacing: -0.02em;
          text-align: center;
          color: #25b6bf;
          margin-bottom: 10px;
        }
        &__subtitle {
          font-family: Inter;
          font-size: 14px;
          font-weight: 400;
          line-height: 15px;
          letter-spacing: 0em;
          text-align: left;
          color: #1d1d1dbf;
          margin-bottom: 0;
        }
      }
    }
    &__link {
      font-family: "Zona Pro";
      font-size: 18px;
      font-weight: 600;
      line-height: 15px;
      letter-spacing: -0.02em;
      padding: 18px 56px;
      color: #ffffff;
      background: #25b6bf;
      border-radius: 8px;
      cursor: pointer;

      @media all and (max-width: $mobile-width) {
        text-align: center;
      }

      &:hover {
        text-decoration: none;
        color: #ffffff;
        opacity: 0.8;
      }
    }
  }
}
.franchising-values {
  z-index: 2;
  position: relative;
  margin-bottom: 160px;

  @media all and (max-width: $mobile-width) {
    margin-bottom: 60px;
  }

  &__title {
    font-family: "Zona Pro";
    font-size: 36px;
    font-weight: 600;
    line-height: 34px;
    letter-spacing: 0em;
    text-align: center;
    color: #1d1d1d;
    margin-bottom: 32px;
  }
  &__content {
    margin: 0 auto 32px;
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    width: 617px;
    @media all and (max-width: $mobile-width) {
      max-width: 100vw;
      width: 100%;
    }
  }
  &__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 32px;
    &__box {
      flex: 0 0 calc(33.3333% - 22px);
      background: #ffffff;
      box-shadow: 0px 5px 12px 0px #00000014;
      padding: 32px;
      border-radius: 15px;

      @media all and (max-width: $mobile-width) {
        flex: auto;
      }

      &__number {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto 24px;
        width: 72px;
        height: 72px;
        border: 2px solid #25b6bf;
        border-radius: 100%;
        span {
          font-family: "Zona Pro";
          font-size: 32px;
          font-weight: 700;
          line-height: 32px;
          letter-spacing: 0em;
          text-align: center;
          color: #25b6bf;
        }
      }
      &__title {
        padding-bottom: 16px;
        color: #25b6bf;
        font-family: "Zona Pro";
        font-size: 26px;
        font-weight: 600;
        line-height: 25px;
        letter-spacing: 0em;
        text-align: center;
      }
      &__content {
        font-family: Inter;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: center;
        color: #1d1d1d;
      }
    }
  }
}
.franchising-why {
  z-index: 2;
  position: relative;
  margin-bottom: 160px;

  @media all and (max-width: $mobile-width) {
    margin-bottom: 60px;
  }

  &__title {
    margin-bottom: 42px;
    font-family: "Zona Pro";
    font-size: 36px;
    font-weight: 600;
    line-height: 34px;
    letter-spacing: 0em;
    text-align: center;
  }
  &__list {
    display: flex;
    flex-wrap: wrap;
    gap: 32px;
    &__box {
      flex: 1 0 calc(50% - 32px);
      padding: 34px 30px;
      background: #ffffff;
      border-radius: 12px;
      box-shadow: 0px 5px 12px 0px #00000014;
      &__top {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        img {
          margin-right: 20px;
        }
        &__title {
          font-family: "Zona Pro";
          font-size: 28px;
          font-weight: 600;
          line-height: 28px;
          letter-spacing: 0em;
          text-align: left;
          color: #1d1d1d;
          margin-bottom: 0;
        }
      }
      &__content {
        font-family: Inter;
        font-size: 16px;
        font-weight: 400;
        line-height: 23px;
        letter-spacing: 0em;
        text-align: left;
        color: #1d1d1d;
      }
    }
  }
}
.franchising-reviews {
  z-index: 2;
  position: relative;
  margin-bottom: 160px;

  @media all and (max-width: $mobile-width) {
    margin-bottom: 60px;
    max-width: 100vw;
    width: 100%;
    overflow-x: hidden;
  }

  &__title {
    margin-bottom: 36px;
    font-family: "Zona Pro";
    font-size: 36px;
    font-weight: 600;
    line-height: 34px;
    letter-spacing: 0em;
    text-align: center;
    color: #1d1d1d;
  }
  &__items {
    display: flex;
    justify-content: space-between;
    gap: 32px;
    margin-bottom: 42px;
    &__box {
      flex: 1 0 calc(50% - 16px);
      padding: 32px;
      box-shadow: 0px 5px 12px 0px #00000014;
      border-radius: 12px;

      @media all and (max-width: $mobile-width) {
        flex: 1 0 100%;
      }

      &__top {
        display: flex;
        margin-bottom: 32px;
        img {
          width: 64px;
          height: 64px;
          margin-right: 24px;
        }
        &__title {
          margin-bottom: 12px;
          font-family: "Zona Pro";
          font-size: 28px;
          font-weight: 600;
          line-height: 28px;
          letter-spacing: 0em;
          text-align: left;
          color: #1d1d1d;
        }
        &__subtitle {
          margin-bottom: 0;
          font-family: Inter;
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
          letter-spacing: 0em;
          text-align: left;
          color: #1d1d1d;
          cursor: pointer;
          &:hover {
            color: #25b6bf;
          }
        }
      }
      &__content {
        font-family: Inter;
        font-size: 16px;
        font-weight: 400;
        line-height: 23px;
        letter-spacing: 0em;
        text-align: left;
        color: #1d1d1d;
      }
    }
  }
  &__controls {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 46px;
    &__arrow-container {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 54px;
      height: 54px;
      border: 2px solid #25b6bf;
      border-radius: 100%;
      &:hover {
        cursor: pointer;
        background: #25b6bf;
        img {
          filter: brightness(0) invert(1);
        }
      }
    }
  }
}
.franchising-help {
  z-index: 2;
  position: relative;
  &__title {
    color: #1d1d1d;
    margin-bottom: 36px;
    font-family: "Zona Pro";
    font-size: 36px;
    font-weight: 600;
    line-height: 34px;
    letter-spacing: 0em;
    text-align: center;
  }
  &__construction-section {
    display: flex;
    gap: 32px;
    margin-bottom: 160px;

    @media all and (max-width: $mobile-width) {
      margin-bottom: 60px;
      flex-direction: column-reverse;
    }

    &__left {
      flex: 1 1;
      img {
        width: 100%;
        height: auto;
      }
    }
    &__right {
      flex: 1 1;
      &__title {
        margin-bottom: 32px;
        font-family: "Zona Pro";
        font-size: 28px;
        font-weight: 600;
        line-height: 27px;
        letter-spacing: 0em;
        text-align: left;
      }
      &__ul {
        padding-left: 14px;
        list-style-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOCIgaGVpZ2h0PSI4IiB2aWV3Qm94PSIwIDAgOCA4IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8Y2lyY2xlIGN4PSI0IiBjeT0iNCIgcj0iNCIgZmlsbD0iIzI1QjZCRiIvPgo8L3N2Zz4K");
        &__li {
          margin-bottom: 16px;
          padding-left: 4px;
          font-family: Inter;
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: 0em;
          text-align: left;
        }
      }
    }
  }
  &__purchase-section {
    display: flex;
    gap: 32px;
    margin-bottom: 160px;

    @media all and (max-width: $mobile-width) {
      margin-bottom: 60px;
      flex-direction: column;
    }

    &__left {
      flex: 1 1;
      &__title {
        margin-bottom: 32px;
        font-family: "Zona Pro";
        font-size: 28px;
        font-weight: 600;
        line-height: 27px;
        letter-spacing: 0em;
        text-align: left;
      }
      &__ul {
        padding-left: 14px;
        list-style-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOCIgaGVpZ2h0PSI4IiB2aWV3Qm94PSIwIDAgOCA4IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8Y2lyY2xlIGN4PSI0IiBjeT0iNCIgcj0iNCIgZmlsbD0iIzI1QjZCRiIvPgo8L3N2Zz4K");
        &__li {
          margin-bottom: 16px;
          padding-left: 4px;
          font-family: Inter;
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: 0em;
          text-align: left;
        }
      }
    }
    &__right {
      flex: 1 1;
      img {
        width: 100%;
        height: auto;
      }
    }
  }
  &__training-section {
    display: flex;
    gap: 32px;
    margin-bottom: 160px;

    @media all and (max-width: $mobile-width) {
      margin-bottom: 60px;
      flex-direction: column;
    }

    &__title {
      font-family: "Zona Pro";
      font-size: 28px;
      font-weight: 600;
      line-height: 27px;
      letter-spacing: 0em;
      text-align: center;
    }

    &__column {
      flex: 1 1;
      img {
        margin-bottom: 32px;
        width: 100%;
        height: auto;
      }
      &__ul {
        padding-left: 14px;
        list-style-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOCIgaGVpZ2h0PSI4IiB2aWV3Qm94PSIwIDAgOCA4IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8Y2lyY2xlIGN4PSI0IiBjeT0iNCIgcj0iNCIgZmlsbD0iIzI1QjZCRiIvPgo8L3N2Zz4K");
        &__li {
          margin-bottom: 16px;
          padding-left: 4px;
          font-family: Inter;
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: 0em;
          text-align: left;
        }
      }
    }
  }
  &__support-section {
    display: flex;
    gap: 32px;
    margin-bottom: 160px;

    @media all and (max-width: $mobile-width) {
      margin-bottom: 60px;
      flex-direction: column;
    }

    &__left {
      flex: 1 1;
      img {
        width: 100%;
        height: auto;
      }
    }
    &__right {
      flex: 1 1;
      &__title {
        margin-bottom: 32px;
        font-family: "Zona Pro";
        font-size: 28px;
        font-weight: 600;
        line-height: 27px;
        letter-spacing: 0em;
        text-align: left;
      }
      &__ul {
        padding-left: 14px;
        list-style-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOCIgaGVpZ2h0PSI4IiB2aWV3Qm94PSIwIDAgOCA4IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8Y2lyY2xlIGN4PSI0IiBjeT0iNCIgcj0iNCIgZmlsbD0iIzI1QjZCRiIvPgo8L3N2Zz4K");
        &__li {
          margin-bottom: 16px;
          padding-left: 4px;
          font-family: Inter;
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: 0em;
          text-align: left;
        }
      }
    }
  }
  &__marketing-section {
    display: flex;
    gap: 32px;
    margin-bottom: 160px;

    @media all and (max-width: $mobile-width) {
      margin-bottom: 60px;
      flex-direction: column;
    }

    &__column {
      flex: 1 1;
      img {
        margin-bottom: 32px;
        width: 100%;
        height: auto;
      }
      &__title {
        font-family: "Zona Pro";
        font-size: 32px;
        font-weight: 600;
        line-height: 32px;
        letter-spacing: 0em;
        text-align: left;
      }
      &__li {
        font-family: Inter;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: left;
      }
    }
  }
}
.franchising-dynamics {
  z-index: 2;
  margin-bottom: 160px;

  @media all and (max-width: $mobile-width) {
    margin-bottom: 60px;
  }

  &__title {
    color: #1d1d1d;
    font-family: "Zona Pro";
    font-size: 36px;
    font-weight: 600;
    line-height: 40px;
    letter-spacing: 0em;
    text-align: center;
    margin-bottom: 48px;

    @media all and (max-width: $mobile-width) {
      margin-bottom: 24px;
    }
  }
  &__row {
    display: flex;
    margin-bottom: 48px;

    @media all and (max-width: $mobile-width) {
      margin-bottom: 24px;
      flex-direction: column;
    }

    &__col {
      flex: 1 1 auto;
      min-width: 0;
      img {
        width: 100%;
        height: auto;
      }
      p {
        font-family: Inter;
        font-size: 28px;
        font-weight: 500;
        line-height: 31px;
        letter-spacing: 0em;
        text-align: center;
      }
    }
  }
  &__asterisk {
    // width: 448px;
    font-family: Inter;
    font-size: 18px;
    font-weight: 600;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: left;
    color: #000000cc;
  }
}
.franchising-terms {
  z-index: 2;
  margin-bottom: 160px;

  @media all and (max-width: $mobile-width) {
    margin-bottom: 60px;
  }

  &__title {
    margin-bottom: 42px;
    font-family: "Zona Pro";
    font-size: 36px;
    font-weight: 600;
    line-height: 40px;
    letter-spacing: 0em;
    text-align: center;
    color: #1d1d1d;
  }
  &__row {
    display: flex;
    gap: 32px;
    justify-content: space-between;

    @media all and (max-width: $mobile-width) {
      margin-bottom: 24px;
      flex-direction: column;
    }

    &__box {
      box-shadow: 0px 5px 12px 0px #00000014;
      flex: 1 1;
      min-width: 0;
      padding: 24px 16px;
      border-radius: 15px;
      @include custom-hover-mixin;

      &__icon-container {
        margin: 0 auto 32px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 72px;
        height: 72px;
        border: 1px solid #25b6bf;
        border-radius: 100%;
      }
      &__content {
        font-family: Inter;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: center;
      }
    }
  }
}
.franchising-options {
  z-index: 2;
  margin-bottom: 160px;

  @media all and (max-width: $mobile-width) {
    margin-bottom: 60px;
  }

  &__title {
    margin-bottom: 24px;
    font-family: "Zona Pro";
    font-size: 36px;
    font-weight: 600;
    line-height: 40px;
    letter-spacing: 0em;
    text-align: center;
    color: #1d1d1d;
  }
  &__row {
    display: flex;
    gap: 32px;
    justify-content: space-between;

    @media all and (max-width: $mobile-width) {
      flex-direction: column;
    }

    &__box {
      flex: 1 1;
      min-width: 0;
      padding: 24px 16px;
      border-radius: 15px;
      border: 1px solid #25b6bf;
      @include custom-hover-mixin;

      &__title {
        margin-bottom: 24px;
        font-family: "Zona Pro";
        font-size: 24px;
        font-weight: 600;
        line-height: 29px;
        letter-spacing: 0em;
        text-align: left;
        color: #1d1d1d;
      }
      &__ul {
        padding-left: 14px;
        list-style-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOCIgaGVpZ2h0PSI4IiB2aWV3Qm94PSIwIDAgOCA4IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8Y2lyY2xlIGN4PSI0IiBjeT0iNCIgcj0iNCIgZmlsbD0iIzI1QjZCRiIvPgo8L3N2Zz4K");
        &__li {
          margin-bottom: 12px;
          padding-left: 4px;
          font-family: Inter;
          font-size: 16px;
          font-weight: 400;
          line-height: 18px;
          letter-spacing: 0em;
          text-align: left;
          color: #1d1d1d;
        }
      }
    }
  }
}
.franchising-finance {
  z-index: 2;
  margin-bottom: 160px;

  @media all and (max-width: $mobile-width) {
    margin-bottom: 60px;
  }

  &__title {
    margin-bottom: 24px;
    font-family: "Zona Pro";
    font-size: 36px;
    font-weight: 600;
    line-height: 40px;
    letter-spacing: 0em;
    text-align: center;
    color: #1d1d1d;
  }
  &__row {
    display: flex;
    gap: 32px;
    justify-content: space-between;
    border-radius: 15px;

    @media all and (max-width: $mobile-width) {
      flex-direction: column;
      align-items: center;
    }

    &__box {
      flex: 1 1;
      min-width: 0;
      padding: 60px 0;
      border-radius: 15px;
      background: #25b6bf;
      color: #fff;
      @include custom-hover-mixin;

      @media all and (max-width: $mobile-width) {
        width: 80%;
        padding: 20px;
      }

      &__title {
        margin-bottom: 12px;
        font-family: "Zona Pro";
        font-size: 36px;
        font-weight: 600;
        line-height: 40px;
        letter-spacing: 0em;
        text-align: center;
      }
      &__subtitle {
        font-family: Inter;
        font-size: 18px;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: center;
      }
      &--yellow {
        @extend .franchising-finance__row__box;
        color: #1d1d1d;
        background: #ffb800;
      }
    }
  }
}
.franchising-exclusive {
  z-index: 2;
  &__title {
    font-family: "Zona Pro";
    font-size: 36px;
    font-weight: 600;
    line-height: 40px;
    letter-spacing: 0em;
    text-align: center;
    margin-bottom: 42px;
  }
  &__big-circle {
    width: 352px;
    height: 352px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 25px solid #25b6bf;
    border-radius: 100%;
    margin: 0 auto 42px;
    &__title {
      font-family: "Zona Pro";
      font-size: 28px;
      font-weight: 600;
      line-height: 31px;
      letter-spacing: 0em;
      text-align: center;
      color: #25b6bf;
      margin-bottom: 12px;
    }
    &__subtitle {
      font-family: Inter;
      font-size: 18px;
      font-weight: 600;
      line-height: 20px;
      letter-spacing: 0em;
      text-align: center;
      color: #25b6bf;
      margin-bottom: 0;
    }
  }
  &__row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 36px;
    margin-bottom: 80px;

    @media all and (max-width: $mobile-width) {
    }

    &__col {
      flex: 1 0 calc(25% - 27px);
      @media all and (max-width: $mobile-width) {
        flex: auto;
      }
      &__title {
        font-family: "Zona Pro";
        font-size: 36px;
        font-weight: 600;
        line-height: 40px;
        letter-spacing: 0em;
        text-align: center;
        color: #25b6bf;
        margin-bottom: 12px;
      }
      &__description {
        font-family: Inter;
        font-size: 18px;
        font-weight: 500;
        line-height: 23px;
        letter-spacing: 0em;
        text-align: center;
        margin-bottom: 4px;
      }
    }
  }
}
</style>
