<template>
  <div class="tt-city__grid-item">
    <div class="tt-icon">
      <img src="@/assets/icons-new/geo_blue.svg">
    </div>
    <a class="tt-city__address" :href="address.link" target="_blank">
      {{ address.name_shop }}
    </a>
    <div class="tt-city__location">
      {{ address.location }}
    </div>
    <div class="tt-city__work-time">
      {{ address.work_time }}
    </div>
    <div class="tt-city__phone">
      {{ address.phone_number }}
    </div>
    <div class="tt-city__phone" v-if="address.phone_number_second">
      {{ address.phone_number_second }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    address: {
      type: Object,
      required: true
    }
  }
}
</script>

<style src="../../assets/styles-new/city.sass" lang="sass" scoped>
</style>