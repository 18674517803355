<template>
  <nav class="tt-nav-mobile">
    <div class="container">
      <ul class="tt-nav-mobile__routes ff-mont">
        <li
          class="tt-nav-mobile__link"
          :class="{
            'tt-nav-mobile__link--active': isActiveRoute(menuItem.url),
          }"
          v-for="(menuItem, key) in navLinks"
          :key="key"
          @click="navigateTo(menuItem.url)"
        >
          {{ $t(menuItem.title) }}
        </li>
      </ul>
      <CitySelector />
      <LanguageSelector />
      <div class="d-sm-block d-md-none">
        <div
          class="tt-nav-mobile__link"
          v-if="!loggedIn && shouldShowPage()"
          @click="login"
        >
          {{ $t("navbar_login_index_signIn") }}
        </div>
        <div
          class="tt-nav-mobile__link"
          v-if="loggedIn && shouldShowPage()"
          @click="navigateTo('/cabinet')"
        >
          {{ $t("navbar_profile_title") }}
        </div>
        <div
          class="tt-nav-mobile__link"
          v-if="loggedIn && shouldShowPage()"
          @click="logout"
        >
          {{ $t("navbar_profile_logout") }}
        </div>
      </div>

      <div class="tt-icon tt-nav-mobile__halal">
        <img src="@/assets/icons-new/halal.png" />
      </div>
    </div>
    <div class="tt-nav-mobile__background-image"></div>
  </nav>
</template>

<script>
import { mapGetters } from "vuex";
import CitySelector from "@/components/Header/CitySelector.vue";
import LanguageSelector from "@/components/Header/LanguageSelector.vue";

export default {
  data: () => ({
    navLinks: [
      {
        title: "navbar_menu",
        url: "/",
      },
      {
        title: "navbar_bonus",
        url: "/promotion",
      },
      {
        title: "navbar_aboutus",
        url: "/about",
      },
      {
        title: "navbar_contacts",
        url: "/contacts",
      },
      {
        title: "navbar_franchising",
        url: "/franchising",
      },
    ],
  }),
  computed: {
    ...mapGetters({
      loggedIn: "auth/loggedIn",
    }),
  },
  methods: {
    isActiveRoute(url) {
      if (url === "/") {
        return this.$route.path === url;
      }
      return this.$route.path.includes(url);
    },
    navigateTo(url) {
      if (this.$route.path !== url) {
        this.$router.push(url);
        this.$emit("navLinkClicked");
      }
    },
    login() {
      this.$eventBus.$emit("login");
      this.$emit("navLinkClicked");
    },
    logout() {
      this.$store
        .dispatch("auth/logout")
        .then(() => this.$emit("navLinkClicked"));
      if (this.$route.name !== "Home") {
        this.$router.push("/");
      }
    },
    shouldShowPage() {
      try {
        const city = JSON.parse(localStorage.getItem("city"));
        return city && city.name === "Астана";
      } catch (e) {
        console.error("Error reading from localStorage", e);
        return false;
      }
    },
  },
  components: {
    LanguageSelector,
    CitySelector,
  },
};
</script>

<style lang="sass" scoped>
@import "src/assets/styles-new/colors"
@import "src/assets/styles-new/typography"
@import "src/assets/styles-new/vars"

.container
  margin-top: 36px

.tt-nav-mobile
  position: fixed
  z-index: $zi-fixed
  top: 0
  left: 0
  width: 100%
  height: calc(100% - 60px)
  margin-top: 68px
  background-color: $white
  overflow: hidden
  &__routes
    margin: 0
    padding: 0
    list-style-type: none
  &__link
    margin-top: 20px
    font-size: $fs-large
    font-weight: $fw-semibold
    color: $dark-gray
    cursor: pointer
    &--active
      color: $main
  &__background-image
    z-index: -1
    position: absolute
    bottom: -500px
    right: -600px
    border: 250px solid rgba(143, 217, 221, 0.1)
    width: 1200px
    height: 1200px
    border-radius: 50%
  &__halal
    position: absolute
    left: 24px
    bottom: 40px

@media screen and (max-width: $mobile-width)
  .container
    margin-top: 24px
  .tt-nav-mobile
    &__background-image
      bottom: -400px
      right: -500px
      border: 150px solid rgba(143, 217, 221, 0.1)
      width: 800px
      height: 800px
    &__link
      margin-top: 14px
      font-size: $fs-semilarge

.tt-nav-mobile::v-deep
  .tt-city-select
    margin-top: 20px
    font-size: $fs-large
    color: $dark-gray
    .tt-icon
      filter: invert(76%) sepia(0%) saturate(3265%) hue-rotate(177deg) brightness(80%) contrast(85%)
  .tt-lang-select
    margin-top: 20px

  @media screen and (max-width: $mobile-width)
    .tt-city-select
      margin-top: 14px
      font-size: $fs-semilarge
    .tt-lang-select
      margin-top: 14px
</style>
