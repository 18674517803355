<template>
  <div class="tt-order-history">
    <div class="tt-order-history__header">
      <div>№</div>
      <div class="tt-order-history__product-names">
        {{ $t('OrderedProducts_product') }}
      </div>
      <div class="tt-order-history__product-count">
        {{ $t('OrderedProducts_count') }}
      </div>
      <div class="tt-order-history__order-date">
        {{ $t('Cabinet_Order_card_date') }}
      </div>
      <div class="tt-order-history__order-amount">
        {{ $t('OrderPage_cmpt_SideCheck_sum') }}
      </div>
      <div>{{ $t('Cabinet_Order_card_status') }}</div>
      <div></div>
    </div>

    <div class="tt-order-history__orders">
      <div class="tt-order-history__order"
           v-for="order in orderHistory"
           :key="order.id">
        <OrderHistoryDropdown>
          <template v-slot:labels>
            <div>{{ order.id }}</div>
            <div class="tt-order-history__product-names">
              {{ getProductNames(order.products) }}
            </div>
            <div class="tt-order-history__product-count">
              {{ order.products.length }}
            </div>
            <div class="tt-order-history__order-date">
              {{ order.date }}
            </div>
            <div class="tt-order-history__order-amount">
              {{ numberWithSpaces(order.amount) }} ₸
            </div>
            <div>{{ statusName(order.status) }}</div>
          </template>

          <template v-slot:content>
            <OrderCard :order-id="order.id" />
          </template>
        </OrderHistoryDropdown>
      </div>
    </div>
  </div>
</template>

<script>
import OrderHistoryDropdown from "@/components/Cabinet/OrderHistoryDropdown.vue";
import OrderCard from "@/components/Cabinet/OrderCard.vue";

export default {
  components: {OrderCard, OrderHistoryDropdown},
  props: ['orderHistory'],
  methods: {
    getProductNames(products) {
      return products.map(p => p.name).join(', ')
    },
    statusName(status) {
      switch (status) {
        case 'Cancelled': return this.$t('PersonalArea_views_status_cancelled');
        case 'Unconfirmed': return this.$t('PersonalArea_Order_statuses_unconfirmed');
        case 'WaitCooking': return this.$t('PersonalArea_Order_statuses_waiting');
        case 'ReadyForCooking': return this.$t('PersonalArea_Order_statuses_readyforcooking');
        case 'CookingStarted': return this.$t('PersonalArea_Order_statuses_waitcooking');
        case 'CookingCompleted': return this.$t('PersonalArea_Order_statuses_cookingcompleted');
        case 'Waiting': return this.$t('PersonalArea_Order_statuses_waiting');
        case 'OnWay': return this.$t('PersonalArea_Order_statuses_onway');
        case 'Delivered': return this.$t('PersonalArea_Order_statuses_delivered');
        case 'Served': return this.$t('PersonalArea_Order_statuses_closed');
        case 'New': {
          if (this.paymentExpired) {
            return this.$t('PersonalArea_has_not_been_paid_and_payment_url_expired');
          } else if (!this.payedAt) {
            return this.$t('PersonalArea_has_not_been_paid');
          } else if (this.payedAt){
            return this.$t('PersonalArea_Order_statuses_error');
          } else {
            return 'NEW';
          }
        }
        default: return 'PENDING';
      }
    }
  }
}
</script>

<style lang="sass" scoped>
@import "src/assets/styles-new/colors"
@import "src/assets/styles-new/vars"

.tt-order-history
  &__header
    display: grid
    grid-template-columns: 50px 1fr 80px 100px 100px 1fr 24px
    gap: 12px
    color: $dark-gray
    border-bottom: 1px solid $gray
    padding-bottom: 8px
    margin-bottom: 16px

@media screen and (max-width: $mobile-width)
  .tt-order-history
    &__header
      grid-template-columns: 50px 1fr 24px
    &__product-names, &__product-count, &__order-date, &__order-amount
      display: none
</style>