<template>
  <div class="tt-icon tt-out-of-stock-icon">
    <img src="@/assets/icons-new/out-of-stock.png" alt="out-of-stock">
    <div class="tt-tooltip">
      {{ $t('OrderPage_cmpt_OrderCart_error_samovivoz_product') }}
    </div>
  </div>
</template>

<script>
export default {
}
</script>

<style lang="sass" scoped>
@import "src/assets/styles-new/colors"
@import "src/assets/styles-new/vars"

.tt-out-of-stock-icon
  position: absolute
  top: -10px
  left: -10px
  cursor: pointer
  &:hover
    .tt-tooltip
      display: block
</style>