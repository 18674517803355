<template>
  <div class="tt-vacancies"
       v-if="vacancies.length">
    <div v-for="vacancy in vacancies"
         :key="vacancy.id">
      <VacancyDropdown :id="vacancy.id">
        <template v-slot:vacancy>
          {{ vacancy.caption }}
        </template>
        <template v-slot:city>
          {{ vacancy.city }}
        </template>
      </VacancyDropdown>
    </div>
  </div>
</template>

<script>
import VacancyDropdown from "@/components/Carrier/VacancyDropdown.vue";

export default {
  components: {VacancyDropdown},
  data: () => ({
    vacancies: []
  }),
  methods: {
    async getVacancies() {
      const city = JSON.parse(localStorage.getItem('city'));
      try {
        if (city) {
          const {data} = await this.$axios.get('/vacancy', {
            params: {
              city_id: city.id
            }
          });
          this.vacancies = data.vacancies;
        }
      } catch (e) {
        console.error(e);
      }
    }
  },
  created() {
    this.getVacancies();
  }
}
</script>

<style lang="sass" scoped>
.tt-vacancies
</style>