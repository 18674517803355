<template>
  <div class="tt-about-us-faq">
    <div class="tt-about-us-faq__title ff-mont">
      FAQ
    </div>
    <div class="tt-about-us-faq__questions"
         v-for="(question, index) in questions"
         :key="index">
      <Dropdown>
        <template v-slot:label>
          <div class="tt-about-us-faq__question">
            {{ $t(question.question) }}
          </div>
        </template>
        <template v-slot:content>
          <div class="tt-about-us-faq__answer"
               v-html="$t(question.answer)"></div>
        </template>
      </Dropdown>
    </div>
  </div>
</template>

<script>
import Dropdown from "@/components/shared/Dropdown.vue";

export default {
  data: () => ({
    questions: [
      {
        question: 'HelpPage_question_title01_01',
        answer: 'Help_Ans01_01',
      },
      {
        question: 'HelpPage_question01_02',
        answer: 'Help_Ans01_02',
      },
      {
        question: 'HelpPage_question01_03',
        answer: 'Help_Ans01_03',
      },
      {
        question: "HelpPage_question_title01_04",
        answer: "Help_Ans01_04",
      },
      {
        question: "HelpPage_question_title01_05",
        answer: "Help_Ans01_05",
      },
      {
        question: 'HelpPage_question01_06',
        answer: 'Help_Ans01_06',
      },
      {
        question: 'HelpPage_question01_07',
        answer: 'Help_Ans01_07',
      },
      {
        question: "HelpPage_question01_08",
        answer: "Help_Ans01_08",
      },
      {
        question: "HelpPage_question01_09",
        answer: "Help_Ans01_09",
      },
      {
        question: "HelpPage_question01_10",
        answer: "Help_Ans01_10",
      },
      {
        question: "HelpPage_question01_11",
        answer: "Help_Ans01_11",
      },
      {
        question: "HelpPage_question01_12",
        answer: "Help_Ans01_12",
      },
      {
        question: "HelpPage_question01_13",
        answer: "Help_Ans01_13",
      },
      {
        question: "HelpPage_question01_14",
        answer: "Help_Ans01_14",
      },
      {
        question: 'HelpPage_question01_15',
        answer: 'Help_Ans01_15',
      }
    ]
  }),
  components: {
    Dropdown
  }
}
</script>

<style lang="sass" scoped>
@import "src/assets/styles-new/typography"
@import "src/assets/styles-new/vars"

.tt-about-us-faq
  margin-top: 100px
  &__title
    font-size: $fs-larger
    font-weight: $fw-semibold
    margin-bottom: 48px


@media screen and (max-width: $tablet-width)
  .tt-about-us-faq
    margin-top: 60px
    &__title
      font-size: $fs-large
      margin-bottom: 40px

@media screen and (max-width: $mobile-width)
  .tt-about-us-faq
    margin-top: 40px
    &__title
      font-size: $fs-semilarge
      margin-bottom: 28px
    &__question
      font-size: 1rem
    &__answer
      font-size: $fs-small
</style>