import axios from 'axios';

const state = {
    carouselItems: [],
    loading: false,
};

const mutations = {
    setCarouselItems(state, payload) {
        state.carouselItems = payload;
    },
    setLoading(state, payload) {
        state.loading = payload;
    }
};

const actions = {
    async getCarouselItems({ commit }) {
        commit('setLoading', true);
        const city = JSON.parse(localStorage.getItem('city'));
        if (city) {
            try {
                const {data: slides} = await axios.get('/carousel', {
                    params: {
                        city_id: city.id
                    }
                });
                commit('setCarouselItems', slides);
                commit('setLoading', false);
            } catch (err) {
                console.error(err);
            }
        }
    }
};

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
