<template>
  <div class="tt-product-details">
    <Dropdown>
      <template v-slot:label>
        <div class="tt-product-details__dropdown-title">
          {{ $t('main_vpopup_nutr_value') }}
        </div>
      </template>

      <template v-slot:content>
        <div class="tt-product-details__grid">
          <div class="tt-product-details__detail">
            <span class="tt-product-details__label">
              {{ $t('main_vpopup_nutr_energy') }}:
            </span>
            <span class="tt-product-details__value">
              {{ product.energy.energy_amount }} {{ $t('product_energy_energy_ccal_amount')}}
            </span>
          </div>

          <div class="tt-product-details__detail">
            <span class="tt-product-details__label">
              {{ $t('main_vpopup_nutr_fat') }}:
            </span>
            <span class="tt-product-details__value">
              {{ product.energy.fat_amount }} {{ $t('product_energy_energy_zhir_amount')}}
            </span>
          </div>

          <div class="tt-product-details__detail">
            <span class="tt-product-details__label">
              {{ $t('main_vpopup_nutr_protein') }}:
            </span>
            <span class="tt-product-details__value">
              {{ product.energy.proteins_amount }} {{ $t('product_energy_energy_belki_amount')}}
            </span>
          </div>

          <div class="tt-product-details__detail">
            <span class="tt-product-details__label">
              {{ $t('main_vpopup_nutr_carbo') }}:
            </span>
            <span class="tt-product-details__value">
              {{ product.energy.carbohydrates_amount }} {{ $t('product_energy_energy_ugl_amount')}}
            </span>
          </div>

          <div class="tt-product-details__detail">
            <span class="tt-product-details__label">
              {{ $t('main_vpopup_nutr_weight') }}:
            </span>
            <span class="tt-product-details__value">
              {{ product.weight }} {{ $t('main_vpopup_nutr_weight_unit') }}
            </span>
          </div>

          <div  class="tt-product-details__detail"
                v-if="product.diameter">
            <span class="tt-product-details__label">
              {{ $t('main_vpopup_nutr_diameter') }}:
            </span>
            <span class="tt-product-details__value">
              {{ product.diameter }} {{ $t('product_energy_energy_diameter_amount')}}
            </span>
          </div>

          <div  class="tt-product-details__detail"
                v-if="product.height">
            <span class="tt-product-details__label">
              {{ $t('main_vpopup_nutr_height') }}:
            </span>
            <span class="tt-product-details__value">
              {{ product.height }} {{ $t('product_energy_energy_height_amount')}}
            </span>
          </div>

          <div  class="tt-product-details__detail"
                v-if="product.storage_period">
            <span class="tt-product-details__label">
              {{ $t('main_vpopup_nutr_time') }}:
            </span>
            <span class="tt-product-details__value">
              {{ product.storage_period }}
            </span>
          </div>

          <div  class="tt-product-details__detail"
                v-if="product.storage_conditions">
            <span class="tt-product-details__label">
              {{ $t('main_vpopup_nutr_term2time') }}:
            </span>
            <span class="tt-product-details__value"
                  v-html="product.storage_conditions"></span>
          </div>
        </div>
      </template>
    </Dropdown>
  </div>
</template>

<script>
import Dropdown from "@/components/shared/Dropdown.vue";

export default {
  components: {Dropdown},
  props: ['product']
}
</script>

<style lang="sass" scoped>
@import "src/assets/styles-new/typography"
@import "src/assets/styles-new/colors"
@import "src/assets/styles-new/vars"

.tt-product-details
  &__dropdown-title
    font-size: $fs-semilarge
  &__grid
    display: grid
    grid-template-columns: repeat(4, 1fr)
    gap: 24px
  &__label
    color: $dark-gray
    margin-right: 12px
  &__value
    font-weight: $fw-medium

@media screen and (max-width: $tablet-width)
  .tt-product-details
    &__dropdown-title
      font-size: $fs-medium
    &__grid
      grid-template-columns: repeat(3, 1fr)

@media screen and (max-width: $mobile-width)
  .tt-product-details
    &__grid
      grid-template-columns: 1fr
      gap: 8px
</style>