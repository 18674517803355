<template>
  <div class="tt-modal-container">
    <div class="tt-modal-container__modal"
         v-if="modalName === 'productModal' && modalProduct.id">
      <div class="tt-icon-button"
           @click="close">
        <img src="@/assets/icons-new/close_xl.svg">
      </div>
      <ProductModal :product="modalProduct" />
      <div class="tt-modal-container__modal-footer">
        <button class="tt-modal-container__details-button"
                @click="navigateToProductPage">
          {{ $t('modal-container_details') }}
        </button>
      </div>
    </div>

    <div class="tt-modal-container__backdrop"
         @click="close"></div>
  </div>
</template>

<script>

import {mapState} from "vuex";
import Spinner from "@/components/shared/Spinner.vue";
import ProductModal from "@/components/modal/ProductModal.vue";

export default {
  computed: {
    ...mapState({
      modalName: state => state.modalProduct.modalName,
      modalProduct: state => state.modalProduct.modalProduct
    })
  },
  methods: {
    close() {
      this.$store.dispatch("modalProduct/setModalName", null);
    },
    navigateToProductPage() {
      let modifiedName = this.modalProduct.name.replace(/\s+/g, '-');
      this.$router.replace({
        path: `/product/${modifiedName}`,
        query: { id: this.modalProduct.id }
      });
      this.$store.dispatch("modalProduct/setModalName", null);
    }
  },
  components: {
    ProductModal,
    Spinner,
  }
}
</script>

<style lang="sass" scoped>
@import "src/assets/styles-new/colors"
@import "src/assets/styles-new/typography"
@import "src/assets/styles-new/vars"

.tt-modal-container
  position: fixed
  z-index: $zi-fixed
  top: 0
  left: 0
  right: 0
  bottom: 0
  display: flex
  align-items: center
  justify-content: center
  &__modal
    position: relative
    z-index: $zi-modal
    background-color: $white
    border-radius: 8px
    overflow: hidden
    padding: 40px
    .tt-icon-button
      position: absolute
      top: 20px
      right: 20px
  &__modal-footer
    height: 67px
    background-color: #F9F9F9
    margin: 27px -40px -40px
    display: flex
    align-items: center
  &__details-button
    margin-left: 40px
    font-weight: $fw-medium
    color: $main
    border: none
    outline: none
    background-color: transparent
  &__backdrop
    position: absolute
    z-index: $zi-modal-backdrop
    width: 100%
    height: 100%
    background-color: rgba(0, 0, 0, 0.4)
</style>