<template>
  <div class="tt-carousel">
    <div class="tt-carousel__header">
      <div class="tt-carousel__title ff-mont">
        <slot name="title"></slot>
      </div>
      <div class="tt-carousel__buttons"
           v-if="isButtonsVisible">
        <div class="tt-icon-button"
             @click="slidePrev">
          <img src="@/assets/icons-new/prev.svg">
        </div>
        <div class="tt-icon-button"
             @click="slideNext">
          <img src="@/assets/icons-new/next.svg">
        </div>
      </div>
    </div>
    <VueSlickCarousel ref="carousel"
                      lazy-load="ondemand"
                      :arrows="false"
                      :infinite="false"
                      :slides-to-show="slidesToShow"
                      :rows="rows">
      <slot name="slides"></slot>
    </VueSlickCarousel>
  </div>
</template>

<script>
import {VueperSlide, VueperSlides} from "vueperslides";
import "vueperslides/dist/vueperslides.css";
export default {
  props: {
    rows: {
      type: Number,
      default: 1
    },
    slidesToShow: {
      type: Number,
      default: 3
    },
    slidesLength: {
      type: Number,
      requested: true
    }
  },
  computed: {
    isButtonsVisible() {
      return this.slidesLength > this.slidesToShow;
    }
  },
  methods: {
    slidePrev() {
      this.$refs.carousel.prev();
    },
    slideNext() {
      this.$refs.carousel.next();
    }
  },
  components: {
    VueperSlide,
    VueperSlides
  }
}
</script>

<style lang="sass" scoped>
@import "src/assets/styles-new/typography"

.tt-carousel
  &__header
    display: flex
    justify-content: space-between
    align-items: center
    margin-bottom: 20px
  &__title
    font-size: 1.375rem
    font-weight: $fw-medium
  &__buttons
    display: flex
    gap: 16px
    .tt-icon-button
      height: 36px
      &:hover
        filter: invert(59%) sepia(86%) saturate(419%) hue-rotate(135deg) brightness(89%) contrast(82%)
</style>