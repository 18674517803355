<template>
  <div class="tt-social">
    <span class="tt-social__link">
      <a href="https://www.instagram.com/taptatti/"
         target="_blank"
         rel="noreferrer">
        <i class="fab fa-instagram"></i>
      </a>
    </span>
    <span class="tt-social__link">
       <a href="https://api.whatsapp.com/send/?phone=77715556060&text=%D0%97%D0%B4%D1%80%D0%B0%D0%B2%D1%81%D1%82%D0%B2%D1%83%D0%B9%D1%82%D0%B5%21+%D0%AF+%D1%85%D0%BE%D1%87%D1%83+%D1%81%D0%B4%D0%B5%D0%BB%D0%B0%D1%82%D1%8C+%D0%B7%D0%B0%D0%BA%D0%B0%D0%B7%D0%B0%D1%82%D1%8C"
          target="_blank"
          rel="noreferrer">
         <i class="fab fa-whatsapp"></i>
       </a>
    </span>
    <span class="tt-social__link">
       <a href="https://t.me/taptatti_bot"
          target="_blank"
          rel="noreferrer">
       <i class="fab fa-telegram"></i>
       </a>
    </span>
    <span class="tt-social__link">
       <a href="https://www.facebook.com/taptattiastana/"
          target="_blank"
          rel="noreferrer">
      <i class="fab fa-facebook"></i>
       </a>
    </span>
    <span class="tt-social__link">
       <a href="https://www.youtube.com/channel/UCwovcdNkxuRBxnYAiOkbIAw?view_as=subscriber"
          target="_blank"
          rel="noreferrer">
         <i class="fab fa-youtube"></i>
       </a>
    </span>
  </div>
</template>

<script>
export default {
  name: "SocialLinks"
}
</script>

<style lang="sass" scoped>
@import "src/assets/styles-new/colors"

.tt-social
  display: flex
  gap: 1rem
  font-size: 20px
  &__link a
    color: black
    &:hover
      color: $main
</style>