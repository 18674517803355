import Vue from 'vue'
import VueRouter from 'vue-router'

import Home from '@/views/Home.vue'
import Product from "@/views/Product.vue";
import CreateOrder from '@/views/CreateOrder.vue'
import ConfirmOrder from '@/views/ConfirmOrder.vue'
import Promotion from '@/views/Promotion.vue'
import AboutUs from '@/views/AboutUs.vue'
import Carrier from '@/views/Carrier.vue'
import Contacts from '@/views/Contacts.vue'
import GiftCertificate from "@/views/GiftCertificate.vue";
import Cabinet from '@/views/Cabinet.vue'
import UserAgreement from "@/views/UserAgreement.vue";
import Policy from '@/views/Policy.vue'
import PublicOffer from "@/views/PublicOffer.vue";
import StopList from '@/views/StopList.vue'
import PageNotFound from '@/views/PageNotFound.vue'
import Franchise from "@/views/Franchise.vue";
import Franchising from "@/views/Franchising.vue";
import Rent from "@/views/Rent.vue";
import RentalOfPremises from "../views/RentalOfPremises";
import { loadLanguageAsync,i18n } from "@/i18n/i18n";
import LangRouter from 'vue-lang-router';


Vue.use(VueRouter);
Vue.use(LangRouter, {
    defaultLanguage: 'ru',
    languages: ['ru', 'kz'],
    detectBrowserLanguage: false,
});


const router = new VueRouter({
    scrollBehavior() {
        return { x: 0, y: 0 }
    },
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/:lang',
            name: 'Home',
            component: Home
        },
        {
            path: '/:lang/product/:modifiedName',
            name: 'Product',
            component: Product,
            props: (route) => ({ id: route.query.id, modifiedName: route.params.modifiedName })
        },
        {
            path: '/:lang/create-order',
            name: 'OrderBasket',
            component: CreateOrder,
        },
        {
            path: '/:lang/order/:orderId',
            name: 'ConfirmationOrder',
            component: ConfirmOrder
        },
        {
            path: '/:lang/promotion',
            name: 'Promotion',
            component: Promotion
        },
        {
            path: '/:lang/about',
            name: 'about',
            component: AboutUs,
        },
        {
            path: '/:lang/carrier',
            name: 'Carrier',
            component: Carrier
        },
        {
            path: '/:lang/franchising',
            name: 'Franchising',
            component: Franchising
        },
        {
            path: '/:lang/Contacts',
            name: 'Contacts',
            component: Contacts
        },
        {
            path: '/:lang/gift',
            name: 'Gift',
            component: GiftCertificate
        },
        {
            path: '/:lang/cabinet',
            name: 'Cabinet',
            component: Cabinet,
            meta: {
                auth: true,
            }
        },
        // {
        //     path: '/:lang/publicy_offer',
        //     name: 'UserAgreement',
        //     component: UserAgreement
        // },
        {
            path: '/:lang/Policy',
            name: 'Policy',
            component: Policy
        },
        {
            path: '/:lang/publicy_offer',
            name: 'Publicy_offer',
            component: PublicOffer
        },
        {
            path: '/:lang/franchise',
            name: 'Franchise',
            component: Franchise
        },
        {
            path: '/:lang/rent',
            name: 'Rent',
            component: Rent
        },
        {
            path: '/:lang/rental-of-premises',
            name: 'RentalOfPremises',
            component: RentalOfPremises
        },
        {
            path: '/:lang/stop_list',
            name: 'StopList',
            component: StopList
        },
        {
            path: '*',
            name: 'pageNotFound',
            component: PageNotFound
        }
    ]
})
router.beforeEach(async (to, from, next) => {
    initRouteConfig(to,from,next)
});
export async function initRouteConfig(to,from,next){
    if (to.matched.some(record => record.meta.auth)) {
        if (localStorage.getItem('token') == null) {
            next({
                path: '/',
                params: { nextUrl: to.fullPath }
            });
            return;
        }
    }

    const lang = i18n.locale || 'ru';

    if (to.path.startsWith(`/${lang}/`)) {
        next();
        return;
    } else if (to.path.includes('kz') || to.path.includes('ru')) {
        next();
        return;
    }

    await loadLanguageAsync(lang);
    router.app.$i18n.locale = lang;

    const newPath = `/${lang}${to.path}${to.fullPath !== to.path ? `?${to.fullPath.split('?')[1]}` : ''}`;
    next({ path: newPath, replace: true });
}


export default router

