<template>
  <div id="payment-selector"
       class="tt-payment">
    <div class="tt-payment__title ff-mont">
      {{ $t('OrderPage_cmpt_Payment_header') }}
    </div>
    <div class="tt-payment__list">
      <div class="tt-payment__item"
           :class="{'tt-payment__item--active': (selectedPaymentType || {}).id === paymentType.id}"
           v-for="(paymentType, i) in paymentTypes"
           :key="`payment-item-${i}`"
           @click="selectPaymentType(paymentType)">
        <div class="tt-icon">
          <img :src="paymentIcons[paymentType.type]" :alt="paymentType.name">
        </div>
        <div class="tt-payment__name">
          {{ paymentType.name }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  computed: {
    ...mapState({
      paymentTypes: state => state.paymentType.paymentTypes,
      selectedPaymentType: state => state.paymentType.selectedPaymentType
    }),
    paymentIcons() {
      return {
        cash: require('@/assets/icons-new/cash.svg'),
        epay: require('@/assets/icons-new/terminal.svg'),
        ecrd: require('@/assets/icons-new/credit-card.svg'),
        visa: require('@/assets/icons-new/terminal.svg'),
        kspi: require('@/assets/icons-new/kaspi.svg'),
        kpay: require('@/assets/icons-new/kaspi.svg'),
        pbox: require('@/assets/icons-new/credit-card.svg'),
      }
    },
  },
  methods: {
    selectPaymentType(paymentType) {
        this.$store.commit('paymentType/SET_SELECTED_PAYMENT_TYPE', paymentType);
    }
  },
  created() {
    this.$store.dispatch('paymentType/getPaymentTypes')
        .then(() => {
          if (this.paymentTypes[0]) this.selectPaymentType(this.paymentTypes[0]);
        });
  }
};
</script>

<style lang="sass" scoped>
@import "src/assets/styles-new/colors"
@import "src/assets/styles-new/typography"
@import "src/assets/styles-new/vars"

.tt-payment
  padding: 32px 0
  border-bottom: 2px solid $light-gray
  &__title
    font-size: $fs-semilarge
    font-weight: $fw-semibold
    margin-bottom: 32px
  &__list
    display: flex
    flex-wrap: wrap
    gap: 40px
  &__item
    min-height: 48px
    padding: 0 20px
    display: flex
    align-items: center
    gap: 8px
    border: 1px solid $dark-gray
    border-radius: 8px
    &:hover
      cursor: pointer
    &--active
      border-color: $main


@media screen and (max-width: $tablet-width)
  .tt-payment
    padding: 24px 0
    &__title
      margin-bottom: 24px
    &__list
      gap: 32px

@media screen and (max-width: $mobile-width)
  .tt-payment
    &__title
      margin-bottom: 16px
    &__list
      gap: 24px
</style>