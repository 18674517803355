import axios from "axios";
const state = {
    modalProduct: {},
    showRequestOrderModal: false,
    comboProducts: {}
};

const mutations = {
    SET_MODAL_PRODUCT(state, product) {
        state.modalProduct = product
    },
    SET_COMBO_PRODUCTS(state, combo) {
        state.defaultComboProducts = combo
    },
    SET_SHOW_REQUEST_ORDER_MODAL(state, name) {
        state.showRequestOrderModal = name
    },
};

const actions = {
    clearModalProduct({ commit }) {
        commit('SET_MODAL_PRODUCT', {});
    },
    setComboProducts({ commit }, products) {
        commit('SET_COMBO_PRODUCTS', products);
    },
    setModalProduct({ commit, dispatch }, product) {
        dispatch('clearModalProduct');
        axios.get(`product/${product.id}`)
            .then(response => {
                commit('SET_MODAL_PRODUCT', response.data);
                console.log(response.data)
            });
    },
    setShowRequestOrderModal({ commit, dispatch }, name) {
        dispatch('clearModalProduct');
        commit('SET_SHOW_REQUEST_ORDER_MODAL', name)
    },
};

// Экспортируем наружу
export default {
    namespaced: true,
    state,
    mutations,
    actions
}
