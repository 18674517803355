<template>
  <div class="tt-certificates">
    <div class="tt-certificates__flex">
      <div>
        <div class="tt-certificates__title ff-mont">
          {{ $t('About_us_Certificates_title') }}
        </div>
        <div class="tt-certificates__subtitle">
          {{ $t('About_us_Certificates_subtitle') }}
        </div>
      </div>

      <div class="tt-certificates__image w-100">
        <img src="@/assets/images-new/certificate-1.png">
        <img src="@/assets/images-new/certificate-2.png">
      </div>
    </div>
  </div>
</template>

<script>

export default {
}
</script>

<style lang="sass" scoped>
@import "src/assets/styles-new/typography"
@import "src/assets/styles-new/vars"
@import "src/assets/styles-new/colors"

.tt-certificates
  padding-top: 60px
  padding-bottom: 70px
  border-bottom: 2px solid $gray
  &__flex
    display: flex
    gap: 150px
  &__title
    font-size: $fs-larger
    line-height: 1.2em
    font-weight: $fw-semibold
    margin-bottom: 24px
  &__subtitle
    font-size: $fs-medium
    line-height: 1.25em
  &__image
    min-width: 321px
    img
      width: 100%

@media screen and (max-width: $tablet-width)
  .tt-certificates
    padding: 40px 0
    &__flex
      gap: 70px
    &__title
      font-size: $fs-large
    &__image
      min-width: 284px

@media screen and (max-width: $mobile-width)
  .tt-certificates
    padding: 32px 0
    &__flex
      flex-direction: column
      gap: 32px
    &__title
      font-size: $fs-semilarge
      margin-bottom: 16px
    &__subtitle
      font-size: $fs-small
</style>