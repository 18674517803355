<template>
  <div>
    <div class="tt-city-select" @click="openCityModal">
      <div class="tt-icon">
        <img src="@/assets/icons-new/geo.svg">
      </div>
      <div class="tt-city-select__name ff-mont tt-city-select__active">
        {{ currentCity ? currentCity.name : 'Выберите город' }}
      </div>
    </div>
  </div>
</template>

<script>

export default {
  data: () => ({
    currentCity: JSON.parse(localStorage.getItem('city')) || null,
  }),
  methods: {
    openCityModal() {
        this.$store.dispatch('cities/setShowModal', true);
    }
  },
};
</script>

<style lang="sass" scoped>
@import "src/assets/styles-new/colors"
@import "src/assets/styles-new/typography"

.tt-city-select
  display: flex
  align-items: center
  &__name
    font-weight: 600
    margin-left: 8px
    cursor: pointer
  &__active
    color: $main
</style>
