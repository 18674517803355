<template>
  <div id="app" :key="appKey">
    <div class="header-underlay"></div>
    <div class="app-content container">
      <Header v-if="isVisible" />
      <router-view />
      <transition v-if="isVisible" name="fade-away">
        <div>
          <CityModal v-if="showModal" />
          <ModalContainer v-if="modalName" />
          <ProductOfTheDay v-if="canShowProductOfTheDay" />
          <RequestModal v-if="showRequestOrderModal" />
        </div>
      </transition>
    </div>
    <Footer v-if="isVisible" />
  </div>
</template>

<script>
import Header from "@/layout/Header";
import Footer from "@/layout/Footer";
import { loadLanguageAsync } from "@/i18n/i18n";
import ProductOfTheDay from "@/components/modal/ProductOfTheDay.vue";
import ModalContainer from "@/components/modal/ModalContainer.vue";
import RequestModal from "@/components/modal/RequestModal.vue";
import CityModal from "@/components/modal/CityModal.vue";

export default {
  metaInfo() {
    return {
      link: [
        {
          rel: "hreflang",
          href: this.getHrefLang(),
        },
      ],
    };
  },
  data: () => ({
    appKey: 0,
  }),
  computed: {
    modalName() {
      return this.$store.state.modalProduct.modalName;
    },
    showRequestOrderModal() {
      return this.$store.state.modalRequestOrder.showRequestOrderModal;
    },
    canShowProductOfTheDay() {
      return this.$store.getters["productOfTheDay/canShowProductOfTheDay"];
    },
    isVisible() {
      return this.$route.path !== "/stop_list";
    },
    showModal() {
      return this.$store.state.cities.showModal;
    },
  },
  methods: {
    getHrefLang() {
      return this.$i18n.locale || "ru";
    },
    forceRerender() {
      this.appKey++;
      this.loadData();
    },
    loadData() {
      this.$store.dispatch("auth/fetchUser");
      if (!this.$store.state.auth.token) {
        this.$store.dispatch("cart/getCart");
      }
    },
  },
  async created() {
    // OPT: move to langSelector
    this.$watch(
      "$i18n.locale",
      (newLocale, oldLocale) => {
        if (newLocale === oldLocale) {
          return;
        }
        loadLanguageAsync(newLocale).then(() => {
          if (newLocale && oldLocale) {
            this.forceRerender();
          }
        });
      },
      { immediate: true }
    );
    await this.$store.dispatch("cities/loadCity");
    this.loadData();
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === "auth/SET_USER") {
        this.$store.commit("cart/RESET_TOKEN");
        this.$store.dispatch("cart/getCart");
      }
      if (mutation.type === "auth/CLEAR_USER") {
        this.$store.commit("cart/RESET_CARD");
      }
      if (
        mutation.type === "cart/SET_CART_ID" &&
        this.$route.path.includes("/create-order")
      ) {
        console.log(this.$route.path);
        this.$store.dispatch("stopList/getCartStopList");
      }
      if (
        mutation.type === "auth/SET_USER" &&
        this.$route.path === "/cabinet"
      ) {
        this.$store.dispatch("order/getHistoryOrders");
      }
      if (mutation.type === "auth/CLEAR_USER" && this.$route.meta.auth) {
        this.$router.push("/");
      }
    });
  },
  beforeDestroy() {
    this.unsubscribe();
  },
  components: {
    ModalContainer,
    RequestModal,
    ProductOfTheDay,
    Header,
    Footer,
    CityModal,
  },
};
</script>

<style lang="sass">
@import "assets/styles-new/global"
</style>
