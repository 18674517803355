import axios from "axios";

const state = {
    reviews: [],
    modalReview: {},
    perPage: 3,
    page: 1,

};

const getters = {
    getModalReview() {
        return state.modalReview;
    }
};

const mutations = {
    SET_REVIEW(state, review) {
        // if (!state.reviews[review.id]) {
        //     state.reviews[review.id] = review;
        // }
        state.modalReview = review;
    }
};

const actions = {
    addReview(context, review) {
        return axios.post('review', review);
    },
    getReview(context, id) {
        if (context.state.reviews[id]) {
            context.commit('SET_REVIEW', context.state.reviews[id]);
        }
        axios.get("review/" + id)
            .then(response => {
                context.commit('SET_REVIEW', response.data);

                return response.data;
            });
    },
    async getReviewList({state}, id) {
        try {
            const params = {
                product_id: id,
                limit: 20,
                // limit: state.perPage,
                // skip: (state.page - 1)*state.perPage,
            };
            const reviews = await axios.get('review', {params: params});
            state.reviews = reviews.data.reviews;
        } catch (e) {
            console.error(e)
        }

    }
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
