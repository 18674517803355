<template>
  <div class="tt-vacancy-dropdown">
    <div class="tt-vacancy-dropdown__container ff-mont">
      <div>
        <slot name="vacancy"></slot>
      </div>
      <div class="tt-vacancy-dropdown__city">
        <slot name="city"></slot>
      </div>
      <div class="tt-icon-button"
           :class="{ 'tt-icon-button--reversed' : isDropped }"
           @click="toggleDropdown">
        <img src="@/assets/icons-new/chevron_down.svg">
      </div>
    </div>

    <transition name="slide-down">
      <div class="tt-vacancy-dropdown__content"
           v-if="isDropped">
        <div v-html="vacancyInfo.description"></div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  props: ['id'],
  data: () => ({
    isDropped: false,
    vacancyInfo: null
  }),
  methods: {
    toggleDropdown() {
      this.isDropped = !this.isDropped;
    },
    async getVacancyInfo() {
      try {
        const { data } = await this.$axios.get(`/vacancy/${this.id}`);
        this.vacancyInfo = data;
      } catch (e) {
        console.error(e);
      }
    }
  },
  mounted() {
    this.getVacancyInfo();
  }
}
</script>

<style lang="sass" scoped>
@import "src/assets/styles-new/typography"
@import "src/assets/styles-new/colors"
@import "src/assets/styles-new/vars"

.tt-vacancy-dropdown
  width: 100%
  margin-bottom: 16px
  &__container
    display: grid
    grid-template-columns: 1fr 1fr 60px
    align-items: center
    font-weight: $fw-semibold
    font-size: $fs-medium
    border-bottom: 1px solid $gray
    padding-bottom: 12px
  &__content
    margin-top: 24px
    margin-bottom: 48px
  .tt-icon-button
    justify-self: end
    height: 24px
    &:hover
      filter: invert(59%) sepia(86%) saturate(419%) hue-rotate(135deg) brightness(89%) contrast(82%)
    &--reversed
      transform: rotate(180deg)


@media screen and (max-width: $tablet-width)
  .tt-vacancy-dropdown
    &__container
      grid-template-columns: 1.5fr 1fr 60px

@media screen and (max-width: $mobile-width)
  .tt-vacancy-dropdown
    &__container
      grid-template-columns: 1fr 60px
    &__city
      grid-row-start: 2
      font-size: 1rem
      font-weight: 400
</style>