<template>
  <nav>
    <ul class="tt-footer-nav">
      <li v-for="(menuItem, key) in navLinks"
          :key="key"
          v-if="shouldShowMenuItem(menuItem)"
      >
        <router-link class="tt-footer-nav__link ff-mont"
                     :to="menuItem.url">
          {{ $t(menuItem.title) }}
        </router-link>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  data: () => ({
    navLinks: [
      {
        title: "navbar_menu" ,
        url: '/',
      },
      {
        title: "navbar_bonus",
        url: '/promotion',
        restrictToCity: 'Астана'
      },
      {
        title: "navbar_aboutus",
        url: '/about'
      },
      {
        title: "navbar_contacts",
        url: '/contacts'
      },
      {
        title: "Footer_careers",
        url: '/carrier'
      },
      {
        title: 'navbar_gift_certificate',
        url: '/gift',
        restrictToCity: 'Астана'
      },
      {
        title: 'navbar_franchise',
        url: '/franchising'
      },
      {
        title: 'navbar_rent',
        url: '/rent'
      }
    ]
  }),
  methods: {
    shouldShowMenuItem(menuItem) {
      if (!menuItem.restrictToCity) {
        return true;
      }

      const city = JSON.parse(localStorage.getItem('city'));
      return city && city.name === menuItem.restrictToCity;
    }
  }
}
</script>

<style lang="sass" scoped>
@import "src/assets/styles-new/colors"
@import "src/assets/styles-new/typography"
@import "src/assets/styles-new/vars"

.tt-footer-nav
  margin: 0
  padding: 0
  display: flex
  flex-wrap: wrap
  gap: 32px
  list-style-type: none
  font-weight: $fw-semibold
  &__link
    color: $black
    text-decoration: none
    white-space: nowrap
    &:hover
      cursor: pointer
      color: $main

@media screen and (max-width: $tablet-width)
  .tt-footer-nav
    display: grid
    grid-template-columns: 1fr 1fr
    row-gap: 20px
</style>