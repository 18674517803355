<template>
  <div class="tt-burger-button"
       :class="{ 'tt-burger-button--active': active }"
       @click="buttonClicked">
    <span></span>
  </div>
</template>

<script>
export default {
  props: {
    active: false
  },
  methods: {
    buttonClicked() {
      this.$emit('clicked');
    }
  }
}
</script>

<style lang="sass" scoped>
.tt-burger-button
  height: 22px
  width: 22px
  display: flex
  align-items: center
  justify-content: center
  span
    margin-top: 3px
    width: 100%
    height: 3px
    border-radius: 2px
    background-color: #1D1D1D
    display: block
    position: relative
    &::after
      content: ''
      position: absolute
      height: 3px
      width: 100%
      border-radius: 2px
      background-color: #1D1D1D
      top: 6px
      transition: all .2s ease
    &::before
      content: ''
      position: absolute
      width: 100%
      height: 3px
      border-radius: 2px
      background-color: #1D1D1D
      top: -6px
      transition: all .2s ease
  &--active
    span
      background-color: transparent
      &::after
        content: ''
        top: 0
        transform: rotate(45deg)
      &::before
        content: ''
        top: 0
        transform: rotate(-45deg)
</style>