<template>
  <div class="tt-product">
    <button class="tt-product__back-button"
            @click="navigateBack">
      <i class="fas fa-chevron-left"></i>
      {{ $t('main_vpopup_btn2back') }}
    </button>
    <div v-if="product">
      <ProductInfo :product="product" @sizeChange="onSizeChange"/>
      <ProductDetails :product="product"/>
      <ProductStock :stock="product.stop_list"/>
    </div>

    <div class="tt-product__spinner-wrapper"
         v-else>
      <Spinner/>
    </div>
  </div>
</template>

<script>
import Spinner from "@/components/shared/Spinner.vue";
import ProductInfo from "@/components/Product/ProductInfo.vue";
import ProductDetails from "@/components/Product/ProductDetails.vue";
import ProductStock from "@/components/Product/ProductStock.vue";

export default {
  metaInfo() {
    return {
      title: this.getTitle(),
      link: [
        {
          rel: 'canonical',
          href: 'https://taptatti.kz/product/' + this.getId()
        }
      ]
    }
  },

  data: () => ({
    product: null
  }),
  methods: {
    navigateBack() {
      this.$router.push({path:'/'});
    },
    getTitle() {
      return this.product ? this.product.name : 'Loading...';
    },
    getId(){
      return this.product ? this.product.id : 0
    },
    onSizeChange(id) {
      console.log('qq22', id)
      this.$store.dispatch('products/getProductById', id)
        .then(resp => this.product = resp);
    }
  },
  mounted() {
    const id = this.$route.query.id || this.$route.params.modifiedName;
    this.$store.dispatch('products/getProductById', id)
        .then(resp => this.product = resp);
  },
  components: {
    ProductStock,
    ProductDetails,
    ProductInfo,
    Spinner
  }
}
</script>

<style src="../assets/styles-new/product.sass" lang="sass" scoped>
</style>