<template>
<div class="tt-page-not-found">
  <img src="@/assets/images-new/404.svg" alt="">
  <h3 class="ff-mont">{{ $t('PageNotFound_views_header_title') }}</h3>
  <router-link to="/">
    <button class="tt-button tt-button--outlined">
      {{ $t('PageNotFound_views_btn2main') }}
    </button>
  </router-link>
</div>
</template>

<script>
export default {
}
</script>

<style lang="sass" scoped>
.tt-page-not-found
  max-width: 400px
  margin: 40px auto
  text-align: center
  .tt-button
    margin-top: 24px
    width: 300px
</style>