<template>
  <div class="tt-card">
    <div class="tt-card__image d-none d-lg-block"
         @click="showModal">
      <ImageLazy :img-src="selectedProduct.image"
                 :alt-text="selectedProduct.name" />
      <NextDayIcon v-if="selectedProduct.next_day" />
    </div>
    <div class="tt-card__image d-lg-none"
         @click="navigateToProductPage">
      <ImageLazy :img-src="selectedProduct.image"
                 :alt-text="selectedProduct.name" />
      <NextDayIcon v-if="selectedProduct.next_day" />
    </div>
    <div class="tt-card__info">
      <div class="tt-card__name">
        {{ selectedProduct.name }}
      </div>
      <div class="tt-card__price">
        <div v-if="selectedProduct.combos">
          <s>{{ getComboSum(selectedProduct.combos) }} ₸</s>
          {{ getComboSum(selectedProduct.combos) - selectedProduct.combos.different_price }} ₸
        </div>
        <div v-else>{{ selectedProduct.price }} ₸</div>
      </div>
    </div>
    <div v-if="multiple" class="tt-card__sizes">
      <div
        v-for="item in product"
        :key="item.id"
        :class="{'active': item.id === selectedProduct.id}"
        class="tt-card__tab"
        @click="changeSize(item.id)"
      >
        {{ item.weight }}
      </div>
    </div>
    <button class="tt-button tt-button--primary"
            v-if="showRequestModalButton"
            @click="openRequestModal"
    >
      {{ $t(buttonName) }}
    </button>
    <button class="tt-button tt-button--primary"
            v-else
            :disabled="isButtonDisabled"
            @click="addToCart">
        {{ $t(buttonName) }}
    </button>
  </div>
</template>

<script>
import ImageLazy from "@/components/shared/ImageLazy.vue";
import {cartMixin} from "@/mixins/cartMixin";
import NextDayIcon from "@/components/shared/NextDayIcon.vue";

export default {
  props: {
    product: null,
    multiple: false
  },
  data: () => ({
    city: JSON.parse(localStorage.getItem('city')) || {},
    selectedIndex: 0
  }),
  mixins: [cartMixin],
  computed: {
    isButtonDisabled() {
      return !!this.selectedProduct.is_onlyview;
    },
    buttonName() {
      switch (true) {
        case !!this.selectedProduct.is_onlyview:
          return 'home_list_btn_only_view_product';
        case !!this.selectedProduct.is_special:
          return 'home_list_btn_special_product';
        case !!this.selectedProduct.is_request:
          return 'home_list_btn_request_product';
        case !!this.selectedProduct.next_day:
          return 'home_list_btn_next_day_product';
        default:
          return 'home_list_btn_add2cart';
      }
    },
    showRequestModalButton() {
      return (this.city.name === 'Астана' && this.selectedProduct.is_request) || this.city.name !== 'Астана';
    },
    selectedProduct() {
      return this.multiple ? this.product[this.selectedIndex] : this.product
    }
  },
  methods: {
    addToCart() {
      if (!this.canAddProductToCart(this.selectedProduct)) {
        return;
      }
      this.$store.dispatch("cart/addProduct", this.selectedProduct)
          .then(response => {
            this.$toast.open({
              position: 'top-right',
              type: 'success',
              message: 'Ваш продукт ' + this.selectedProduct.name + ' добавлен в корзину!',
              duration: 3000,
            });
          });
    },
    showModal() {
      this.$store.dispatch("modalProduct/setModalProduct", this.selectedProduct);
      this.$store.dispatch("modalProduct/setModalName", "productModal");
    },
    navigateToProductPage() {
      this.$router.push(`/product/${this.selectedProduct.id}`);
    },
    // openFilialModal() {
    //   this.$store.dispatch("modalFilial/setModalProduct", this.product);
    //   this.$store.dispatch("modalFilial/setShowFilialModal", true);
    // },
    openRequestModal() {
      console.log("open request order modal?")
      this.$store.dispatch("modalRequestOrder/setModalProduct", this.selectedProduct);
      this.$store.dispatch('modalRequestOrder/setShowRequestOrderModal', true);

      // таски по тап-татты
      // сюда нужно добавить форму для отправки адреса
      // сюда также нужно добавить чтобы можно было добавлять выбранный продукт + и -

    },
    getComboSum(combo) {
      let price = 0;
      for (let i = 0; i < combo.products.length; i++ ) {
        if (combo.products[i].pivot.is_default) {
          price += combo.products[i].price;
        }
      }
      return price;
    },
    changeSize(id) {
      this.selectedIndex = this.product.findIndex(item => item.id === id)
    }
  },
  components: {
    NextDayIcon,
    ImageLazy
  }
}
</script>

<style lang="sass" scoped>
@import "src/assets/styles-new/typography"
@import "src/assets/styles-new/colors"
@import "src/assets/styles-new/vars"

.tt-card
  position: absolute
  top: 0
  left: 0
  background-color: $white
  display: flex
  flex-direction: column
  align-items: center
  &__image
    width: 256px
    height: 256px
    margin-bottom: 12px
  &__info
    width: 100%
    display: flex
    justify-content: space-between
  &__name
    font-weight: $fw-medium
    line-height: 1.375rem
    min-height: 2.75rem
  &__price
    margin-left: 16px
    font-weight: $fw-semibold
    font-size: $fs-medium
    white-space: nowrap
  &__sizes
    display: none
    background: #F8F8F8
    align-items: center
    width: 100%
    border-radius: 6px
  &__tab
    flex: 1
    display: flex
    justify-content: center
    padding: 5px 0
    user-select: none
    &.active
      background-color: $main
      border-radius: 6px
      color: #ffffff

  .tt-button
    margin-top: 16px

@media screen and (min-width: 992px)
  .tt-card
    .tt-button
      display: none
    &:hover
      padding: 16px
      border: 1px solid $main
      border-radius: 8px
      cursor: pointer
      z-index: $zi-dropdown
      .tt-card__image
        width: 224px
        height: 224px
      .tt-card__sizes
        display: flex
      .tt-button
        display: block

@media screen and (max-width: $tablet-width)
  .tt-card
    &__image
      height: 216px
      width: 216px
    &__name, &__price
      font-size: $fs-small
      line-height: 1rem
    .tt-button
      height: 38px
      font-size: $fs-small
      line-height: 1rem

@media screen and (max-width: $mobile-width)
  .tt-card
    &__image
      height: 164px
      width: 164px
    .tt-button
      height: 32px


.tt-card::v-deep
  .tt-next-day-icon
    top: 10px
    left: 10px
</style>