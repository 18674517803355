<template>
  <div class="tt-product-stock">
    <Dropdown>
      <template v-slot:label>
        <div class="tt-product-stock__dropdown-title">
          {{ $t('have_shops') }}
        </div>
      </template>
      <template v-slot:content>
        <div class="tt-product-stock__grid">
          <div v-for="item in stock"
               class="tt-product-stock__item">
            <div>
              <div class="tt-product-stock__address">
                {{ item.address }}
              </div>
              <div class="tt-product-stock__name">
                {{ item.name }}
              </div>
            </div>

            <div class="tt-product-stock__amount">
              {{ item.amount }} {{ $t('product_measure_unit_piece') }}
            </div>
          </div>
        </div>
      </template>
    </Dropdown>
  </div>
</template>

<script>
import Dropdown from "@/components/shared/Dropdown.vue";

export default {
  props: ['stock'],
  components: {
    Dropdown
  }
}
</script>

<style lang="sass" scoped>
@import "src/assets/styles-new/typography"
@import "src/assets/styles-new/colors"
@import "src/assets/styles-new/vars"

.tt-product-stock
  &__dropdown-title
    font-size: $fs-semilarge
  &__grid
    display: grid
    grid-template-columns: 1fr 1fr
    column-gap: 128px
    row-gap: 32px
  &__item
    display: flex
    justify-content: space-between
    max-width: 448px
  &__address, &__amount
    font-weight: $fw-medium
  &__name
    color: $dark-gray

@media screen and (max-width: $tablet-width)
  .tt-product-stock
    &__dropdown-title
      font-size: $fs-medium
    &__item
      max-width: 344px

@media screen and (max-width: $mobile-width)
  .tt-product-stock
    &__grid
      grid-template-columns: 1fr
      gap: 8px
    &__item
      max-width: 100%
</style>