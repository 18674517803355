import axios from "axios";
const state = {
    modalName: null,
    modalProduct: {},
    defaultComboProducts: {}
};


const mutations = {
    SET_MODAL_NAME(state, name) {
        state.modalName = name
    },
    SET_MODAL_PRODUCT(state, product) {
        state.modalProduct = product
    },
    SET_DEFAULT_COMBO_PRODUCTS(state, combo) {
        state.defaultComboProducts = combo
    },
};

const actions = {
    setModalName({ commit }, name) {
        commit('SET_MODAL_NAME', name)
    },
    setModalProduct({ commit, dispatch }, product) {
        dispatch('clearModalProduct');
        axios.get(`product/${product.id}`)
          .then(response => {
            commit('SET_MODAL_PRODUCT', response.data);
            let product = response.data
            let comboProducts = product.combo.filter((productCombo) => productCombo.pivot.is_default===1)
            commit('SET_DEFAULT_COMBO_PRODUCTS', comboProducts);
          });
    },
    clearModalProduct({ commit }) {
        commit('SET_MODAL_PRODUCT', {});
    }
};

// Экспортируем наружу
export default {
    namespaced: true,
    state,
    mutations,
    actions
}
