<template>
  <div class="tt-cabinet">
    <div class="tt-cabinet__title ff-mont">
      {{ $t('navbar_profile_title') }}
    </div>

    <UserInfo />

    <div class="tt-cabinet__tabs ff-mont">
      <div class="tt-cabinet__tab"
           @click="toggleActiveTab('current')"
           :class="{ 'tt-cabinet__tab--active': activeTab === 'current' }">
        {{ $t('Cabinet_current_order') }}
      </div>
      <div class="tt-cabinet__tab"
           @click="toggleActiveTab('history')"
           :class="{ 'tt-cabinet__tab--active': activeTab === 'history' }">
        {{ $t('Cabinet_order_history') }}
      </div>
    </div>

    <OrderCard :order-id="currentOrder.id"
               v-if="(activeTab === 'current') && currentOrder.id" />

    <OrderHistory :order-history="orderHistory"
                  v-if="(activeTab === 'history') && orderHistory.length" />
  </div>
</template>

<script>
import { mapState } from "vuex";
import UserInfo from "@/components/Cabinet/UserInfo.vue";
import OrderCard from "@/components/Cabinet/OrderCard.vue";
import Spinner from "@/components/shared/Spinner.vue";
import OrderHistory from "@/components/Cabinet/OrderHistory.vue";

export default {
  data: () => ({
    activeTab: 'current'
  }),
  computed: {
    ...mapState({
      currentOrder: state => state.order.order,
      orderHistory: state => state.order.history,
      loading: state => state.order.loading
    })
  },
  methods: {
    toggleActiveTab(tab) {
      if (this.activeTab !== tab) {
        this.activeTab = tab;
      }
    }
  },
  created() {
    if (this.$store.getters["auth/loggedIn"]){
      this.$store.dispatch('order/getHistoryOrders');
    }
  },
  beforeDestroy() {
    this.$store.commit('order/SET_ORDER_HISTORY', {})
  },
  components: {
    OrderHistory,
    Spinner,
    OrderCard,
    UserInfo,
  },
  metaInfo() {
    return {
      title: this.$t('PersonalArea_views_meta_title'),
      meta: [{
        vmid: 'description',
        property: 'description',
        content: this.$t('PersonalArea_views_meta_description')
      }, {
        vmid: 'og:title',
        property: 'og:title',
        content: this.$t('PersonalArea_views_meta_og_title')
      }, {
        vmid: 'og:description',
        property: 'og:description',
        content: this.$t('PersonalArea_views_meta_og_description')
      },],
    }
  },
};
</script>

<style lang="sass" scoped>
@import "src/assets/styles-new/typography"
@import "src/assets/styles-new/vars"
@import "src/assets/styles-new/colors"

.tt-cabinet
  padding: 40px 0 100px
  &__title
    font-size: $fs-larger
    line-height: 1.2em
    font-weight: $fw-semibold
    margin-bottom: 48px
  &__tabs
    border-bottom: 1px solid $light-gray
    display: flex
    gap: 64px
    font-size: $fs-medium
    font-weight: $fw-semibold
    color: $dark-gray
    margin-bottom: 40px
  &__tab
    cursor: pointer
    padding: 10px
    &--active
      color: $main
      padding-bottom: 8px
      border-bottom: 2px solid $main

@media screen and (max-width: $tablet-width)
  .tt-cabinet
    padding: 40px 0
    &__title
      font-size: $fs-large

@media screen and (max-width: $mobile-width)
  .tt-cabinet
    padding-top: 16px
    &__title
      font-size: $fs-semilarge
      margin-bottom: 32px
    &__tabs
      gap: 0
      justify-content: space-between
      font-size: 1rem
      margin-bottom: 24px
</style>
