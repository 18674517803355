<template>
  <div class="code-validator">
    <div class="code-validator__text"
         v-if="user.phone">
      {{ $t('navbar_CodeValidator_userPhone_01') }}
      <br />
      {{user.phone}}
      {{ $t('navbar_CodeValidator_userPhone_02') }}
      {{ $t('navbar_CodeValidator_userPhone_03') }}
    </div>

    <CodeInput class="code-validator__code"
               :loading="loading"
               :fieldWidth="32"
               :fieldHeight="40"
               @change="onChange" />

    <span class="code-validator__timer"
         v-if="timer > 0">
      Повторно отправить код {{ timer }}
    </span>
    <span class="code-validator__resend"
       @click="resendCode"
       v-else>
      Отправить код заново
    </span>
  </div>
</template>

<script>
import CodeInput from "vue-verification-code-input";

export default {
  props: ["user", "loading"],
  data: () => ({
    timeToResend: 60,
    timeIntervalId: null,
    timer: 0,
  }),
  methods: {
    resetForm() {
      this.stopTimer();
      this.startTimer();
    },
    resendCode() {
      this.$store.dispatch("auth/getActivationCode", {
        phone: this.user.phone.replace(/\D+/g, "")
      }).then(() => {
        this.resetForm();
      });
    },
    onChange(code) {
      this.$emit("onComplete", code);
    },
    startTimer() {
      this.timer = this.timeToResend;
      return setInterval(() => {
        this.timer--;
        if (this.timer < 0) {
          this.stopTimer();
        }
      }, 1000);
    },
    stopTimer() {
      clearInterval(this.timeIntervalId);
    },
  },
  mounted() {
    this.timeIntervalId = this.startTimer();
  },
  beforeDestroy() {
    this.stopTimer();
  },
  components: {
    CodeInput
  }
};
</script>

<style lang="sass" scoped>
@import "src/assets/styles-new/typography"
@import "src/assets/styles-new/colors"
@import "src/assets/styles-new/vars"
.code-validator
  font-size: $fs-small
  &__text
    margin-bottom: 24px
  &__code::v-deep
    width: 100% !important
    max-width: 250px
    .react-code-input
      width: 100%
      display: flex
      justify-content: space-between
      input
        border: 1px solid $dark-gray !important
        border-radius: 8px
        &:focus
          border: 1px solid $main !important
  &__timer, &__resend
    font-size: 12px
  &__resend
    cursor: pointer
    &:hover
      color: $main

@media screen and (max-width: $mobile-width)
  .code-validator
    &__code
      margin: auto
</style>
