<template>
  <div id="cart_block" class="tt-ordered-products">
    <div class="tt-ordered-products__header">
      <span>{{ $t("OrderedProducts_product") }}</span>
      <span>{{ $t("OrderedProducts_count") }}</span>
      <span>{{ $t("OrderedProducts_price") }}</span>
    </div>

    <div class="tt-ordered-products__grid" v-for="product in products">
      <div class="tt-ordered-products__flex">
        <div class="tt-ordered-products__image">
          <ImageLazy :img-src="product.image" :alt-text="product.name" />
          <OutOfStockIcon v-if="isOutOfStock(product.id)" />
          <NextDayIcon v-else-if="product.next_day" />
          <AvailableForPickupIcon v-else-if="product.is_special" />
        </div>
        <div class="tt-ordered-products__name">
          {{ product.name }}
        </div>
      </div>

      <CountControl
        :count="product.quantity"
        @plus="increment(product)"
        @minus="decrement(product)"
      />

      <div class="tt-ordered-products__amount">
        {{ numberWithSpaces(product.amount) }} ₸
      </div>

      <div class="tt-icon-button">
        <img
          src="@/assets/icons-new/close.svg"
          alt="remove"
          @click="remove(product.hash)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import CountControl from "@/components/shared/CountControl.vue";
import ImageLazy from "@/components/shared/ImageLazy.vue";
import NextDayIcon from "@/components/shared/NextDayIcon.vue";
import OutOfStockIcon from "@/components/shared/OutOfStockIcon.vue";
import AvailableForPickupIcon from "@/components/shared/AvailableForPickupIcon.vue";

export default {
  props: ["products"],
  methods: {
    async increment(product) {
      await this.$store.dispatch("cart/addProduct", product);
      await this.$store.dispatch("cart/getDeliveryInfo");
    },
    async decrement(product) {
      await this.$store.dispatch("cart/decrementProduct", product);
      await this.$store.dispatch("cart/getDeliveryInfo");
    },
    remove(hash) {
      this.$store.dispatch("cart/removeProduct", hash);
    },
    isOutOfStock(id) {
      return this.$store.getters["stopList/productIsOutOfStock"](id);
    },
  },
  components: {
    OutOfStockIcon,
    NextDayIcon,
    ImageLazy,
    CountControl,
    AvailableForPickupIcon,
  },
};
</script>

<style lang="sass" scoped>
@import "src/assets/styles-new/colors"
@import "src/assets/styles-new/typography"
@import "src/assets/styles-new/vars"

.tt-ordered-products
  &__header, &__grid
    display: grid
    grid-template-columns: 1fr 226px 150px 36px
    align-items: center
    margin-bottom: 16px
    @media all and (max-width: $mobile-width)
      overflow: hidden

  &__header
    padding-bottom: 12px
    border-bottom: 1px solid $light-gray
  &__flex
    display: flex
    align-items: center
  &__image
    position: relative
    height: 80px
    width: 80px
  &__name
    margin-left: 40px
    font-size: $fs-medium
    font-weight: $fw-medium
  &__amount
    font-weight: $fw-semibold

@media screen and (max-width: $tablet-width)
  .tt-ordered-products
    &__header, &__grid
      grid-template-columns: 1fr 190px 140px 32px

@media screen and (max-width: $mobile-width)
  .tt-ordered-products
    font-size: $fs-small
    &__header, &__grid
      grid-template-columns: 1fr 116px 82px 28px
    &__image
      display: none
    &__name
      font-size: $fs-small
      margin-left: 0
      margin-right: 20px
  .tt-ordered-products::v-deep
    .tt-count-control
      width: 90px
      font-size: $fs-small
</style>
