import { mapState } from "vuex";

export default {
    computed: {
        ...mapState({
            currentUser: state => state.auth.user,
        }),
    },

    methods: {
        numberWithSpaces(x) {
            if (typeof x !== 'number') return x;
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
        },
        truncate(str, n) {
            if (typeof str !== 'string') return str;
            return str.substring(0,n) + `${str.length > n ? '...' : ''}`;
        },
    }
}
