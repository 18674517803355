<template>
  <div class="container-fluid">
    <h3>{{ time }}</h3>
    <div>
      <h4>Организации</h4>
      <div class="row m-1">
          <button
              type="button"
              class="btn border"
              :class="checkSelectedOrganization(organization) ? 'btn-primary' : 'btn-outline-primary'"
              v-for="(organizationFilials, organization) in organizations"
              @click="selectOrganization(organization)"
          >
            {{ organization }}
          </button>
      </div>
    </div>
    <div v-if="filials.length">
      <h4>Терминалы</h4>
      <div class="row m-1">
          <button
              type="button"
              class="btn border"
              :class="checkSelectedFilial(filial) ? 'btn-primary' : 'btn-outline-primary'"
              v-for="filial in filials"
              @click="selectFilial(filial)"
          >
            {{ filial }}
          </button>
      </div>
    </div>
    <div v-if="categoryNames.length">
      <h4>Категории</h4>
      <div class="row m-1">
        <div class="btn-group">
          <button
              type="button"
              class="btn border"
              :class="checkSelectedCategoryName(categoryName) ? 'btn-primary' : 'btn-outline-primary'"
              v-for="categoryName in categoryNames"
              @click="selectCategoryName(categoryName)"
          >
            {{ categoryName }}
          </button>
        </div>
      </div>
    </div>

<!--    <div v-if="categories.length" v-for="category in categories" class="border">-->
<!--      <h1>-->
<!--        {{ category.name }}-->
<!--      </h1>-->
<!--      <div class="row border-bottom">-->
<!--        <div class="col">-->
<!--          Название-->
<!--        </div>-->
<!--        <div class="col" v-for="filial in selectedFilials">-->
<!--          <h5>-->
<!--            {{ filial }}-->
<!--          </h5>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="row" v-for="product in category.products">-->
<!--        <div class="col border ">-->
<!--          <p>-->
<!--            {{ product.name }}-->
<!--          </p>-->
<!--        </div>-->
<!--        <div class="col border" v-for="filial in selectedFilials"-->
<!--             :class="{-->
<!--              'alert-danger': checkRed(product.stop_list[filial] || 0),-->
<!--              'alert-warning': checkYellow(product.stop_list[filial] || 0),-->
<!--             }">-->
<!--          {{ product.stop_list[filial] || 0 }}-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
    <div
        v-if="categories.length" v-for="category in categories"
        class="border stoplist-table-wrapper"
    >
      <h1>
        {{ category.name }}
      </h1>
      <table class="stoplist-table">
        <thead>
          <tr>
            <th>Название</th>
            <th v-for="filial in selectedFilials">{{ filial }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="product in category.products">
            <th>{{ product.name }}</th>
            <td
                v-for="(filial, i) in selectedFilials"
                :class="{
                  'alert-danger': checkRed(product.stop_list[filial] || 0),
                  'alert-warning': checkYellow(product.stop_list[filial] || 0),
                }"
            >
              {{ product.stop_list[filial] || 0 }}
              <span>{{ selectedFilials[i] }}</span>
              <span>{{ product.name }}</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

  </div>
</template>

<script>
export default {
  name: "StopList",
  async created() {
    await this.updateStopList();
    this.timeInterval = setInterval(this.refreshTime, 1000);
    this.updateStopListInterval = setInterval(this.updateStopList, this.updateInterval);

  },
  beforeDestroy() {
    clearInterval(this.timeInterval)
    clearInterval(this.updateStopListInterval)
  },
  computed: {
    selectedFilials() {
      return this.$store.getters['stopList/selectedFilials'];
    },
    filials() {
      return this.$store.getters['stopList/filials'];
    },
    selectedCategoryNames() {
      return this.$store.state.stopList.selectedCategoryNames;
    },
    categoryNames() {
      return this.$store.state.stopList.categoryNames;
    },
    selectedOrganizations() {
      return this.$store.state.stopList.selectedOrganizations;
    },
    organizations() {
      return this.$store.state.stopList.organizations;
    },
    yellowZone() {
      return this.$store.state.stopList.yellow_zone;
    },
    redZone() {
      return this.$store.state.stopList.red_zone;
    },
    updateInterval() {
      return this.$store.state.stopList.update_interval;
    },
    categories() {
      return this.$store.getters['stopList/categories'];
    },
  },
  methods: {
    async updateStopList() {
      await this.$store.dispatch("stopList/getProductsStopList")
    },
    refreshTime() {
      this.time = (new Date()).toLocaleString().replace(", ", " - ");
    },
    checkRed(value) {
      return this.getSplitValue(value) < Number(this.redZone)
    },
    checkYellow(value) {
      return this.getSplitValue(value) >= Number(this.redZone) && this.getSplitValue(value) < Number(this.yellowZone)
    },
    selectFilial(filial) {
      this.$store.commit('stopList/SET_SELECTED_FILIAL', filial);
    },
    checkSelectedFilial(filial) {
      return this.selectedFilials.includes(filial)
    },
    selectCategoryName(categoryName) {
      this.$store.commit('stopList/SET_SELECTED_CATEGORY_NAME', categoryName);
    },
    checkSelectedCategoryName(categoryName) {
      return this.selectedCategoryNames.includes(categoryName)
    },
    selectOrganization(organization) {
      this.$store.commit('stopList/SET_SELECTED_ORGANIZATION', organization);
    },
    checkSelectedOrganization(organization) {
      return this.selectedOrganizations.includes(organization)
    },
    getSplitValue(value) {
      return Number(value.toString().split("/")[0]);
    }
  },
  data() {
    return {
      time: null,
      timeInterval: undefined,
      updateStopListInterval: undefined,
    }
  }
}
</script>
