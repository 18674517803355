<template>
  <div class="tt-delivery">
    <div v-if="canDelivery">
      <div class="tt-delivery__form">
        <div class="tt-delivery__time">
          <div class="tt-v-select">
            <label class="tt-v-select__label">
              {{ $t("TakeDelivery_select_date") }}
            </label>
            <v-select
              v-model="selectedDate"
              :clearable="false"
              :searchable="false"
              :options="dateOptions"
              :selectable="(option) => isSelectable(option)"
              :disabled="!dateOptions.length"
              :loading="isStopListLoading"
              label="value"
            />
          </div>

          <div class="tt-v-select">
            <label class="tt-v-select__label">
              {{ $t("OrderPage_cmpt_TakeDelivery_choose_time") }}
            </label>
            <v-select
              v-model="selectedTime"
              :clearable="false"
              :searchable="false"
              :options="timeOptions"
              :disabled="!timeOptions.length"
              :loading="isStopListLoading"
              label="value"
            />
          </div>
        </div>

        <DeliveryAddress />
      </div>

      <div class="tt-delivery__regulations">
        <div class="tt-icon">
          <img src="@/assets/icons-new/info.svg" />
        </div>
        <p>
          {{ $t("TakeDelivery_attention_about_delivery") }}
          {{ $t("TakeDelivery_first_br") }}
          {{ $t("TakeDelivery_second_br") }}
          {{ $t("TakeDelivery_third_br") }}
        </p>
      </div>
    </div>

    <div class="tt-delivery__no-couriers" v-else>
      {{ $t("OrderBasket_views_section_tabs_cant_delivery") }}
    </div>
  </div>
</template>

<script>
import DeliveryAddress from "@/components/CreateOrder/DeliveryAddress.vue";
import { mapGetters, mapState } from "vuex";

export default {
  computed: {
    ...mapState({
      canDelivery: (state) => state.stopList.canDelivery,
      isStopListLoading: (state) => state.stopList.loading,
    }),
    ...mapGetters({
      dateOptions: "cart/getDeliveryDates",
      timeOptions: "cart/getDeliveryTimes",
    }),
    selectedDate: {
      get() {
        return this.$store.state.cart.selectedDeliveryDate;
      },
      set(selectedDate) {
        this.$store.commit("cart/SET_SELECTED_DELIVERY_DATE", selectedDate);
      },
    },
    selectedTime: {
      get() {
        return this.$store.state.cart.selectedDeliveryTime;
      },
      set(selectedTime) {
        this.$store.commit("cart/SET_SELECTED_DELIVERY_TIME", selectedTime);
      },
    },
  },
  methods: {
    isSelectable(date) {
      const currentDate = new Date()
        .toLocaleDateString("en-GB")
        .replaceAll("/", ".");
      const currentTime = new Date().getHours();
      return !(date === currentDate && currentTime > 15);
    },
  },
  mounted() {
    this.$store.commit("filial/SET_ACTIVE_FILIAL", {});
    this.$store.commit("cart/RESET_DELIVERY_STATE", {});
    // this.$store.commit("cart/SET_SELECTED_DELIVERY_TIME", null);
  },
  components: {
    DeliveryAddress,
  },
};
</script>

<style lang="sass" scoped>
@import "src/assets/styles-new/typography"
@import "src/assets/styles-new/colors"
@import "src/assets/styles-new/vars"

.tt-delivery
  position: relative
  &__time
    display: grid
    grid-template-columns: repeat(2, 200px)
    column-gap: 40px
    margin-bottom: 32px
  &__regulations
    position: absolute
    top: 0
    right: 0
    max-width: 256px
    font-size: $fs-small
    .tt-icon
      justify-content: flex-start
  &__no-couriers
    color: $error

@media screen and (max-width: 1199px)
  .tt-delivery__regulations
    max-width: 200px

@media screen and (max-width: $tablet-width)
  .tt-delivery
    &__time
      margin-bottom: 24px
    &__regulations
      display: none

@media screen and (max-width: $mobile-width)
  .tt-delivery
    &__time
      display: flex
      flex-direction: column
      gap: 16px
      margin-bottom: 16px
</style>
