<template>
  <div class="tt-basket-product">
    <div class="tt-basket-product__image">
      <ImageLazy :img-src="product.image"
                 :alt-text="product.name" />
      <NextDayIcon v-if="product.next_day" />
      <AvailableForPickupIcon v-if="product.is_special" />
    </div>

    <div class="tt-basket-product__grid">
      <div class=tt-basket-product__name>
        {{ product.name }}
      </div>

      <div class="tt-icon-button tt-basket-product__remove-icon"
           @click="removeProduct(product.hash)">
        <img src="@/assets/icons-new/close.svg" alt="remove">
      </div>

      <div class="tt-basket-product__control">
        <CountControl :count="product.quantity"
                      @plus="incrementProduct"
                      @minus="decrementProduct" />
      </div>

      <div class="tt-basket-product__total-sum">
        {{ numberWithSpaces(product.amount) }} ₸
      </div>
    </div>
  </div>
</template>
<script>
import ImageLazy from "@/components/shared/ImageLazy.vue";
import CountControl from "@/components/shared/CountControl.vue";
import NextDayIcon from "@/components/shared/NextDayIcon.vue";
import AvailableForPickupIcon from "@/components/shared/AvailableForPickupIcon.vue";

export default {
  props: ["product", "index"],
  methods: {
    decrementProduct() {
      this.$store.dispatch("cart/decrementProduct", this.product);
    },
    incrementProduct() {
      this.$store.dispatch("cart/addProduct", this.product);
    },
    removeProduct(hash) {
      this.$store.dispatch("cart/removeProduct", hash);
    },
  },
  components: {
    NextDayIcon,
    CountControl,
    ImageLazy,
    AvailableForPickupIcon
  }
};
</script>

<style lang="sass" scoped>
@import "src/assets/styles-new/typography"

.tt-basket-product
  display: flex
  gap: 28px
  &__image
    position: relative
    min-width: 88px
    min-height: 88px
    max-width: 88px
    max-height: 88px
  &__grid
    width: 100%
    display: grid
    grid-template-columns: 1fr 100px
    grid-gap: 8px 16px
  &__name
    font-size: $fs-medium
    font-weight: $fw-medium
  &__remove-icon
    justify-self: end
  &__control
    align-self: end
  &__total-sum
    justify-self: end
    align-self: end
    font-size: 1.25rem
    font-weight: $fw-semibold
</style>