<template>
  <div class="tt-lang-select">
    <v-select
      v-model="currentLang"
      :options="options"
      :clearable="false"
      :searchable="false"
      @input="onLanguageChange"
    ></v-select>
  </div>
</template>

<script>
export default {
  data: () => ({
    currentLang: {
      label: "navbar_language_1",
      code: "kz",
    },
    languages: [
      {
        label: "navbar_language_1",
        code: "kz",
      },
      {
        label: "navbar_language_2",
        code: "ru",
      }
    ],
  }),
  computed: {
    options: {
      get() {
        return this.languages.map(language => {
          language.label = this.$t(language.label);
          return language;
        })
      }
    }
  },
  mounted() {
    this.currentLang = this.options.filter(language => {
      return language.code === this.$i18n.locale
    })[0];
  },
  methods: {
    onLanguageChange({ code }) {
      this.$i18n.locale = code
      let newPath = this.$route.path.replace(/^\/[a-z]{2}/, `/${code}`);
      if (newPath !== this.$route.path) {
        this.$router.push(newPath);
      }
    }
  }
};
</script>

<style lang="sass" scoped>
@import "src/assets/styles-new/vars"
@import "src/assets/styles-new/typography"
@import "src/assets/styles-new/colors"

.tt-lang-select::v-deep
  width: 75px
  .v-select
    width: 50px
    font-family: 'Montserrat Alternates', Inter, sans-serif
    font-weight: $fw-semibold
    color: $dark-gray
  .vs__selected
    margin-top: 0
  .vs__dropdown-toggle
    border: none
  .vs__selected-options
    flex-wrap: nowrap
    min-width: 50px
  .vs__open-indicator
    fill: $black
  .vs__dropdown-option--selected
    color: $black
  .vs__dropdown-menu
    width: 75px !important
    border: none
    border-radius: 8px
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08)
  .vs__dropdown-option--highlight
    background-color: $light-gray
    color: $black

@media screen and (max-width: $tablet-width)
  .tt-lang-select::v-deep
    font-size: $fs-large
    .v-select
      width: 100px
    .vs__selected
      color: $dark-gray
      padding-left: 0
      margin-left: 0
    .vs__open-indicator
      fill: $dark-gray
    .vs__dropdown-option--selected
      color: $dark-gray
    .vs__dropdown-option
      color: $gray
    .vs__dropdown-option--highlight
      background-color: $light-gray
      color: $dark-gray

@media screen and (max-width: $mobile-width)
  .tt-lang-select::v-deep
    font-size: $fs-semilarge
    .v-select
      width: 80px
</style>
