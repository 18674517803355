import axios from "axios";
const state = {
    modalProduct: {},
    showFilialModal: false,
};

const mutations = {
    SET_MODAL_PRODUCT(state, product) {
        state.modalProduct = product
    },
    SET_SHOW_FILIAL_MODAL(state, name) {
        state.showFilialModal = name
    },
};

const actions = {
    clearModalProduct({ commit }) {
        commit('SET_MODAL_PRODUCT', {});
    },
    setModalProduct({ commit, dispatch }, product) {
        dispatch('clearModalProduct');
        axios.get(`product/${product.id}`)
            .then(response => {
                commit('SET_MODAL_PRODUCT', response.data);
                console.log(response.data)
            });
    },
    setShowFilialModal({ commit, dispatch }, name) {
        dispatch('clearModalProduct');
        commit('SET_SHOW_FILIAL_MODAL', name)
    },
};

// Экспортируем наружу
export default {
    namespaced: true,
    state,
    mutations,
    actions
}
