<template>
  <div class="tt-public-offer">
    <div class="tt-public-offer__title ff-mont">
      {{ $t('publicy_offerta_cmpt_header') }}
    </div>

    <div class="tt-public-offer__content">
<!--      <h5>{{ $t('publicy_offerta_cmpt_header_02') }}</h5> -->
      <!--      <p>{{ $t('publicy_offerta_cmpt_header_03') }}</p> -->
      <!--      <p>{{ $t('publicy_offerta_cmpt_terms_01') }}</p> -->
      <!--     <p>{{ $t('publicy_offerta_cmpt_terms_02') }}</p> -->
      <!--    <p>{{ $t('publicy_offerta_cmpt_terms_03') }}</p> -->
      <!--    <p>{{ $t('publicy_offerta_cmpt_terms_04') }}</p> -->
      <!--    <p>{{ $t('publicy_offerta_cmpt_terms_05') }}</p> -->
      <!--    <p>{{ $t('publicy_offerta_cmpt_terms_06') }}</p> -->
      <!--    <p>{{ $t('publicy_offerta_cmpt_terms_07') }}</p> -->
      <!--    <p>{{ $t('publicy_offerta_cmpt_terms_08') }}</p> -->
      <!--    <p>{{ $t('publicy_offerta_cmpt_terms_09') }}</p> -->

      <h5>{{ $t('publicy_offerta_cmpt_common_01') }}</h5>
      <p>{{ $t('publicy_offerta_cmpt_common_02') }}</p>
      <p>{{ $t('publicy_offerta_cmpt_common_03') }}</p>
      <p>{{ $t('publicy_offerta_cmpt_common_04') }}</p>
      <p>{{ $t('publicy_offerta_cmpt_common_05') }}</p>
      <p>{{ $t('publicy_offerta_cmpt_common_06') }}</p>

      <h5>{{ $t('publicy_offerta_cmpt_status_site_01') }}</h5>
      <p>{{ $t('publicy_offerta_cmpt_status_site_02') }}</p>
      <p>{{ $t('publicy_offerta_cmpt_status_site_03') }}</p>
      <p>{{ $t('publicy_offerta_cmpt_status_site_04') }}</p>
      <p>{{ $t('publicy_offerta_cmpt_status_site_05') }}</p>
      <p>{{ $t('publicy_offerta_cmpt_status_site_06') }}</p>
      <p>{{ $t('publicy_offerta_cmpt_status_site_07') }}</p>
      <p>{{ $t('publicy_offerta_cmpt_status_site_08') }}</p>
      <p>{{ $t('publicy_offerta_cmpt_status_site_09') }}</p>
      <p>{{ $t('publicy_offerta_cmpt_status_site_10') }}</p>

      <h5>{{ $t('publicy_offerta_cmpt_status_customer_01') }}</h5>
      <p>{{ $t('publicy_offerta_cmpt_status_customer_02') }}</p>
<!--      <p>{{ $t('publicy_offerta_cmpt_status_customer_03') }}</p>-->
<!--      <p>{{ $t('publicy_offerta_cmpt_status_customer_04') }}</p>-->
<!--      <p>{{ $t('publicy_offerta_cmpt_status_customer_05') }}</p>-->

      <h5>{{ $t('publicy_offerta_cmpt_status_subject_01') }}</h5>
      <p>{{ $t('publicy_offerta_cmpt_status_subject_02') }}</p>
      <p>{{ $t('publicy_offerta_cmpt_status_subject_03') }}</p>
      <p>{{ $t('publicy_offerta_cmpt_status_subject_04') }}</p>
      <p>{{ $t('publicy_offerta_cmpt_status_subject_05') }}</p>
      <p>{{ $t('publicy_offerta_cmpt_status_subject_06') }}</p>
      <p>{{ $t('publicy_offerta_cmpt_status_subject_07') }}</p>
      <p>{{ $t('publicy_offerta_cmpt_status_subject_08') }}</p>
      <p>{{ $t('publicy_offerta_cmpt_status_subject_09') }}</p>
      <p>{{ $t('publicy_offerta_cmpt_status_subject_10') }}</p>
      <p>{{ $t('publicy_offerta_cmpt_status_subject_11') }}</p>
      <p>{{ $t('publicy_offerta_cmpt_status_subject_12') }}</p>

      <h5>{{ $t('publicy_offerta_cmpt_status_modifier_time_01') }}</h5>
      <p>{{ $t('publicy_offerta_cmpt_status_modifier_time_02') }}</p>
      <p>{{ $t('publicy_offerta_cmpt_status_modifier_time_03') }}</p>
      <p>{{ $t('publicy_offerta_cmpt_status_modifier_time_04') }}</p>
      <p>{{ $t('publicy_offerta_cmpt_status_modifier_time_05') }}</p>
<!--      <p>{{ $t('publicy_offerta_cmpt_status_modifier_time_06') }}</p>-->
<!--      <p>{{ $t('publicy_offerta_cmpt_status_modifier_time_07') }}</p>-->
<!--      <p>{{ $t('publicy_offerta_cmpt_status_modifier_time_08') }}</p>-->

      <h5>{{ $t('publicy_offerta_cmpt_status_info_product_01') }}</h5>
      <p>{{ $t('publicy_offerta_cmpt_status_info_product_02') }}</p>
      <p>{{ $t('publicy_offerta_cmpt_status_info_product_03') }}</p>
<!--      <p>{{ $t('publicy_offerta_cmpt_status_info_product_04') }}</p>-->
<!--      <p>{{ $t('publicy_offerta_cmpt_status_info_product_05') }}</p>-->
<!--      <p>{{ $t('publicy_offerta_cmpt_status_info_product_06') }}</p>-->
<!--      <p>{{ $t('publicy_offerta_cmpt_status_info_product_07') }}</p>-->

      <h5>{{ $t('publicy_offerta_cmpt_purch_product_01') }}</h5>
      <p>{{ $t('publicy_offerta_cmpt_purch_product_02') }}</p>
      <p>{{ $t('publicy_offerta_cmpt_purch_product_03') }}</p>
      <p>{{ $t('publicy_offerta_cmpt_purch_product_04') }}</p>
      <p>{{ $t('publicy_offerta_cmpt_purch_product_05') }}</p>
      <p>{{ $t('publicy_offerta_cmpt_purch_product_06') }}</p>
      <p>{{ $t('publicy_offerta_cmpt_purch_product_07') }}</p>
      <p>{{ $t('publicy_offerta_cmpt_purch_product_08') }}</p>
      <p>{{ $t('publicy_offerta_cmpt_purch_product_09') }}</p>
      <p>{{ $t('publicy_offerta_cmpt_purch_product_10') }}</p>
      <p>{{ $t('publicy_offerta_cmpt_purch_product_11') }}</p>
      <p>{{ $t('publicy_offerta_cmpt_purch_product_12') }}</p>
      <p>{{ $t('publicy_offerta_cmpt_purch_product_13') }}</p>
      <p>{{ $t('publicy_offerta_cmpt_purch_product_14') }}</p>
      <p>{{ $t('publicy_offerta_cmpt_purch_product_15') }}</p>
      <p>{{ $t('publicy_offerta_cmpt_purch_product_16') }}</p>
      <p>{{ $t('publicy_offerta_cmpt_purch_product_17') }}</p>
      <p>{{ $t('publicy_offerta_cmpt_purch_product_18') }}</p>
      <p>{{ $t('publicy_offerta_cmpt_purch_product_19') }}</p>
      <p>{{ $t('publicy_offerta_cmpt_purch_product_20') }}</p>
      <p>{{ $t('publicy_offerta_cmpt_purch_product_21') }}</p>
      <p>{{ $t('publicy_offerta_cmpt_purch_product_22') }}</p>
      <p>{{ $t('publicy_offerta_cmpt_purch_product_23') }}</p>


      <h5>{{ $t('publicy_offerta_cmpt_price_prod_01') }}</h5>
      <p>{{ $t('publicy_offerta_cmpt_price_prod_02') }}</p>
      <p>{{ $t('publicy_offerta_cmpt_price_prod_03') }}</p>
      <p>{{ $t('publicy_offerta_cmpt_price_prod_04') }}</p>
      <p>{{ $t('publicy_offerta_cmpt_price_prod_05') }}</p>
      <p>{{ $t('publicy_offerta_cmpt_price_prod_06') }}</p>
      <p>{{ $t('publicy_offerta_cmpt_price_prod_07') }}</p>
      <p>{{ $t('publicy_offerta_cmpt_price_prod_08') }}</p>
      <p>{{ $t('publicy_offerta_cmpt_price_prod_09') }}</p>

      <h5>{{ $t('publicy_offerta_cmpt_price_pay_prod_01') }}</h5>
      <p>{{ $t('publicy_offerta_cmpt_price_pay_prod_02') }}</p>
      <p>{{ $t('publicy_offerta_cmpt_price_pay_prod_03') }}</p>
      <p>{{ $t('publicy_offerta_cmpt_price_pay_prod_04') }}</p>
<!--      <p>{{ $t('publicy_offerta_cmpt_price_pay_prod_05') }}</p>-->
<!--      <p>{{ $t('publicy_offerta_cmpt_price_pay_prod_06') }}</p>-->
<!--      <p>{{ $t('publicy_offerta_cmpt_price_pay_prod_07') }}</p>-->

<!--      <h5>{{ $t('publicy_offerta_cmpt_delivery_product_01') }}</h5>-->
<!--      <p>{{ $t('publicy_offerta_cmpt_delivery_product_02') }}</p>-->
<!--      <p>{{ $t('publicy_offerta_cmpt_delivery_product_03') }}</p>-->
<!--      <p>{{ $t('publicy_offerta_cmpt_delivery_product_04') }}</p>-->
<!--      <p>{{ $t('publicy_offerta_cmpt_delivery_product_05') }}</p>-->
<!--      <p>{{ $t('publicy_offerta_cmpt_delivery_product_06') }}</p>-->
<!--      <p>{{ $t('publicy_offerta_cmpt_delivery_product_07') }}</p>-->
<!--      <p>{{ $t('publicy_offerta_cmpt_delivery_product_08') }}</p>-->
<!--      <p>{{ $t('publicy_offerta_cmpt_delivery_product_09') }}</p>-->
<!--      <p>{{ $t('publicy_offerta_cmpt_delivery_product_10') }}</p>-->
<!--      <p>{{ $t('publicy_offerta_cmpt_delivery_product_11') }}</p>-->
<!--      <p>{{ $t('publicy_offerta_cmpt_delivery_product_12') }}</p>-->
<!--      <p>{{ $t('publicy_offerta_cmpt_delivery_product_13') }}</p>-->
<!--      <p>{{ $t('publicy_offerta_cmpt_delivery_product_14') }}</p>-->
<!--      <p>{{ $t('publicy_offerta_cmpt_delivery_product_15') }}</p>-->
<!--      <p>{{ $t('publicy_offerta_cmpt_delivery_product_16') }}</p>-->

<!--      <h5>{{ $t('publicy_offerta_cmpt_change_prod_01') }}</h5>-->
<!--      <p>{{ $t('publicy_offerta_cmpt_change_prod_02') }}</p>-->
<!--      <p>{{ $t('publicy_offerta_cmpt_change_prod_03') }}</p>-->
<!--      <p>{{ $t('publicy_offerta_cmpt_change_prod_04') }}</p>-->
<!--      <p>{{ $t('publicy_offerta_cmpt_change_prod_05') }}</p>-->
<!--      <p>{{ $t('publicy_offerta_cmpt_change_prod_06') }}</p>-->
<!--      <p>{{ $t('publicy_offerta_cmpt_change_prod_07') }}</p>-->
<!--      <p>{{ $t('publicy_offerta_cmpt_change_prod_08') }}</p>-->
<!--      <p>{{ $t('publicy_offerta_cmpt_change_prod_09') }}</p>-->

<!--      <h5>{{ $t('publicy_offerta_cmpt_fors_01') }}</h5>-->
<!--      <p>{{ $t('publicy_offerta_cmpt_fors_02') }}</p>-->

<!--      <h5>{{ $t('publicy_offerta_cmpt_resp_01') }}</h5>-->
<!--      <p>{{ $t('publicy_offerta_cmpt_resp_02') }}</p>-->
<!--      <p>{{ $t('publicy_offerta_cmpt_resp_03') }}</p>-->
<!--      <p>{{ $t('publicy_offerta_cmpt_resp_04') }}</p>-->
<!--      <p>{{ $t('publicy_offerta_cmpt_resp_05') }}</p>-->
<!--      <p>{{ $t('publicy_offerta_cmpt_resp_06') }}</p>-->
<!--      <p>{{ $t('publicy_offerta_cmpt_resp_07') }}</p>-->

<!--      <h5>{{ $t('publicy_offerta_cmpt_etc_01') }}</h5>-->
<!--      <p>{{ $t('publicy_offerta_cmpt_etc_02') }}</p>-->
<!--      <p>{{ $t('publicy_offerta_cmpt_etc_03') }}</p>-->
<!--      <p>{{ $t('publicy_offerta_cmpt_etc_04') }}</p>-->
<!--      <p>{{ $t('publicy_offerta_cmpt_etc_05') }}</p>-->
<!--      <p>{{ $t('publicy_offerta_cmpt_etc_06') }}</p>-->
<!--      <p>{{ $t('publicy_offerta_cmpt_etc_07') }}</p>-->

<!--      <h5>{{ $t('publicy_offerta_cmpt_rekv_01') }}</h5>-->
<!--      <p>{{ $t('publicy_offerta_cmpt_rekv_02') }}</p>-->
<!--      <p>{{ $t('publicy_offerta_cmpt_rekv_03') }}</p>-->
<!--      <p>{{ $t('publicy_offerta_cmpt_rekv_04') }}</p>-->
<!--      <p>{{ $t('publicy_offerta_cmpt_rekv_05') }}</p>-->
<!--      <p>{{ $t('publicy_offerta_cmpt_rekv_06') }}</p>-->
<!--      <p>{{ $t('publicy_offerta_cmpt_rekv_07') }}</p>-->
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="sass" scoped>
@import "src/assets/styles-new/typography"
@import "src/assets/styles-new/vars"

.tt-public-offer
  padding: 40px 0 100px
  &__title
    font-size: $fs-larger
    line-height: 1.2em
    font-weight: $fw-semibold
    margin-bottom: 48px

@media screen and (max-width: $tablet-width)
  .tt-public-offer
    padding: 40px 0
    &__title
      font-size: $fs-large

@media screen and (max-width: $mobile-width)
  .tt-public-offer
    padding-top: 16px
    &__title
      font-size: $fs-semilarge
      margin-bottom: 32px
</style>