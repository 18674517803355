<template>
  <div class="tt-address">
    <div class="tt-address__grid">
      <div class="tt-input-field tt-address__address">
        <label class="tt-input-field__label">
          {{ $t("OrderPage_cmpt_DeliveryAddress_street_house") }}
        </label>
        <input
          type="text"
          class="tt-input-field__input"
          :class="{
            'tt-input-field__input--invalid': !canDelivery || addressError,
          }"
          :placeholder="$t('OrderPage_cmpt_DeliveryAddress_placeholder_street')"
          id="address_input"
          v-model="address"
          @blur="lostFocus"
          @focus="showSuggestList = true"
          autocomplete="off"
        />
        <div class="suggestion" v-if="showSuggestList && address.length > 0">
          <div class="suggestion_list">
            <div
              v-for="(item, index) in suggestResponse"
              :key="index"
              :value="item.value"
              @click="selectAdress(item)"
            >
              {{ item.displayName }}
            </div>
          </div>
        </div>
        <div class="tt-input-field__error">
          <span v-if="!canDelivery">
            {{ $t("OrderPage_cmpt_DeliveryAddress_error_cant_delivery") }}
          </span>
          <span v-if="addressError">
            {{ $t(addressError) }}
          </span>
        </div>
      </div>

      <div class="tt-input-field">
        <label class="tt-input-field__label">
          {{ $t("OrderPage_cmpt_DeliveryAddress_flat") }}
        </label>
        <input
          type="text"
          class="tt-input-field__input"
          :placeholder="$t('OrderPage_cmpt_DeliveryAddress_placeholder_flat')"
          v-model="addressFlat"
        />
      </div>

      <div class="tt-input-field">
        <label class="tt-input-field__label">
          {{ $t("OrderPage_cmpt_DeliveryAddress_entrance") }}
        </label>
        <input
          type="text"
          class="tt-input-field__input"
          :placeholder="
            $t('OrderPage_cmpt_DeliveryAddress_placeholder_entrance')
          "
          v-model="addressEntrance"
        />
      </div>

      <div class="tt-input-field">
        <label class="tt-input-field__label">
          {{ $t("OrderPage_cmpt_DeliveryAddress_doorphone") }}
        </label>
        <input
          type="text"
          class="tt-input-field__input"
          :placeholder="
            $t('OrderPage_cmpt_DeliveryAddress_placeholder_doorphone')
          "
          v-model="addressDoorphone"
        />
      </div>

      <div class="tt-input-field">
        <label class="tt-input-field__label">
          {{ $t("OrderPage_cmpt_DeliveryAddress_floor") }}
        </label>
        <input
          type="text"
          class="tt-input-field__input"
          :placeholder="$t('OrderPage_cmpt_DeliveryAddress_placeholder_floor')"
          v-model="addressFloor"
        />
      </div>
    </div>

    <div class="tt-address__map-dropdown">
      <div
        class="tt-address__map-button"
        :class="{ 'tt-address__map-button--active': delivery.map }"
        @click="delivery.map = !delivery.map"
      >
        <img src="@/assets/icons-new/target.svg" alt />
        <div class="tt-address__map-button__text">
          <span v-if="!delivery.map">
            {{ $t("OrderPage_cmpt_DeliveryAddress_btn2show_map") }}
          </span>
          <span v-else>
            {{ $t("OrderPage_cmpt_DeliveryAddress_btn2hide_map") }}
          </span>
        </div>
        <img
          class="tt-address__map-button__chevron"
          src="@/assets/icons-new/chevron_down_blue.svg"
        />
      </div>

      <div id="map" class="tt-address__map" v-show="delivery.map"></div>
    </div>
  </div>
</template>

<script>
import { loadYmap } from "vue-yandex-maps";
import { mapState } from "vuex";

export default {
  data: () => ({
    addressError: null,
    addressInput: undefined,
    myMap: undefined,
    address: "",
    shortAddress: "",
    interval: null,
    mapSettings: {
      apiKey:
        process.env.VUE_APP_YANDEX_CART_API_KEY +
        "&" +
        `suggest_apikey=` +
        process.env.VUE_APP_YANDEX_SUGGEST_API_KEY,
      lang: "ru_RU",
      center: [71.430411, 51.128207],
      zoom: 12,
      controls: ["zoomControl"],
      coordorder: "longlat",
    },
    placemarkSettings: {},
    delivery: {
      map: true,
      house: false,
    },
    debounceAdressTimeout: 500,
    showSuggestList: false,
    suggestResponse: [],
  }),
  computed: {
    ...mapState({
      canDelivery: (state) => state.cart.canDelivery,
    }),
    addressFlat: {
      get() {
        return this.$store.state.cart.deliveryAddress.flat;
      },
      set(flat) {
        this.$store.commit("cart/SET_DELIVERY_FLAT", flat);
      },
    },
    addressEntrance: {
      get() {
        return this.$store.state.cart.deliveryAddress.entrance;
      },
      set(entrance) {
        this.$store.commit("cart/SET_DELIVERY_ENTRANCE", entrance);
      },
    },
    addressFloor: {
      get() {
        return this.$store.state.cart.deliveryAddress.floor;
      },
      set(floor) {
        this.$store.commit("cart/SET_DELIVERY_FLOOR", floor);
      },
    },
    addressDoorphone: {
      get() {
        return this.$store.state.cart.deliveryAddress.doorphone;
      },
      set(doorphone) {
        this.$store.commit("cart/SET_DELIVERY_DOORPHONE", doorphone);
      },
    },
  },
  methods: {
    initMap() {
      this.myMap = new ymaps.Map("map", this.mapSettings);
      // this.addressInput = new ymaps.SuggestView("address_input", {
      //   boundedBy: [
      //     [71.2, 51.3],
      //     [71.7, 51],
      //   ],
      //   strictsBounds: true,
      //   provider: {
      //     suggest: function (request, options) {
      //       return ymaps.suggest("НурСултан , " + request);
      //     },
      //   },
      // });

      this.myMap.events.add("click", (e) => {
        let coords = e.get("coords");
        // Если метка уже создана – просто передвигаем ее.
        if (this.myPlacemark) {
          this.myPlacemark.geometry.setCoordinates(coords);
        }
        // Если нет – создаем.
        else {
          this.myPlacemark = this.createPlacemark(coords);
          this.myMap.geoObjects.add(this.myPlacemark);
          // Слушаем событие окончания перетаскивания на метке.
          this.myPlacemark.events.add("dragend", () => {
            this.getAddress(this.myPlacemark.geometry.getCoordinates());
          });
        }
        this.getAddress(coords);
      });
      // this.addressInput.events.add("select", (e) => {
      //   let request = this.addressInput.state.get("request");
      //   this.geocodeRequest(request);
      // });
    },
    getAddress(coords) {
      this.myPlacemark.properties.set("iconCaption", "поиск...");
      this.geocodeRequest(coords);
    },
    createPlacemark(coords) {
      return new ymaps.Placemark(
        coords,
        {
          iconCaption: this.$t(
            "OrderPage_cmpt_DeliveryAddress_search_inthe_map"
          ),
        },
        {
          preset: "islands#violetDotIconWithCaption",
          draggable: true,
        }
      );
    },
    geocodeRequest(request) {
      ymaps
        .geocode(request)
        .then((res) => {
          const obj = res.geoObjects.get(0);
          this.setAddress(obj);
          this.$store.commit(
            "cart/SET_DELIVERY_COORDS",
            obj.geometry.getCoordinates()
          );
          if (this.checkFullAddress(obj)) {
            this.$store.dispatch("cart/getDeliveryInfo");
            this.addressError = null;
          }
          const mapState = ymaps.util.bounds.getCenterAndZoom(
            obj.properties.get("boundedBy"),
            this.myMap.container.getSize()
          );
          if (typeof request === "string") {
            this.setMapCenter(mapState);
            this.setPlacemark(mapState);
          } else {
            this.myPlacemark.properties.set(this.placemarkSettings);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    checkFullAddress(obj) {
      if (obj) {
        switch (
          obj.properties.get("metaDataProperty.GeocoderMetaData.precision")
        ) {
          case "exact":
            return true;
          case "number":
          case "near":
          case "range":
            this.addressError =
              "OrderPage_cmpt_DeliveryAddress_error_home_number_map";
            break;
          case "street":
            this.addressError =
              "OrderPage_cmpt_DeliveryAddress_error_street_number_map";
            break;
          case "other":
          default:
            this.addressError =
              "OrderPage_cmpt_DeliveryAddress_error_map_correctly_address";
        }
      } else {
        this.addressError = "OrderPage_cmpt_DeliveryAddress_incorrect_address";
      }
      return false;
    },
    setMapCenter(mapState) {
      if (this.myPlacemark) {
        this.myMap.setCenter(mapState.center);
      } else {
        this.myMap.setCenter(mapState.center, mapState.zoom);
      }
    },
    setPlacemark(mapState) {
      if (this.myPlacemark) {
        this.myPlacemark.geometry.setCoordinates(mapState.center);
        this.myPlacemark.properties.set(this.placemarkSettings);
      } else {
        this.myPlacemark = new ymaps.Placemark(
          mapState.center,
          this.placemarkSettings,
          {
            preset: "islands#redDotIconWithCaption",
          }
        );
        this.myMap.geoObjects.add(this.myPlacemark);
      }
    },
    setAddress(obj) {
      this.$store.commit("cart/SET_DELIVERY_ADDRESS", obj);
      this.address = obj.getAddressLine();
      this.shortAddress = [
        obj.getThoroughfare(),
        obj.getPremiseNumber(),
        obj.getPremise(),
      ].join(" ");
      if (!this.shortAddress.trim().length) {
        this.shortAddress = [
          obj.getLocalities().length
            ? obj.getLocalities()
            : obj.getAdministrativeAreas(),
        ].join(" ");
      }
      this.placemarkSettings = {
        iconCaption: this.shortAddress,
        balloonContent: this.address,
      };
    },
    onZonesLoad(json) {
      this.delivery.map = false;
      // Добавляем зоны на карту.
      let deliveryZones = window.ymaps.geoQuery(json).addToMap(this.myMap);
      // Задаём цвет и контент балунов полигонов.
      deliveryZones.each(function (obj) {
        obj.options.set({
          fillColor: obj.properties.get("fill"),
          fillOpacity: obj.properties.get("fill-opacity"),
          strokeColor: obj.properties.get("stroke"),
          strokeWidth: obj.properties.get("stroke-width"),
          strokeOpacity: obj.properties.get("stroke-opacity"),
          interactivityModel: "default#silent",
        });
        obj.properties.set("balloonContent", obj.properties.get("description"));
      });
    },
    sleep(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    suggest(request, options) {
      //console.log("suggest", request);
      return ymaps.suggest("НурСултан, " + request, {
        boundedBy: [
          [71.2, 51.3],
          [71.7, 51],
        ],
        results: 5,
      });
      // return ymaps.vow.resolve([
      //   {
      //     displayName: "Мытищи, Московская область",
      //     value: "Россия, Московская область, Мытищи ",
      //   },
      //   {
      //     displayName: "Мытищи2, Московская область2",
      //     value: "Россия2, Московская область, Мытищи2 ",
      //   },
      //   {
      //     displayName: "Мытищи3, Московская область3",
      //     value: "Россия3, Московская область, Мытищи3 ",
      //   },
      //]);
    },
    lostFocus() {
      setTimeout(() => {
        this.showSuggestList = false;
      }, 200);
    },
    selectAdress(request) {
      //console.log("selectAdress", request);
      this.address = request.value;
      this.geocodeRequest(request.value);
    },
  },
  created() {
    loadYmap(this.mapSettings);
    this.interval = setInterval(async () => {
      if (
        window.ymaps !== undefined &&
        window.ymaps.SuggestView !== undefined
      ) {
        clearInterval(this.interval);
        this.initMap();
        await this.$store.dispatch("cart/getDeliveryZone");
        this.onZonesLoad(this.$store.state.cart.geoJson);
      }
    }, 1000);
  },
  beforeDestroy() {
    this.$store.commit("filial/SET_ACTIVE_FILIAL", {});
    this.$store.commit("cart/RESET_DELIVERY_STATE", {});
  },
  watch: {
    async address(value) {
      // console.log(
      //   value,
      //   this.address,
      //   value === this.address,
      //   this.showSuggestList
      // );
      await this.sleep(this.debounceAdressTimeout);
      if (value === this.address && this.showSuggestList) {
        this.suggestResponse = await this.suggest(value);
        //console.log("suggestResponse", this.suggestResponse);
      }
    },
  },
};
</script>

<style lang="sass" scoped>
@import "src/assets/styles-new/colors"
@import "src/assets/styles-new/typography"
@import "src/assets/styles-new/vars"

.tt-address
  &__grid
    display: grid
    grid-template-columns: repeat(3, 200px)
    column-gap: 40px
    row-gap: 32px
    margin-bottom: 40px
  &__address
    grid-column-start: 1
    grid-column-end: 3
  &__map-button
    display: inline-flex
    align-items: center
    cursor: pointer
    margin-bottom: 20px
    &--active
      .tt-address__map-button__chevron
        transform: rotate(180deg)
    &__text
      margin: 0 8px
      color: $main
      font-weight: $fw-medium
  &__map
    width: 100%
    height: 50vh
    max-height: 432px

@media screen and (max-width: $tablet-width)
  .tt-address
    &__grid
      row-gap: 24px
      margin-bottom: 32px

@media screen and (max-width: $mobile-width)
  .tt-address
    &__grid
      display: flex
      flex-direction: column
      gap: 16px
      margin-bottom: 24px
</style>

<style scoped>
.suggestion {
  position: relative;
}

.suggestion_list {
  margin-top: 0px;
  margin-bottom: 26px;
  background-color: #ffffff;
  position: absolute;
  top: 100%;
  left: 0;
  border: 1px solid rgb(201, 201, 201);
  width: 100%;
  z-index: 5000;
}

.suggestion_list div {
  width: 100%;
  padding: 5px 5px 5px 5px;
  font-size: 12px;
}
.suggestion_list div:hover {
  background-color: #25b6bf;
  color: white;
  cursor: pointer;
}
</style>
