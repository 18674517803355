<template>
  <div class="tt-rent">
    <div class="tt-rent-block">
      <div class="tt-rent-block__info">
        <div class="tt-rent-block__title">
          {{ $t('rental_of_premises_title') }}
        </div>
        <div class="tt-rent-block__description">
          <div v-html="$t('rental_of_premises_description')"></div>
        </div>
        <div class="tt-rent-block__subtitle">
          {{ $t('rental_of_premises_subtitle') }}
        </div>
        <div class="tt-rent-block__content">
          <ul>
            <li>{{ $t('rental_of_premises_content_li_1') }}</li>
            <li>{{ $t('rental_of_premises_content_li_2') }}</li>
            <li>{{ $t('rental_of_premises_content_li_3') }}</li>
            <li>{{ $t('rental_of_premises_content_li_4') }}</li>
            <li>{{ $t('rental_of_premises_content_li_5') }}</li>
            <li>{{ $t('rental_of_premises_content_li_6') }}</li>
            <li>{{ $t('rental_of_premises_content_li_7') }}</li>
            <li>{{ $t('rental_of_premises_content_li_8') }}</li>
            <li>{{ $t('rental_of_premises_content_li_9') }}</li>
          </ul>
        </div>
        <a href="/rental-of-premises" class="tt-rent-block__btn-link">
          <button class="tt-button tt-button--primary">
            {{ $t('rental_of_premises_btn_text') }}
          </button>
        </a>
      </div>
    </div>

    <div class="tt-rent-contact">
      <div class="tt-rent-contact__title">
        {{ $t('rental_of_premises_contact_title') }}
      </div>
      <div class="tt-rent-contact__position">
        {{ $t('rental_of_premises_contact_position') }}
      </div>
      <div class="tt-rent-contact__phone">
        <img src="@/assets/icons-new/phone_call.svg" alt="phone" />
        {{ $t('rental_of_premises_contact_phone') }}
      </div>
      <div class="tt-rent-contact__mail">
        <img src="@/assets/icons-new/mail-black.svg" alt="mail" />
        {{ $t('rental_of_premises_contact_mail') }}
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "Rent",
  data: () => ({

  }),
  metaInfo() {
    return {
      title: this.$t('Rent_views_meta_title'),
      meta: [{
        vmid: 'description',
        property: 'description',
        content: this.$t('Rent_views_meta_description')
      }, {
        vmid: 'og:title',
        property: 'og:title',
        content: this.$t('Rent_views_meta_og_title')
      }, {
        vmid: 'og:description',
        property: 'og:description',
        content: this.$t('Rent_views_meta_og_description')
      },],
    }
  },
}
</script>

<style scoped lang="sass">
@import "src/assets/styles-new/typography"
@import "src/assets/styles-new/vars"
@import "src/assets/styles-new/colors"

.tt-rent
  display: flex
  padding: 40px 0 100px

.tt-rent-block
  width: 75%
  flex-shrink: 0
  border-radius: 12px
  background: #D5F1F3
  &__info
    padding-left: 40px
    padding-right: 60px
    padding-top: 32px
  &__title
    padding-bottom: 24px
    font-size: $fs-larger
    line-height: 1.2em
    font-weight: $fw-semibold
  &__description
    padding-bottom: 32px
    font-weight: $fw-medium
    line-height: $fs-semilarge
  &__subtitle
    padding-bottom: 24px
    font-size: $fs-semilarge
    font-weight: $fw-semibold
    line-height: normal
  &__content
    padding-bottom: 24px
    font-weight: $fw-medium
    line-height: $fs-semilarge
    ul
      padding-inline-start: 20px
  &__btn-link
    display: flex
    width: 45%
    justify-content: center
    align-items: center
    gap: 10px
    padding-bottom: 40px


.tt-rent-contact
  padding-left: 32px
  &__title
    padding-bottom: 8px
    font-size: $fs-semilarge
    font-weight: $fw-semibold
    line-height: normal
  &__position
    padding-bottom: 16px
    font-size: 16px
    font-weight: $fw-semibold
    line-height: normal
  &__phone
    display: flex
    gap: 10px
    font-weight: $fw-medium
    line-height: $fs-semilarge
    padding-bottom: 16px
  &__mail
    display: flex
    gap: 10px
    font-weight: $fw-medium
    line-height: $fs-semilarge
@media screen and (max-width: $mobile-width)
  .tt-rent
    flex-wrap: wrap
    &-block
      width: 100%
      margin-bottom: 20px
      &__btn-link
        width: 100%
</style>
