import { render, staticRenderFns } from "./Franchise.vue?vue&type=template&id=6163b052&scoped=true"
import script from "./Franchise.vue?vue&type=script&lang=js"
export * from "./Franchise.vue?vue&type=script&lang=js"
import style0 from "./Franchise.vue?vue&type=style&index=0&id=6163b052&prod&scoped=true&lang=sass"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6163b052",
  null
  
)

export default component.exports