<template>
  <div class="tt-home">
    <HeroCarousel />

    <div class="tt-home__spinner-wrapper"
         v-if="loading">
      <Spinner />
    </div>

    <div v-else>
      <Novelties />
      <ProductsCatalog />
    </div>
  </div>
</template>

<script>
import HeroCarousel from "@/components/Home/HeroCarousel.vue";
import Novelties from "@/components/Home/Novelties.vue";
import ProductsCatalog from "@/components/Home/ProductsCatalog.vue";
import Spinner from "@/components/shared/Spinner.vue";

export default {
  data: () => ({
    loading: false
  }),
  async created() {
    this.loading = true;
    try {
        await this.$store.dispatch("products/getProducts");
    } catch (err) {
      console.error(err);
    } finally {
      this.loading = false;
    }
  },
  components: {
    Spinner,
    ProductsCatalog,
    Novelties,
    HeroCarousel,
  },
  metaInfo() {
    return {
      title: this.$t('home_views_title_meta'),
      meta: [
        {
          vmid: 'description',
          property: 'description',
          name: 'description',
          content: this.$t('home_views_description_meta')
        },
        {
          vmid: 'og:title',
          property: 'og:title',
          name: 'og:title',
          content: this.$t('home_views_og_title_meta')
        },
        {
          vmid: 'og:description',
          property: 'og:description',
          name: 'og:description',
          content: this.$t('home_views_og_description_meta')
        }
      ]
    }
  }
};
</script>

<style lang="sass" scoped>
@import "src/assets/styles-new/typography"
@import "src/assets/styles-new/colors"
@import "src/assets/styles-new/vars"

.tt-home
  padding: 40px 0 100px
  position: relative
  &__spinner-wrapper
    position: relative
    height: 80vh


@media screen and (max-width: $tablet-width)
  .tt-home
    padding: 40px 0

@media screen and (max-width: $mobile-width)
  .tt-home
    padding-top: 16px
</style>
