<template>
  <div class="tt-order-detail">
    <div class="tt-order-detail__label">
      {{ $t(i18nKey) }}
    </div>
    <div class="tt-order-detail__value">
      {{ value }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    i18nKey: String,
    value: String | Number
  }
}
</script>

<style lang="sass" scoped>
@import "src/assets/styles-new/typography"
@import "src/assets/styles-new/vars"

.tt-order-detail
  &__value
    margin-top: 8px
    font-size: $fs-medium
    font-weight: $fw-semibold

@media screen and (max-width: $mobile-width)
  .tt-order-detail
    &__value
      margin-top: 0
</style>