<template>
  <div class="tt-rent">
    <div class="tt-rental-premises">
      <div class="tt-rental-premises__info">
        <div class="tt-rental-premises__title ff-mont">
          {{ $t('navbar_rent') }}
        </div>
        <div class="tt-rental-premises__description">
          <div v-html="$t('rental_of_premises_description')"></div>
        </div>
        <div class="tt-rental-premises__subtitle">
          {{ $t('rental_of_premises_subtitle') }}
        </div>
        <div class="tt-rental-premises__content">
          <ul>
            <li>{{ $t('rental_of_premises_content_li_1') }}</li>
            <li>{{ $t('rental_of_premises_content_li_2') }}</li>
            <li>{{ $t('rental_of_premises_content_li_3') }}</li>
            <li>{{ $t('rental_of_premises_content_li_4') }}</li>
            <li>{{ $t('rental_of_premises_content_li_5') }}</li>
            <li>{{ $t('rental_of_premises_content_li_6') }}</li>
            <li>{{ $t('rental_of_premises_content_li_7') }}</li>
            <li>{{ $t('rental_of_premises_content_li_8') }}</li>
            <li>{{ $t('rental_of_premises_content_li_9') }}</li>
          </ul>
        </div>

        <div class="tt-rent-contact">
          <div class="tt-rent-contact__title">
            {{ $t('rental_of_premises_contact_title') }}
          </div>
          <div class="tt-rent-contact__position">
            {{ $t('rental_of_premises_contact_position') }}
          </div>
          <div class="tt-rent-contact__phone">
            <img src="@/assets/icons-new/phone_call.svg" alt="phone" />
            {{ $t('rental_of_premises_contact_phone') }}
          </div>
          <div class="tt-rent-contact__mail">
            <img src="@/assets/icons-new/mail-black.svg" alt="mail" />
            {{ $t('rental_of_premises_contact_mail') }}
          </div>
        </div>
      </div>

      <form class="tt-rental-premises__form"
            v-if="!formSent">

        <div class="tt-input-field">
          <label class="tt-input-field__label">
            {{ $t('rental_of_premises_form_input_name') }}
          </label>
          <input class="tt-input-field__input"
                 :class="{ 'tt-input-field__input--invalid': isInvalid('username') }"
                 :placeholder="$t('rental_of_premises_fio')"
                 v-model="form.username"
          >
        </div>

        <div class="tt-input-field">
          <label class="tt-input-field__label">
            {{ $t('rental_of_premises_form_input_phone') }}
          </label>
          <input type="tel"
                 class="tt-input-field__input"
                 :class="{ 'tt-input-field__input--invalid': isInvalid('phone') }"
                 v-mask="'+7 (###) ###-##-##'"
                 placeholder="+7 (___) ___ __ __"
                 v-model="form.phone"
          >
        </div>

        <div class="tt-input-field" v-for="(item, index) in questionList" :key="index">
          <label class="tt-input-field__label">
            {{ item.question }}
          </label>
          <div class="tt-v-select" v-if="item.type === 'dropdown'">
            <v-select
                v-model="item.answer"
                :clearable="false"
                :searchable="false"
                :options="item.options"
                :disabled="!item.options.length"
                label="value"
                :class="{ 'tt-input-field__input--invalid': isAnswerInvalid(index) }"
            />
          </div>

          <input v-else class="tt-input-field__input"
                 v-model="item.answer"
                 :class="{ 'tt-input-field__input--invalid': isAnswerInvalid(index) }"
          >
        </div>

        <button class="tt-button tt-button--primary"
                :disabled="loading"
                @click.prevent="submit">
          {{ $t('contacts_widget_btn2send') }}
        </button>
      </form>

      <div class="tt-rental-premises__success"
           v-else>
        <p>{{ $t('rental_of_premises_text_success') }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import {required} from "vuelidate/lib/validators";
import {phoneValidator} from "@/mixins/validators";

export default {
  name: "RentalOfPremises",
  data: () => ({
    form: {},
    errors: {},
    loading: false,
    attemptedSubmit: false,
    formSent: false,
    questionList: [
      {
        question: 'Стоимость аренды за помещение',
        answer: '',
      },
      {
        question: 'Ваш город',
        answer: '',
        type: 'dropdown',
        options: [
          'Алматы',
          'Астана',
          'Шымкент',
          'Актобе',
          'Караганда',
          'Тараз',
          'Усть-Каменогорск',
          'Павлодар',
          'Атырау',
          'Семей',
          'Кызылорда',
          'Актау',
          'Костанай',
          'Уральск',
          'Туркестан',
          'Петропавловск',
          'Кокшетау',
          'Темиртау',
          'Талдыкорган',
          'Экибастуз',
          'Рудный',
          'Жезказган',
          'Каскелен',
          'Жанаозен',
          'Кентау',
          'Балхаш',
          'Сатпаев',
          'Кульсары',
          'Талгар',
          'Сарыагаш',
          'Конаев',
          'Косшы',
          'Жаркент',
          'Арыс',
          'Аксу',
          'Степногорск',
          'Щучинск',
          'Шу',
          'Жетысай',
          'Риддер',
          'Аягоз',
          'Есик',
          'Шахтинск',
          'Аральск',
          'Аксай',
          'Алтай',
          'Кандыагаш',
          'Житикара',
          'Сарань',
          'Байконур',
          'Ленгер',
          'Шардара',
          'Лисаковск',
          'Атбасар',
          'Текели',
          'Хромтау',
          'Абай',
          'Аркалык',
          'Тобыл',
          'Каратау',
          'Шалкар',
          'Жанатас',
          'Алга',
          'Ушарал',
          'Уштобе',
          'Зайсан',
          'Шемонаиха',
          'Макинск',
          'Сарканд',
          'Акколь',
          'Тайынша',
          'Эмба',
          'Ерейментау',
          'Есиль',
          'Приозёрск',
          'Курчатов',
          'Каркаралинск',
          'Форт-Шевченко',
          'Булаево',
          'Каражал',
          'Сергеевка',
          'Казалинск',
          'Серебрянск',
          'Мамлютка',
          'Державинск',
          'Шар',
          'Степняк',
          'Темир',
          'Жем'
        ]
      },
      {
        question: 'Напишите пожалуйста точный адрес включительно номер помещения',
        answer: '',
      },
      {
        question: 'Какая у Вас площадь помещения?',
        answer: '',
      },
      {
        question: 'В каком состоянии помещение?',
        answer: '',
        type: 'dropdown',
        options: [
          'Черновое',
          'С ремонтом',
          'Требует косметического ремонта',
          'Требует капительного ремонта'
        ]
      },
      {
        question: 'Находится ли на первой линии?',
        answer: '',
        type: 'dropdown',
        options: [
          'ДА',
          'НЕТ'
        ]
      },
      {
        question: 'Какая мощность по Киловаттам?',
        answer: '',
      },
      {
        question: 'Сколько входов?',
        answer: '',
        type: 'dropdown',
        options: [
          '1',
          '2',
          'более двух',
        ]
      },
      {
        question: 'Имеется ли пандус?',
        answer: '',
        type: 'dropdown',
        options: [
          'ДА',
          'НЕТ',
        ]
      },
      {
        question: 'Имеется ли парковочное пространство перед помещением?',
        answer: '',
        type: 'dropdown',
        options: [
          'ДА',
          'НЕТ',
        ]
      },
      {
        question: 'Помещение находится в вашей личной собственности?',
        answer: '',
        type: 'dropdown',
        options: [
          'ДА',
          'НЕТ',
        ]
      },
      {
        question: 'Имеет ли обременение помещение?',
        answer: '',
        type: 'dropdown',
        options: [
          'Да',
          'Нет'
        ]
      },
      {
        question: 'Имеется ли поблизости Остановочный комплекс (Остановка автобусная) ?',
        answer: '',
        type: 'dropdown',
        options: [
          'Да',
          'Нет'
        ]
      },
      {
        question: 'На каком этаже находится помещение?',
        answer: '',
        type: 'dropdown',
        options: [
          'Цоколь',
          'Полуцоколь',
          '1 этаж',
          '2 этаж',
          'выше 2 этажа',
        ]
      },
      {
        question: 'Имеется ли у Вас отдельная вытяжная система?',
        answer: '',
        type: 'dropdown',
        options: [
          'Да',
          'Нет'
        ]
      },
      {
        question: 'Имеются ли у Вас все документы такие как разграничительная ведомость, технические условия и прочие документы?',
        answer: '',
        type: 'dropdown',
        options: [
          'Да',
          'Нет'
        ]
      },
      {
        question: 'Имеется ли у Вас все инженерные коммуникации?',
        answer: '',
        type: 'dropdown',
        options: [
          'Да все подключено и работает',
          'НЕТ у меня нет горячей воды',
          'У меня не все имеется',
        ]
      },
    ]
  }),
  validations() {
    return {
      form: {
        username: {
          required
        },
        phone: {
          required,
          phoneValidator
        },
      },
    }
  },
  methods: {
    isInvalid(prop) {
      return this.$v.form[prop].$dirty && this.$v.form[prop].$invalid;
    },
    showError(err) {
      return typeof err === 'object' ? err[0] : err;
    },
    isAnswerInvalid(index) {
      const item = this.questionList[index];
      const answer = item.answer || '';
      const trimmedAnswer = answer.trim();

      if (!this.attemptedSubmit) {
        return false;
      }

      if (!trimmedAnswer) {
        return true;
      }

      return !item.type && trimmedAnswer.length < 3;
    },
    allAnswersValid() {
      return this.questionList.every((item, index) => !this.isAnswerInvalid(index));
    },
    getQuestionListError() {
      for (let i = 0; i < this.questionList.length; i++) {
        if (this.isAnswerInvalid(i)) {
          return {
            error: `Ошибка в ответе.`,
            field: `Вопрос ${i + 1}: ${this.questionList[i].question}`
          };
        }
      }
      return null;
    },
    getFirstValidationError() {
      const fields = Object.keys(this.$v.form);
      for (let field of fields) {
        if (this.$v.form[field].$invalid) {
          return {
            error: `Ошибка в поле.`,
            field: field
          };
        }
      }

      const questionError = this.getQuestionListError();
      if (questionError) {
        return questionError;
      }

      return null;
    },
    async submit() {
      this.attemptedSubmit = true;
      const firstError = this.getFirstValidationError();
      if (firstError) {
        this.$toast.open({
          position: 'top-right',
          type: 'error',
          message: `${firstError.error} ${firstError.field}`,
          duration: 3000,
        });
        this.$v.form.$touch();
        return;
      }

      this.loading = true;
      let formData = new FormData();

      Object.keys(this.form).forEach(item => {
        formData.append(item, this.form[item]);
      });

      const message = this.questionList 
          .map(item => `<b>${item.question}</b>: <br /> ${item.answer}`) 
          .join('<br /><br />') 
      formData.append('message', message);

      try {
        await this.$axios.post( "/rental", formData);
        this.formSent = true;
      } catch (e) {
        this.errors = (e.response.data || {}).errors;
        if (this.errors.file !== undefined) {
          this.$toast.open({
            position: 'top-right',
            type: 'error',
            message: this.errors.file[0],
            duration: 3000,
          });
        }
      } finally {
        this.loading = false;
      }
    }
  },
  metaInfo() {
    return {
      title: this.$t('Rental_of_premises_views_meta_title'),
      meta: [{
        vmid: 'description',
        property: 'description',
        content: this.$t('Rental_of_premises_views_meta_description')
      }, {
        vmid: 'og:title',
        property: 'og:title',
        content: this.$t('Rental_of_premises_views_meta_og_title')
      }, {
        vmid: 'og:description',
        property: 'og:description',
        content: this.$t('Rental_of_premises_views_meta_og_description')
      },],
    }
  },
}
</script>

<style scoped lang="sass">
@import "src/assets/styles-new/typography"
@import "src/assets/styles-new/vars"
@import "src/assets/styles-new/colors"

.tt-rent
  padding: 40px 0 100px

.tt-v-select .v-select.tt-input-field__input--invalid
  border-color: red

.tt-v-select .v-select.tt-input-field__input--invalid .vs__selected-options
  border-color: red

.tt-rental-premises
  display: flex
  justify-content: space-between
  gap: 32px
  &__info
    max-width: 478px
  &__title
    font-size: $fs-larger
    line-height: 1.2em
    font-weight: $fw-semibold
    margin-bottom: 24px
  &__description
    padding-bottom: 48px
    font-weight: $fw-medium
    line-height: $fs-semilarge
  &__subtitle
    padding-bottom: 24px
    font-size: $fs-semilarge
    font-weight: $fw-semibold
    line-height: normal
  &__content
    font-size: 14px
    font-weight: $fw-medium
    line-height: $fs-semilarge
    ul
      padding-inline-start: 20px
  &__contacts
    display: grid
    grid-template-columns: 100px 1fr
    font-weight: $fw-medium
  &__form
    width: 100%
    max-width: 544px
    .tt-input-field
      margin-bottom: 10px
    .tt-button
      margin-top: 30px
      height: 56px
  &__success
    margin-top: 8px
    color: $main
    font-weight: $fw-medium


.tt-rent-contact
  padding-top: 56px
  &__title
    padding-bottom: 8px
    font-size: $fs-semilarge
    font-weight: $fw-semibold
    line-height: normal
  &__position
    padding-bottom: 16px
    font-size: 16px
    font-weight: $fw-semibold
    line-height: normal
  &__phone
    display: flex
    gap: 10px
    font-weight: $fw-medium
    line-height: $fs-semilarge
    padding-bottom: 16px
  &__mail
    display: flex
    gap: 10px
    font-weight: $fw-medium
    line-height: $fs-semilarge

@media screen and (max-width: $tablet-width)
  .tt-rental-premises
    &__title
      font-size: $fs-large

@media screen and (max-width: $mobile-width)
  .tt-rental-premises
    flex-direction: column
    gap: 0
    &__title
      font-size: $fs-semilarge
      margin-bottom: 16px

</style>
