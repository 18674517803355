<template>
  <div class="tt-order-card">
    <div v-if="order">
      <div class="tt-order-card__title ff-mont">
        {{ $t('Cabinet_Order_card_title') }}
      </div>

      <div class="tt-order-card__details">
        <OrderCardDetail i18n-key="Order_views_order_number"
                         :value="order.id" />

        <OrderCardDetail i18n-key="Cabinet_Order_card_date"
                         :value="order.delivery_date" />

        <OrderCardDetail i18n-key="Cabinet_Order_card_time"
                         :value="order.delivery_time_interval" />

        <OrderCardDetail v-if="!order.isDelivery"
                         i18n-key="OrderPage_cmpt_SideCheck_samovivoz_magazin"
                         :value="order.filial_address" />

        <OrderCardDetail v-if="order.isDelivery"
                         i18n-key="OrderPage_cmpt_DeliveryAddress_placeholder_street"
                         :value="order.delivery_address" />

        <OrderCardDetail i18n-key="OrderPage_cmpt_SideCheck_sum"
                         :value="`${numberWithSpaces(order.amount)} ₸`" />

        <OrderCardDetail i18n-key="Cabinet_Order_card_status"
                         :for-status="true"
                         :value="order.status"
                         :payment-expired="order.payment_url_expired"
                         :payed-at="order.payed_at" />
      </div>

      <div class="tt-order-card__products"
           v-if="order.cart">
        <div class="tt-order-card__product"
             v-for="product in order.cart.products">
          <div class="tt-order-card__product-info">
            <div class="tt-order-card__product-image">
              <ImageLazy :img-src="product.image" />
            </div>

            <div>
              <div class="tt-order-card__product-name">
                {{ product.name }}
              </div>
              <div class="tt-order-card__product-inscription">
                {{ product.inscription }}
              </div>
            </div>
          </div>

          <div class="tt-order-card__product-quantity">
            {{ product.quantity }}
          </div>

          <div class="tt-order-card__product-price">
            {{ numberWithSpaces(Number(product.price)*Number(product.quantity)) }} ₸
          </div>
        </div>
      </div>
    </div>

    <Spinner v-else />
  </div>
</template>

<script>
import OrderCardDetail from "@/components/Cabinet/OrderCardDetail.vue";
import ImageLazy from "@/components/shared/ImageLazy.vue";
import axios from "axios";
import Spinner from "@/components/shared/Spinner.vue";

export default {
  props: {
    orderId: null
  },
  data: () => ({
    order: null
  }),
  mounted() {
    this.$axios.get(`order/${this.orderId}`)
      .then(resp => this.order = resp.data);
  },
  components: {
    Spinner,
    ImageLazy,
    OrderCardDetail
  }
}
</script>

<style lang="sass" scoped>
@import "src/assets/styles-new/typography"
@import "src/assets/styles-new/colors"
@import "src/assets/styles-new/vars"

.tt-order-card
  position: relative
  min-height: 300px
  &__title
    font-size: $fs-semilarge
    font-weight: $fw-semibold
    margin-bottom: 16px
  &__details
    display: flex
    column-gap: 80px
    row-gap: 24px
    flex-wrap: wrap
    margin-bottom: 40px
  &__products
    display: flex
    flex-direction: column
    gap: 16px
    max-width: 600px
  &__product
    display: grid
    grid-template-columns: 1fr 40px 100px
    gap: 16px
  &__product-info
    display: flex
  &__product-image
    width: 80px
    height: 80px
    margin-right: 16px
  &__product-name
    font-weight: $fw-medium
  &__product-inscription
    font-size: $fs-small
    color: $dark-gray
    line-height: 1rem
  &__product-quantity, &__product-price
    font-weight: $fw-medium
    font-size: $fs-medium
  &__product-quantity
    justify-self: center
  &__product-price
    justify-self: end

@media screen and (max-width: $mobile-width)
  .tt-order-card
    &__details
      display: grid
      grid-template-columns: 1fr 1fr
      gap: 16px
    &__product-image
      display: none
</style>
