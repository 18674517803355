const state = {
    product: null,
    isShown: !!sessionStorage.getItem("is_product_of_the_day_shown")
}

const getters = {
    canShowProductOfTheDay(state) {
        return state.product ? !state.isShown : false;
    }
}

const actions = {
    setIsShownToTrue() {
        state.isShown = true;
        sessionStorage.setItem('is_product_of_the_day_shown', 'true');
    }
}

const mutations = {
    SET_PRODUCT_OF_THE_DAY(state, product) {
        state.product = product;
    }
}

// Экспортируем наружу
export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}