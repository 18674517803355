import Vue from 'vue'
import VueI18n from 'vue-i18n'
import axios from 'axios'
import VueCookies from 'vue-cookies'
import defaultJsonRu from './ru.json'
import defaultJsonKz from './kz.json'
import defaultJsonEn from './en.json'

Vue.use(VueCookies)
Vue.use(VueI18n)

const allowLanguages = ['ru', 'kz'];
let browserLang = navigator.language.substr(0,2).toLowerCase();
if (!allowLanguages.includes(browserLang)) {
    browserLang = null;
}
const defaultLang = $cookies.get('content_lang') || browserLang || process.env.VUE_APP_I18N_LOCALE || 'ru';
export const i18n = new VueI18n({
    locale: defaultLang,
    fallbackLocale: defaultLang,
})

setI18nLanguage(defaultLang)

const loadedLanguages = []

function setI18nLanguage (lang) {
    if (i18n) {
        i18n.locale = lang
    }
    document.querySelector('html').setAttribute('lang', lang)
    $cookies.set('content_lang', lang, '30d');

    return lang
}

export function loadLanguageAsync (lang) {
    if (loadedLanguages.includes(lang)) {
        if (i18n.locale !== lang) setI18nLanguage(lang)

        return Promise.resolve()
    }

    const localStorageKey = 'translates_' + lang;
    if (sessionStorage.getItem(localStorageKey) !== null) {
        loadedLanguages.push(lang)
        i18n.setLocaleMessage(lang, JSON.parse(sessionStorage.getItem(localStorageKey)))
        setI18nLanguage(lang)

        return Promise.resolve()
    }

    setDefaultTranslations(lang);

    return axios.get(`/translates/${lang}`).then(response => {
        let messages = response.data
        loadedLanguages.push(lang)
        if (sessionStorage.getItem(localStorageKey) === null) {
            sessionStorage.setItem(localStorageKey, JSON.stringify(messages))
        }
        i18n.setLocaleMessage(lang, messages)
        setI18nLanguage(lang)
    })
}

function setDefaultTranslations(lang) {
    if (lang === 'ru') {
        i18n.setLocaleMessage(lang, defaultJsonRu)
    }
    else {
        i18n.setLocaleMessage(lang, defaultJsonKz)
    }
}
